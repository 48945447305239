import * as React from "react";
import "../css/common.css";
import { Button, Card } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "./Utils/Loader";
import { ModalPopup } from "./UpcomingMatches/MatchDetailPopup";
import { Authentication } from "./Utils/PopupModal/Authentication";
import { DBConvertDatetime } from "./constants";
import { getByDisplayValue } from "@testing-library/react";
import { GetDay } from "./constants";
import { CustomGetAPI, GetAPI, PostAPI } from "./Utils/APIConnector";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import HelmetMetaTag from "./Utils/HelmetMetaTag/HelmetMetaTag";
export function UpcomingMatches() {
  const [state, setState] = useState({});
  const [allMatchesData, setAllMatchesData] = useState([]);
  var userid = window.localStorage.getItem("userid");

  if (userid === null || userid === undefined) {
    userid = 0;
  }
  const [show, setShow] = useState(false);
  const [Filter, setFilter] = useState(false);
  const [data, setData] = useState([]);
  const [matchID, SetMatchID] = useState("");
  const [date, SetDate] = useState("");
  const [isLoading, SetIsLoading] = useState(true);
  const [isGuest, SetIsGuest] = useState(false);
  const [isGuestPopup, SetIsGuestPopup] = useState(false);
  var matches = [];

  const toggleFilters = () => {
    setFilter(!Filter);
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("loggedin");
    const today = new Date().toISOString().split("T")[0];
    SetDate(today);
  }, [localStorage.getItem("loggedin")]);
  const userType = window.localStorage.getItem("UserType");
  const FilterRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(ref) {
      return function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFilter(false);
        }
      };
    }
    const handleFilterClick = handleClickOutside(FilterRef);
    document.addEventListener("mousedown", handleFilterClick);

    return () => {
      document.removeEventListener("mousedown", handleFilterClick);
    };
  }, [FilterRef]);

  useEffect(() => {
    datas();
    return () => {
      setState({});
    };
  }, []);

  const datas = async () => {
    if (userType === null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    if (
      isNaN(userid) ||
      userid === null ||
      userid === 0 ||
      userType === "Guest" ||
      userType === "" ||
      userType === null
    ) {
      SetIsGuest(true);
    }

    await CustomGetAPI(
      `/CreateMatches/GetMatchesList/1970-01-01/${userid}`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.details;
        for (var i = 0; i < matchList?.length; i++) {
          matches?.push(matchList[i]);
        }
        matches.reverse();
        setTimeout(() => {
          SetIsLoading(false);
        }, 1000);
        setAllMatchesData(matches);
      }
    });

    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };
    // fetch(
    //   "https://playerstation.azurewebsites.net/api/CreateMatches/GetMatchesList/" +
    //   userid,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result);
    //     var matchList = result.details;
    //     for (var i = 0; i < matchList.length; i++) {
    //       matches.push(matchList[i]);
    //     }
    //     matches.reverse();
    //     setTimeout(() => {
    //       SetIsLoading(false);
    //     }, 1000);
    //     setAllMatchesData(matches);
    //   })
    //   .catch((error) => console.log("error", error));
    // //  setAllMatchesData( allMatchesData=>[...allMatchesData, matches])
    // // } else {
    // //     SetIsGuest(true);
    // //     SetIsLoading(false);
    // //     setTimeout(() => {
    // //         SetIsGuestPopup(true);
    // //     }, 1000);
    // // }
  };
  const closePopupHandler = () => {
    setShow(false);
  };

  const closeGuestPopup = () => {
    SetIsGuestPopup(false);
  };
  const navigate = useNavigate();
  async function isShowPopup(matchId) {
    if (isGuest) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location = "/welcome";
      SetIsGuestPopup(true);
    } else {
      var userID = parseInt(userid);

      await GetAPI("/Matches/MatchListById/" + matchId + "/" + userID).then(
        (result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            var result = JSON.parse(result);
            var matchList = result.details;
            // console.log("first", matchList);
            setData(matchList);
            setShow(true);
            SetMatchID(matchList.id);
          }
        }
      );

      // var requestOptions = {
      //   method: "GET",
      //   redirect: "follow",
      // };
      // fetch(
      //   "https://playerstation.azurewebsites.net/api/Matches/MatchListById/" +
      //   matchId +
      //   "/" +
      //   userID,
      //   requestOptions
      // )
      //   .then((response) => response.json())
      //   .then((result) => {
      //     var matchList = result.details;
      //     setData(matchList);
      //     setShow(true);
      //     SetMatchID(matchList.id);
      //   })
      //   .catch((error) => console.log("error", error));
    }
  }
  async function joinMatch(matchId) {
    SetIsLoading(true);
    var matchID = parseInt(matchId);
    var userID = userid;
    var raw = JSON.stringify({
      CricketMatchId: matchID,
      UserId: userID,
      Status: 1,
    });
    await PostAPI("/CreateMatches/JoinMatchPlayers", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        setTimeout(function () {
          window.location = "/UpcomingMatches";
        }, 500);
      }
    });
  }

  function joinMatchFromModal() {
    joinMatch(matchID);
  }

  function showLoginPopup() {
    window.localStorage.setItem("RedirectURL", window.location.href);
    window.location = "/welcome";
    SetIsGuestPopup(true);
  }

  const handleChangeDate = async (targetDate) => {
    // const targetDate = e.target.value;
    SetDate(targetDate);
    if (targetDate === "") {
      targetDate = "1970-01-01";
      // SetDate(new Date().toISOString().split("T")[0]);
    }
    const formattedDate = date != "" ? date : "1970-01-01";
    await CustomGetAPI(
      `/CreateMatches/GetMatchesList/${targetDate}/${userid}`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.details;
        for (var i = 0; i < matchList?.length; i++) {
          matches?.push(matchList[i]);
        }
        matches.reverse();
        setTimeout(() => {
          SetIsLoading(false);
        }, 1000);
        setAllMatchesData(matches);
        setFilter(false);
      }
    });
  };
  const handleButtonClick = (e) => {
    if (!localStorage.getItem("loggedin")) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      e.preventDefault();
      Swal.fire({
        icon: "info",
        title: "Login Required",
        text: "Please Login to use this feature.",
        showConfirmButton: true,
        confirmButtonText: "Signup/Login",
        confirmButtonColor: "#000000",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = "/welcome";
        }
      });
    }
  };
  return (
    <>
      <HelmetMetaTag
        titleName="Find Players - Join Teams and Showcase Your Skills | CricBuddy"
        canonical={window.location.href}
        desc="Looking to join a cricket team? Find players for your team or join teams in need of players on CricBuddy. Showcase your skills and play with like-minded individuals passionate about cricket."
        ogtitle="Find Players - Join Teams and Showcase Your Skills | CricBuddy"
        ogdesc="Looking to join a cricket team? Find players for your team or join teams in need of players on CricBuddy. Showcase your skills and play with like-minded individuals passionate about cricket."
        ogtype="website"
        ogurl={window.location.href}
      />
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          // isGuest ?
          //     <Authentication show={isGuestPopup} onClick={closeGuestPopup}
          //         onHide={closeGuestPopup}></Authentication>
          //     :
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={() => navigate("/home")}
                />
              </div>
            </div>
            <div className="Container">
              <Row className="mx-2 mb-3">
                <div className="col-12">
                  <div className="p-2 px-0 text-center row d-flex align-items-center">
                    <div className="col-8 text-start p-0">
                      <label>
                        <h4 className="mb-0">
                          <b>Upcoming Matches</b>
                        </h4>
                      </label>
                    </div>
                    <div className="col-4 p-0 ">
                      <div className="row mt-2 d-flex align-items-center">
                        <div className="col-12 px-0">
                          <input
                            type="date"
                            className="form-control"
                            name="date"
                            placeholder="Select Date"
                            value={date}
                            onClick={(e) => {
                              handleButtonClick(e);
                            }}
                            onChange={(e) => handleChangeDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-4 p-0 ">
                      <button
                        className="venueListingTop w-100"
                        style={{ padding: "6px" }}
                        onClick={(e) => {
                          handleButtonClick(e);
                        }}
                      >
                        <img
                          className="mx-1"
                          src="/images/sorting.svg"
                          alt=""
                        />
                        Filters
                      </button>
                      {Filter && (
                        <div
                          className="mt-3 mb-3 position-absolute"
                          id="filtersCard"
                          ref={FilterRef}
                        >
                          <div className="filtersOpen p-3 mx-3">
                            <div className="" id="filters">
                              {/* <div className="row mt-2 d-flex align-items-center">
                                <div className="col-4">
                                  <span className="venueText">Ground Name</span>
                                </div>
                                <div className="col-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="date"
                                    // value={date}
                                    style={{ paddingLeft: "10px" }}
                                    // onChange={(e) => SetDate(e.target.value)}
                                  />
                                </div>
                              </div> 
                              <div className="row mt-2 d-flex align-items-center">
                                <div className="col-4">
                                  <span className="venueText">Date</span>
                                </div>
                                <div className="col-8">
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="date"
                                    value={date}
                                    style={{ paddingLeft: "10px" }}
                                    onChange={(e) => SetDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              {/* <div className="row mt-2 d-flex align-items-center">
                                <div className="col-4">
                                  <span className="venueText">Ground Type</span>
                                </div>
                                <div className="col-8">
                                  <select>
                                    <option>select Ground</option>
                                    <option>Cricket Box</option>
                                    <option>Cricket Ground</option>
                                  </select>
                                </div>
                              </div> 
                              <hr />
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    className="filterButtons px-1 w-100"
                                    style={{ background: "#3C3C40" }}
                                    onClick={() => handleChangeDate(date)}
                                  >
                                    Apply Filters
                                  </button>
                                </div>

                                <div className="col-6">
                                  <button
                                    className="filterButtons w-100 h-100"
                                    style={{ background: "#a51e22" }}
                                    onClick={() =>
                                      handleChangeDate("1970-01-01")
                                    }
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
                {}
                {allMatchesData?.length != 0 ? (
                  allMatchesData?.map((str, index) => {
                    var returData;
                    var buttonCode;
                    if (str.playerStatus === null) {
                      buttonCode = (
                        <button
                          key={index}
                          type="submit"
                          className="upcomingbtn upcomingbtnFont"
                          onClick={() => isShowPopup(str.id)}
                          style={{ fontSize: "15px" }}
                        >
                          Ask to play
                        </button>
                      );
                    } else if (str.playerStatus === 1) {
                      buttonCode = (
                        <button
                          key={index}
                          type="submit"
                          className="upcomingbtn upcomingbtnFont"
                          style={{ backgroundColor: "#FFB038" }}
                        >
                          REQUESTED
                        </button>
                      );
                    } else if (str.playerStatus === 2) {
                      buttonCode = (
                        <button
                          key={index}
                          type="submit"
                          className="upcomingbtn upcomingbtnFont"
                          style={{ backgroundColor: "#339900" }}
                        >
                          APPROVED
                        </button>
                      );
                    } else if (str.playerStatus === 3) {
                      buttonCode = (
                        <button
                          key={index}
                          type="submit"
                          className="upcomingbtn upcomingbtnFont"
                          style={{ backgroundColor: "#CD0D0D" }}
                        >
                          REJECTED
                        </button>
                      );
                    } else if (str.playerStatus === 4) {
                      buttonCode = (
                        <Button key={index} type="submit" className="">
                          Refunded
                        </Button>
                      );
                    }
                    returData = (
                      <Card
                        key={index}
                        className="mt-3 matchListItem px-0"
                        data-item={str.id}
                        data-index={index}
                      >
                        <Card.Header style={{ backgroundColor: "#E8E8E8" }}>
                          <div className="text-center">
                            {str.sportTypeName === "Tennis" ? (
                              <>
                                <div className="row">
                                  <div className="col-10 centerAlign">
                                    <b
                                      className="font1"
                                      onClick={() => {
                                        window.localStorage.setItem(
                                          "MatchDetailID",
                                          str.id
                                        );
                                        window.localStorage.setItem(
                                          "RedirectURL",
                                          window.location.href
                                        );
                                        window.location = "/match-details";
                                      }}
                                    >{`${str.overs} Over Tennis match `}</b>
                                  </div>
                                  <div className="col-2 px-3">
                                    <img
                                      className="w-100"
                                      src="./images/TennisBall.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col-10 centerAlign px-0">
                                    <b
                                      className="font1"
                                      onClick={() => {
                                        window.localStorage.setItem(
                                          "MatchDetailID",
                                          str.id
                                        );
                                        window.localStorage.setItem(
                                          "RedirectURL",
                                          window.location.href
                                        );
                                        window.location = "/match-details";
                                      }}
                                    >{`${str.overs} Over Season match `}</b>
                                  </div>
                                  <div className="col-2 px-3">
                                    <img
                                      className="w-100"
                                      src="./images/SeasonBall.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Card.Header>
                        <Card.Body className="profileformControl">
                          <div className="row">
                            <div className="col-6 ">
                              <div className="text-left">
                                <label className="form-label textLabel">
                                  Players in team :
                                </label>{" "}
                                <label className="dataText">
                                  {str.numberOfExistingPlayers}
                                </label>
                              </div>
                            </div>
                            <div className="col-6 px-0">
                              <div className=" text-left">
                                <label className="form-label textLabel">
                                  Required Players :
                                </label>{" "}
                                <label className="dataText">
                                  {str.numberOfRequiredPlayers}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row text-left">
                            <div className="col-12">
                              <label className="form-label textLabel">
                                Refundable Fees :
                              </label>{" "}
                              <label className="dataText">
                                ₹ {str.entryFees}
                              </label>
                            </div>
                          </div>

                          {str.numberOfBatsmen === null &&
                          str.numberOfBowlers === null &&
                          str.numberOfAllRounders === null &&
                          str.numberOfWicketKeepers === null ? null : (
                            <>
                              <div className="p-2 text-center">
                                <label>
                                  <h4>
                                    <b>Players Requirements</b>
                                  </h4>
                                </label>
                              </div>

                              <div className="centerAlign mb-3">
                                {str.numberOfBatsmen != null ? (
                                  <div
                                    className="col-3 centerAlign "
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div className="">
                                      <img
                                        src="/images/PlayerType/Batsmen.svg"
                                        className="img-fluid"
                                      />{" "}
                                      :{" "}
                                      <b className="dataText">
                                        {str.numberOfBatsmen}
                                      </b>
                                    </div>
                                  </div>
                                ) : null}
                                {str.numberOfBowlers != null ? (
                                  <div
                                    className="col-3 centerAlign"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div className="">
                                      <img
                                        src="/images/PlayerType/Bowler.svg"
                                        className="img-fluid"
                                      />{" "}
                                      :{" "}
                                      <b className="dataText">
                                        {str.numberOfBowlers}
                                      </b>
                                    </div>
                                  </div>
                                ) : null}
                                {str.numberOfAllRounders != null ? (
                                  <div
                                    className="col-3 centerAlign "
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div className="">
                                      <img
                                        src="/images/PlayerType/AllRounder.svg"
                                        className="img-fluid"
                                      />{" "}
                                      :{" "}
                                      <b className="dataText">
                                        {str.numberOfAllRounders}
                                      </b>
                                    </div>
                                  </div>
                                ) : null}
                                {str.numberOfWicketKeepers != null ? (
                                  <div
                                    className="col-3 centerAlign "
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div className="">
                                      <img
                                        src="/images/PlayerType/WicketKeeper.svg"
                                        className="img-fluid"
                                      />{" "}
                                      :{" "}
                                      <b className="dataText">
                                        {str.numberOfWicketKeepers}
                                      </b>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )}
                          {}
                          <div className="row text-left my-2">
                            <div className="col-12">
                              <img src="./images/groundIcon.svg" alt="" />{" "}
                              <label className="groundName">{`${str.groundName} Cricket Ground`}</label>
                            </div>
                          </div>
                          <div className="row mx-0">
                            <div className="col-7 px-0">
                              {!isGuest ? (
                                <>
                                  <div className="text-left mt-2">
                                    <div className="row">
                                      <div className="col-2">
                                        <img
                                          src="./images/DateandTime.svg"
                                          alt=""
                                        />{" "}
                                      </div>
                                      <div className="col-10">
                                        <label className="dataText">
                                          {DBConvertDatetime(str.dateAndTime)}{" "}
                                          {`(${GetDay(str.dateAndTime)})`}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="row text-center">
                                  <div className="col-12 getdays ">
                                    {`(${GetDay(str.dateAndTime)})`}
                                  </div>
                                </div> */}
                                </>
                              ) : (
                                <div
                                  className="mt-2 text-right centerAlign justify-content-start dataText"
                                  onClick={showLoginPopup}
                                  style={{ textDecoration: "underline" }}
                                >
                                  Login to see time
                                </div>
                              )}
                            </div>

                            <div className=" col-5 text-center">
                              {buttonCode}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                    return returData;
                  })
                ) : (
                  <div className="profileformControl small fw-bold text-center">
                    No upcoming matches available
                  </div>
                )}

                {}
                <ModalPopup
                  show={show}
                  data={data}
                  onClick={closePopupHandler}
                  onHide={closePopupHandler}
                  matchJoin={joinMatchFromModal}
                ></ModalPopup>

                {/* <Authentication
                  show={isGuestPopup}
                  onClick={closeGuestPopup}
                  onHide={closeGuestPopup}
                ></Authentication> */}
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
}
