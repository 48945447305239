import React, { useEffect, useState } from "react";
import $ from "jquery";
import { isValid } from "../constants";
import { GetAPI } from "../Utils/APIConnector";

export default function LiveMatchPalyers_4({
  prevStep,
  nextStep,
  teamNames,
  values,
  setDynamicValues,
}) {
  const [teamOneDetails, SetTeamOneDetails] = useState([]);
  const [teamTwoDetails, SetTeamTwoDetails] = useState([]);
  const [battingTeam, SetBattingTeam] = useState([]);
  const [BattingTeamSecondInning, SetBattingTeamSecondInning] = useState([]);
  const [bowlingTeam, SetBowlingTeam] = useState([]);
  const [BowlingTeamSecondInning, SetBowlingTeamSecondInning] = useState([]);

  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  useEffect(async () => {
    const userType = window.localStorage.getItem("UserType");

    if (userType === null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    await GetAPI(
      "/Matches/MatchTeamDetails?matchID=" + parseInt(matchDetailsID)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.selectedPlayers;
        var teamOne = [];
        var teamTwo = [];

        for (var i = 0; i < matchList.length; i++) {
          if (matchList[i].teamTypeID === 2) {
            teamOne.push(matchList[i]);
          }
          if (matchList[i].teamTypeID === 3) {
            teamTwo.push(matchList[i]);
          }
        }
        if (values.TeamChoose === "Bat") {
          if (values.TossWon === "1") {
            if (values.inningsType === "FirstInning") {
              SetBattingTeam(teamOne);
              SetBowlingTeam(teamTwo);
              setDynamicValues("battingTeamValue", 2);
            }
            if (values.inningsType === "SecondInning") {
              SetBattingTeam(teamTwo);
              SetBowlingTeam(teamOne);
              setDynamicValues("battingTeamValue", 3);
            }
          }
          if (values.TossWon === "2") {
            if (values.inningsType === "FirstInning") {
              SetBattingTeam(teamTwo);
              SetBowlingTeam(teamOne);
              setDynamicValues("battingTeamValue", 3);
            }
            if (values.inningsType === "SecondInning") {
              SetBattingTeam(teamOne);
              SetBowlingTeam(teamTwo);
              setDynamicValues("battingTeamValue", 2);
            }
          }
        }
        if (values.TeamChoose === "Bowl") {
          if (values.TossWon === "1") {
            if (values.inningsType === "FirstInning") {
              SetBowlingTeam(teamOne);
              SetBattingTeam(teamTwo);
              setDynamicValues("battingTeamValue", 3);
            }
            if (values.inningsType === "SecondInning") {
              SetBowlingTeam(teamTwo);
              SetBattingTeam(teamOne);
              setDynamicValues("battingTeamValue", 2);
            }
          }
          if (values.TossWon === "2") {
            if (values.inningsType === "FirstInning") {
              SetBowlingTeam(teamTwo);
              SetBattingTeam(teamOne);
              setDynamicValues("battingTeamValue", 2);
            }
            if (values.inningsType === "SecondInning") {
              SetBowlingTeam(teamOne);
              SetBattingTeam(teamTwo);
              setDynamicValues("battingTeamValue", 3);
            }
          }
        }
      }
    });
  }, []);
  const Previous = () => {
    prevStep(1);
  };
  const Continue = () => {
    var isValidate = false;
    var strikeBatsmen = $(
      "#playerSelection .strikeBatsmen option:selected"
    ).val();
    var nonStrikeBatsmen = $(
      "#playerSelection .nonStrikeBatsmen  option:selected"
    ).val();
    var strikeBowler = $(
      "#playerSelection .strikeBowler option:selected"
    ).val();

    if (
      !isValid(strikeBatsmen) ||
      !isValid(nonStrikeBatsmen) ||
      !isValid(strikeBowler)
    ) {
      $("#playerSelection .validation")
        .removeClass("d-none")
        .text("All fields are required");
    } else {
      if (parseInt(strikeBatsmen) === parseInt(nonStrikeBatsmen)) {
        $("#playerSelection .validation")
          .removeClass("d-none")
          .text("Please select both different batsmen");
      } else {
        $("#playerSelection .validation").addClass("d-none");
        isValidate = true;
      }
    }
    if (isValidate) {
      // nextStep(1);
      window.localStorage.setItem("strikerBatsmen", strikeBatsmen);
      window.localStorage.setItem("nonStrikerBatsmen", nonStrikeBatsmen);
      window.localStorage.setItem("strikerBowler", strikeBowler);
      nextStep(1);
    }
  };
  return (
    <div>
      <div className="p-3" id="playerSelection">
        <div className="row ContainerPage_3 mt-3 mb-3 text-center p-2">
          <span>
            <b>
              {values.TossWon === "1" ? teamNames[0].teamA : teamNames[0].teamB}
            </b>{" "}
            has won the toss and decided to {values.TeamChoose} first.
          </span>
        </div>

        <div className="row ContainerPage_3 py-3">
          <div className="col-12 page_3Font1">Striker Batsman</div>
          <div className="col-8 my-2 ContainerPage_4Select">
            <select className="strikeBatsmen">
              <option value="0">Striker Player Name select</option>
              {battingTeam.map((str, index) => {
                var temp = <option value={str.id}>{str.fullName}</option>;
                return temp;
              })}
            </select>
          </div>
        </div>
        <div className="row ContainerPage_3 mt-4 py-3">
          <div className="col-12 page_3Font1">Non Striker Batsman</div>
          <div className="col-9 my-2 ContainerPage_4Select">
            <select className="nonStrikeBatsmen">
              <option value="0">Non Striker Player Name select</option>
              {battingTeam.map((str, index) => {
                var temp = <option value={str.id}>{str.fullName}</option>;
                return temp;
              })}
            </select>
          </div>
        </div>
        <div className="row ContainerPage_3 mt-4 py-3">
          <div className="col-12 page_3Font1">Striker Bowler</div>
          <div className="col-8 my-2 ContainerPage_4Select">
            <select className="strikeBowler">
              <option value="0">Striker Bowler Name select</option>
              {bowlingTeam.map((str, index) => {
                var temp = <option value={str.id}>{str.fullName}</option>;
                return temp;
              })}
            </select>
          </div>
        </div>

        <span
          className="validation centerAlign mt-3 d-none"
          style={{ color: "red", float: "center" }}
        >
          Both batsment not should be same!!
        </span>
      </div>
      <div className="mt-3 mb-3">
        <hr />
        <div className="row">
          <div className="col-2 pe-0 centerAlign">
            {values.IsResumed ? null : (
              <img src="/images/Fill_BackArrow.svg" onClick={Previous} />
            )}
          </div>
          <div
            className="col-8 p-0 centerAlign"
            // style={{
            //   justifyContent: "flex-end",
            //   display: "flex",
            //   alignItems: "flex-end",
            // }}
          >
            <button className="loginbtn" onClick={Continue}>
              {values.IsResumed ? "Resume Match" : "Start Match"}
            </button>
            {/* <img
              src="/images/Fill_NextArrow.svg"
              onClick={Continue}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
