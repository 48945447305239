import React from "react";
import { useState } from "react";
import "../css/common.css";
import $ from "jquery";
import { CustomPostAPI } from "./Utils/APIConnector";

export function ForgotpwdPage() {
  const [phone, setPhone] = useState("");
  const [blankPhone, setBlankPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [blankOtp, setBlankOTP] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmpwd, setConfirmPwd] = useState("");
  const [blankpwd, setblankpwd] = useState("");
  const [confirmblankpwd, setConfirmblankPwd] = useState("");
  const [showLoader, SetShowLoader] = useState(false);
  const [IsPartner, setIsPartner] = useState(localStorage.getItem("IsPartner"));

  var userid = window.localStorage.getItem("userid");
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  const RemoveValidation = () => {
    if (phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (phone === "") {
        setBlankPhone("PhoneNumber is required!!");
      } else if (!pattern.test(phone)) {
        setBlankPhone("Please enter only number.");
      } else if (phone.length != 10) {
        setBlankPhone("Please enter valid phone number.");
      } else {
        setBlankPhone("");
      }
    }

    if (otp === "") {
      setBlankOTP("OTP is required");
    } else {
      setBlankOTP("");
    }

    if (pwd === "") {
      setblankpwd("Password is required!!");
    } else {
      setblankpwd("");
    }

    if (confirmblankpwd === "") {
      setConfirmblankPwd("Field is required!!");
    } else {
      if (pwd != confirmpwd) {
        setConfirmblankPwd("Both passwords are not same.");
      } else {
        setConfirmblankPwd("");
      }
    }
  };
  const ForgotPWD = async () => {
    SetShowLoader(true);
    if (phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (phone === "") {
        setBlankPhone("PhoneNumber is required!!");
        SetShowLoader(false);
      } else if (!pattern.test(phone)) {
        setBlankPhone("Please enter only number.");
        SetShowLoader(false);
      } else if (phone.length != 10) {
        setBlankPhone("Please enter valid phone number.");
        SetShowLoader(false);
      } else {
        setBlankPhone("");
        var raw = JSON.stringify({
          PhoneNumber: phone,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(
          process.env.REACT_APP_API_BASE_URL + "/Auth/ExistingUser",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            window.localStorage.setItem(
              "phoneno",
              result.userDetails.phoneNumber
            );
            window.localStorage.setItem("userid", result.userDetails.id);
            window.localStorage.setItem("flag", 2);
            $("#hdnOtpDiv").removeClass("d-none");
            $(".loginbtn").text("CHANGE PASSWORD");
            $("#verifyOTP").removeClass("d-none");
            $("#firstOtpbtn").addClass("d-none");
            SetShowLoader(false);
          })
          .catch((error) => console.log("error", error));
      }
    }
  };
  const changePassword = async () => {
    SetShowLoader(true);
    if (phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (phone === "") {
        setBlankPhone("Mobile Number is required!!");
        SetShowLoader(false);
      } else if (!pattern.test(phone)) {
        setBlankPhone("Please enter only number.");
        SetShowLoader(false);
      } else if (phone.length != 10) {
        setBlankPhone("Please enter valid phone number.");
        SetShowLoader(false);
      } else {
        setBlankPhone("");
        if (otp === "") {
          setBlankOTP("OTP is required");
          SetShowLoader(false);
        } else {
          setBlankOTP("");
          var raw = JSON.stringify({
            PhoneNumber: phone,
            VerifyCode: otp,
          });
          await CustomPostAPI("/Auth/VerifyCode", raw).then((result) => {
            if (result === undefined || result === "") {
              alert("Something went wrong");
              SetShowLoader(false);
            } else {
              result = JSON.parse(result);
              if (result.status === "Success") {
                if ($("#hdnOtpDiv").is(":visible")) {
                  $(".HdnContactInputDiv").addClass("d-none");
                  $(".HdnAnotherInputDiv").removeClass("d-none");
                  setblankpwd("");
                  setConfirmblankPwd("");
                  $("#firstOtpbtn").addClass("d-none");
                  $("#verifyOTP").addClass("d-none");
                  $("#secondOtpbtn").removeClass("d-none");
                } else {
                  $(".HdnAnotherInputDiv").addClass("d-none");
                  $(".HdnContactInputDiv").removeClass("d-none");
                }

                SetShowLoader(false);
              } else {
                alert(result.message);
                SetShowLoader(false);
              }
            }
          });
        }
      }
    }
  };
  const changeFinalPassword = async () => {
    SetShowLoader(true);
    if (pwd === "") {
      setblankpwd("Password is required!!");
      SetShowLoader(false);
    } else {
      setblankpwd("");
    }

    if (confirmpwd === "") {
      setConfirmblankPwd("Field is required!!");
      SetShowLoader(false);
    } else {
      if (pwd != confirmpwd) {
        setConfirmblankPwd("Both passwords are not same.");
        SetShowLoader(false);
      } else {
        setConfirmblankPwd("");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        var raw = JSON.stringify({
          UserId: userid,
          Password: pwd,
        });
        await CustomPostAPI("/Auth/forgotpassword", raw).then((result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
            SetShowLoader(false);
          } else {
            result = JSON.parse(result);
            SetShowLoader(false);
            localStorage.setItem("loggedin", "true");
            window.location = "/home";
          }
        });
      }
    }
  };
  const showPassword = (itemId, inputId) => {
    const inputElement = $("#" + inputId);
    if (itemId === "pwdInput" || itemId === "confirmPwdInput") {
      var icon = inputElement.siblings(".openEye");
      if (icon.hasClass("d-none")) {
        inputElement.attr("type", "text");
        icon.removeClass("d-none");
        icon.siblings(".closeEye").addClass("d-none");
      } else {
        inputElement.attr("type", "password");
        icon.addClass("d-none");
        icon.siblings(".closeEye").removeClass("d-none");
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="row px-2 mt-5">
          <div className="col-12">
            <a href="/login">
              <img src="/images/backArrow.svg" />
            </a>
          </div>
        </div>
        <div className="px-2" style={{ marginTop: "15%" }}>
          {IsPartner === "true" && (
            <div className="col-12 mt-4 pt-4 text-center">
              <span className="cricketAppTxt">CricBuddy partner program</span>
            </div>
          )}
          <div className="col-12 pt-3">
            <span className="formHeading">Change Password</span>
          </div>
          <div className="col-12 pt-3">
            <p className="paraTxt">
              Amet minim mollit non deserunt ullamcoei sitaliqua dolor do amet
              sintelit officia.
            </p>
          </div>

          <div className="row  px-2 ">
            <div className="col-12 mt-3 ">
              <div className="posRel HdnContactInputDiv">
                <img src="/images/contact.svg" className="inputIcons1" />
                <input
                  type="text"
                  className="form-control formControl"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Mobile Number"
                  autoComplete="new-password"
                />
                <span style={{ color: "red" }}>{blankPhone}</span>
              </div>
            </div>
            <div className="col-12  pt-3">
              <div className="d-none posRel HdnContactInputDiv" id="hdnOtpDiv">
                <img src="/images/OTP1.svg" className="inputIcons1" />
                <input
                  type="text"
                  className="form-control formControl"
                  value={otp}
                  placeholder="OTP"
                  onChange={(e) => setOTP(e.target.value)}
                />
                <span style={{ color: "red" }}>{blankOtp}</span>
              </div>
            </div>
            <div className="col-12  pt-3">
              <div className="d-none posRel HdnAnotherInputDiv">
                <img src="/images/pwd.svg" className="inputIcons1" />
                <input
                  id="newPass"
                  type="password"
                  className="form-control formControl"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  placeholder="New Password"
                  autoComplete="new-password"
                />
                <span style={{ color: "red" }}>{blankpwd}</span>
                <img
                  src="/images/open_eye.svg"
                  className="eyeIcon openEye d-none"
                  onClick={() => showPassword("pwdInput", "newPass")}
                />
                <img
                  src="/images/close_eye.svg"
                  className="eyeIcon closeEye  "
                  onClick={() => showPassword("pwdInput", "newPass")}
                />
              </div>
            </div>
            <div className="col-12  pt-3">
              <div className="d-none posRel HdnAnotherInputDiv">
                <img src="/images/pwd.svg" className="inputIcons1" />
                <input
                  id="confirmPass"
                  type="password"
                  className="form-control formControl"
                  value={confirmpwd}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  placeholder="Confirm Password"
                  autoComplete="new-password"
                />
                <span style={{ color: "red" }}>{confirmblankpwd}</span>
                <img
                  src="/images/open_eye.svg"
                  className="eyeIcon openEye d-none"
                  onClick={() => showPassword("confirmPwdInput", "confirmPass")}
                />
                <img
                  src="/images/close_eye.svg"
                  className="eyeIcon closeEye "
                  onClick={() => showPassword("confirmPwdInput", "confirmPass")}
                />
              </div>
            </div>
            <div className="col-12 text-center pt-2">
              <button
                type="submit"
                className="mt-2 loginbtn col-6"
                id="firstOtpbtn"
                onClick={ForgotPWD}
              >
                SEND OTP
              </button>
              <button
                type="submit"
                className="loginbtn w-auto col-6 d-none"
                id="verifyOTP"
                onClick={changePassword}
              >
                Verify OTP
              </button>
              <button
                type="submit"
                className="mt-2 loginbtn w-auto col-8 d-none"
                id="secondOtpbtn"
                onClick={changeFinalPassword}
              >
                CHANGE PASSWORD
              </button>
              {showLoader ? (
                <img
                  id="loaderImg"
                  className="col-1"
                  height="30px"
                  width="30px"
                  src="/images/loder.svg"
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 px-0">
            <img
              src="/images/Cricket_Line _Art.svg"
              className="img-fluid bottomImg mt-5"
            />
          </div>
        </div>
      </div>
    </>
  );
}
