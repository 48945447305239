import React, { useState, useEffect } from "react";
import {
  minDate,
  maxDate,
  DBConvertDatetime,
  dateInputValue,
} from "./constants";
import $ from "jquery";
import { CustomGetAPI, PostAPI, PutAPI } from "./Utils/APIConnector";

export default function MatchDetailsEditPage(props) {
  const ConvertDatetime = DBConvertDatetime(
    props.allMatchesData.dateAndTime
  ).split(" ");
  const [MatchName, setMatchName] = useState(props.allMatchesData.name);
  const [TypeOfMatch, setTypeOfMatch] = useState(
    props.allMatchesData.sportTypeName
  );
  const [date, setDate] = useState(
    dateInputValue(props.allMatchesData.dateAndTime)
  );
  const [time, setTime] = useState(ConvertDatetime[1]);
  const [overs, setOvers] = useState(props.allMatchesData.overs);
  const [listofGrounds, setlistofGrounds] = useState([]);
  const userid = window.localStorage.getItem("userid");
  useEffect(async () => {
    var tempstr = null;
    await CustomGetAPI(
      "/User/GroundList?userid=" + userid + "&name=" + tempstr
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setlistofGrounds(result.listOfGround);
      }
    });
  }, [userid]);

  const SaveDetails = async () => {
    var i = 0;
    var mName = $("#mName").val();
    var mGName = $("#MatchGroundID option:selected").val();
    var mDate = $("#mDate").val();
    var mTime = $("#mTime").val();
    var mOvers = $("#mOvers").val();

    if (mName != "") {
      i++;
    }
    if (mGName != 0) {
      i++;
    }
    if (mDate != "") {
      i++;
    }
    if (mTime != "") {
      i++;
    }
    if (mOvers != "") {
      i++;
    }
    var SportTypeId = 0;
    if ($(".EMradioBtn1").is(":checked")) {
      SportTypeId = 4;
    }
    if ($(".EMradioBtn2").is(":checked")) {
      SportTypeId = 3;
    }
    if (i < 5) {
      $(".errorMsg").removeClass("d-none");
    } else {
      $(".errorMsg").addClass("d-none");

      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Access-Control-Allow-Origin", "*");

      var raw = JSON.stringify({
        Name: mName,
        GroundId: mGName,
        Overs: mOvers.trim(),
        SportTypeId: SportTypeId,
        MatchDateTime: mDate + " " + mTime,
        MatchId: props.allMatchesData.id,
      });
      await PostAPI("/CreateMatches/CreatMatches/", raw).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          props.onHide();
          window.location.reload();
        }
      });
    }
  };
  return (
    <div>
      <div className="">
        <div className="row text-left my-2">
          <div className="col-12">
            <label className="form-label textLabel">Match Name </label>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              name=""
              id="mName"
              className="form-control"
              placeholder=""
              value={MatchName}
              onChange={(e) => setMatchName(e.target.value)}
              aria-describedby="helpId"
            />
          </div>
        </div>

        <div className="row text-left my-2">
          <div className="col-6">
            <label className="form-label textLabel">On Which Ground? </label>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <select
              className="form-select "
              id="MatchGroundID"
              aria-label="Select Ground"
              // value={GroundName}
              // onChange={(e) => setGroundName(e.target.value)}
            >
              <option
                value="0"
                defaultValue={true}
                style={{ backgroundColor: "black !important" }}
              >
                Ground
              </option>
              {listofGrounds.map((data, i) => {
                var returnData;
                returnData =
                  data.groundId === props.allMatchesData.groundId ? (
                    <option key={i} value={data.groundId} selected>
                      {data.groundFullName}
                    </option>
                  ) : (
                    <option key={i} value={data.groundId}>
                      {data.groundFullName}
                    </option>
                  );

                return returnData;
              })}
            </select>
          </div>
        </div>
        <div className="row text-left my-4">
          <div className="col-12">
            <label className="form-label textLabel">
              On Which Date and Time?
            </label>
          </div>
          <div className="row my-2 ms-0">
            <div className="col-6 ps-0">
              <input
                type="date"
                name=""
                id="mDate"
                className="form-control"
                placeholder=""
                aria-describedby="helpId"
                min={minDate()}
                max={maxDate()}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="col-6 pe-0">
              {/* {DBConvertDatetime(str.matchesPackage.dateAndTime)} */}
              <input
                type="time"
                name=""
                id="mTime"
                className="form-control"
                placeholder=""
                aria-describedby="helpId"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="row text-left my-2">
              <div className="col-12">
                <label className="form-label textLabel">
                  Types of Cricket{" "}
                </label>
              </div>

              <div className="row m-1">
                <div className="col-6 p-0 mx-0">
                  <input
                    type="radio"
                    id="TennisBallRadio"
                    className="form-check-input EMradioBtn1"
                    value="TennisBall"
                    name="TypeOfMatch"
                    checked={TypeOfMatch === "TennisBall"}
                    defaultChecked
                    onChange={(e) => setTypeOfMatch(e.target.value)}
                  />
                  <img src="./images/TennisBall.svg" alt="" />
                </div>
                <div className="col-6 p-0">
                  <input
                    type="radio"
                    id="SeasonBallRadio"
                    className="form-check-input  EMradioBtn2"
                    value="SeasonBall"
                    name="TypeOfMatch"
                    checked={TypeOfMatch === "SeasonBall"}
                    onChange={(e) => setTypeOfMatch(e.target.value)}
                  />
                  <img src="./images/SeasonBall.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row text-left my-2">
              <div className="col-12">
                <label className="form-label textLabel">Overs </label>
              </div>
            </div>
            <div className="col-10">
              <input
                type="text"
                name=""
                id="mOvers"
                className="form-control"
                placeholder=""
                aria-describedby="helpId"
                value={overs.trim()}
                onChange={(e) => setOvers(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div
          className="row my-2 mx-1 errorMsg d-none justify-content-end"
          style={{ color: "red" }}
        >
          All fields are Required!
        </div>
        <div className="row my-4">
          <div className="col-12">
            <button
              className="loginbtn"
              style={{ float: "right" }}
              onClick={SaveDetails}
            >
              Save
            </button>
            <div className="mx-3 cancleLinkBtn" onClick={props.onHide}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
