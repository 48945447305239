import React from "react";
import { useState, useEffect, useRef } from "react";
import "../css/common.css";
import $ from "jquery";
import { isValid } from "./constants";
import { CustomGetAPI, CustomPostAPI } from "./Utils/APIConnector";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
var typingTimer;
var doneTypingInterval = 1000;
export function Registration() {
  //   localStorage.clear();
  const [showLoader, SetShowLoader] = useState(false);
  const [location, setLocation] = useState("");
  const [cityArea, setResult] = useState([]);
  const [showLoaderCity, SetShowLoaderCity] = useState(false);
  const [blankLocation, setBlankLocation] = useState("");
  const [referralCode, setReferralCode] = useSearchParams();
  const [cookies, setCookie] = useCookies(["User"]);

  useEffect(() => {
    const referCode = referralCode.get("referby");
    // console.log("this-->" + referCode);
  }, []);
  const [IsPartner, setIsPartner] = useState(localStorage.getItem("IsPartner"));
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          $(".suggBox").addClass("d-none");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const validateMobileNo = () => {
    var mobileNo = $("#UserMobileNo").val().slice(0, 10);
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#errorTxt").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
    }
  };
  const validateOTP = () => {
    var otp = $("#UserOTP").val().slice(0, 6);
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserOTP").parent().find("span").text("");
    $("#errorTxt").text("");
    $("#UserOTP").val(otp);
    if (!pattern.test(otp)) {
      $("#UserOTP").parent().find("span").text("Please enter valid OTP");
    }
  };
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");

  const SendOTP = async () => {
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      SetShowLoader(false);
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      SetShowLoader(false);
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
    });
    // $("#OTPInputDiv").removeClass("d-none");
    // $("#sendOTPBtn").addClass("d-none");
    // $("#verifyOTPBtn").removeClass("d-none");
    // $("#ResendOTP").removeClass("d-none");
    timerOTP();
    SetShowLoader(false);

    await CustomPostAPI("/Auth/LoginUser", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
        SetShowLoader(false);
      } else {
        result = JSON.parse(result);
        if (result.status === "AlreadyRegistered") {
          $("#errorTxt").parent().removeClass("d-none");
          var spanText =
            'Your mobile number is already registered, please <a style="color:#0d6efd;text-decoration: underline;" href="/login">login here</a>';
          $("#errorTxt").text("");
          $("#errorTxt").append(spanText);
          SetShowLoader(false);
        } else if (
          result.status === "NotRegistered" ||
          result.status === "NotVerified" ||
          result.status === "NotUpdated"
        ) {
          $("#OTPInputDiv").removeClass("d-none");
          $(".signuppolicy").addClass("d-none");
          $("#sendOTPBtn").addClass("d-none");
          $("#verifyOTPBtn").removeClass("d-none");
          $("#ResendOTP").removeClass("d-none");
          timerOTP();
          SetShowLoader(false);
        } else {
          alert(result.message);
          SetShowLoader(false);
        }
      }
    });
  };
  const verifyOTP = async () => {
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    var OTP = $("#UserOTP").val();
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      return;
    } else if (OTP.length === 0) {
      $("#UserOTP").parent().find("span").text("Please enter valid OTP");
      return;
    } else if (OTP.length < 6) {
      $("#UserOTP").parent().find("span").text("Please enter 6 digit OTP");
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
      VerifyCode: OTP,
    });

    SetShowLoader(false);
    // $("#verifyOTPBtn").addClass("d-none");
    // $("#OTPInputDiv").addClass("d-none");
    // $("#ResendOTP").addClass("d-none");
    // $("#errorTxt").parent().removeClass("d-none");
    // var spanText =
    //   "Your mobile number is verified, please provide below information to complete Sign-up process.";
    // $("#errorTxt").append(spanText);
    // $("#errorTxt").css("color", "#339900");
    // $("#UserRegistrationForm").removeClass("d-none");
    // $(".bottomImg").css("position", "relative");
    // // $("#UserMobileNo").val(result.mobilenumber);
    // $("#UserMobileNo").attr("disabled", "disabled");
    // $("#RegisterOTPBtn").removeClass("d-none");
    await CustomPostAPI("/Auth/VerifyWhatsappCode", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
        SetShowLoader(false);
      } else {
        result = JSON.parse(result);
        if (result.status === "Success") {
          SetShowLoader(false);
          $("#verifyOTPBtn").addClass("d-none");
          $("#OTPInputDiv").addClass("d-none");
          $("#ResendOTP").addClass("d-none");
          $("#errorTxt").parent().removeClass("d-none");
          var spanText =
            "Your mobile number is verified, please provide below information to complete Sign-up process.";
          $("#errorTxt").append(spanText);
          $("#errorTxt").css("color", "#339900");
          $("#UserRegistrationForm").removeClass("d-none");
          $(".bottomImg").css("position", "relative");
          $("#UserMobileNo").val(result.mobilenumber);
          $("#UserMobileNo").attr("disabled", "disabled");
          $("#RegisterOTPBtn").removeClass("d-none");
        } else if (result.status === "Invalid") {
          SetShowLoader(false);
          $("#errorTxt").text("Please enter valid OTP");
        } else if (result.status === "expired") {
          $("#errorTxt").text("OTP expired");
        }
      }
    });
  };
  const suggKeyDown = () => {
    $(".suggBox").removeClass("d-none");
    $(".suggBox").addClass("d-block");
    $("#areacitysuggestion").removeAttr("areaid");
    $("#areacitysuggestion").removeAttr("cityid");
    clearTimeout(typingTimer);
  };
  const suggKeyup = () => {
    $(".suggBox").removeClass("d-none");
    $(".suggBox").addClass("d-block");
    $("#areacitysuggestion").removeAttr("areaid");
    $("#areacitysuggestion").removeAttr("cityid");
    clearTimeout(typingTimer);
    var element = $(this);
    typingTimer = setTimeout(function () {
      doneTyping(element);
    }, doneTypingInterval);
  };
  const doneTyping = async () => {
    SetShowLoaderCity(true);
    if (location === "") {
      setBlankLocation("Location is required!!");
      SetShowLoaderCity(false);
    } else {
      setBlankLocation("");

      await CustomGetAPI("/User/GetLocation/" + location).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
          SetShowLoader(false);
        } else {
          result = JSON.parse(result);
          $(".suggBox").addClass("d-block");
          var sugData = result.areacity;
          var sugArray = [];
          for (var i = 0; i < sugData.length; i++) {
            if (sugData[i].areaID === 0) {
              sugArray.push(
                <p
                  className="areacitydiv p-2 w-100"
                  style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    paddingBottom: "0px",
                  }}
                  onClick={selectAreaCity}
                  cityid={sugData[i].cityID}
                  areaid={sugData[i].areaID}
                  data-name={sugData[i].cityName}
                >
                  <img src="/images/location.svg" /> {sugData[i].cityName}
                </p>
              );
            } else {
              sugArray.push(
                <p
                  className="areacitydiv p-2  w-100"
                  style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    paddingBottom: "0px",
                  }}
                  onClick={selectAreaCity}
                  cityid={sugData[i].cityID}
                  areaid={sugData[i].areaID}
                  data-name={sugData[i].areaName + "," + sugData[i].cityName}
                >
                  <img src="/images/location.svg" /> {sugData[i].areaName},{" "}
                  {sugData[i].cityName}
                </p>
              );
            }
          }

          setResult(sugArray);
          if (sugData.length === 0) {
            $(".suggBox").addClass("d-none");
          } else {
            SetShowLoaderCity(false);
          }
        }
      });
    }
  };
  const selectAreaCity = (event) => {
    $("#areacitysuggestion").val("");
    $("#areacitysuggestion").attr("cityid", "");
    var city = event.target.attributes[3].value;
    $("#areacitysuggestion").val(city);
    setLocation(city);
    if (event.target.attributes[2].value != "0") {
      $("#areacitysuggestion").attr("areaid", "");
      $("#areacitysuggestion").attr("areaid", event.target.attributes[2].value);
    }
    $("#areacitysuggestion").attr("cityid", event.target.attributes[1].value);
    $("#areacitysuggestion").attr("flagsuggestion", "1");
    $(".suggBox").addClass("d-none");
  };

  const registerUser = async () => {
    SetShowLoader(true);
    var validationCount = 0;
    var UserFirstName = $("#UserFirstName").val();
    var UserLastName = $("#UserLastName").val();
    var CityID = parseInt($("#areacitysuggestion").attr("cityid"));
    var AreaID = parseInt($("#areacitysuggestion").attr("areaid"));
    var UserPassword = $("#UserPassword").val();
    var UserMobileNo = $("#UserMobileNo").val();
    if (
      UserFirstName === "" ||
      UserFirstName === undefined ||
      UserFirstName === null
    ) {
      $("#UserFirstName").parent().parent().find("span").html("Required");
      ++validationCount;
    } else {
      $("#UserFirstName").parent().parent().find("span").html("");
    }
    if (
      UserLastName === "" ||
      UserLastName === undefined ||
      UserLastName === null
    ) {
      $("#UserLastName").parent().parent().find("span").html("Required");
      ++validationCount;
    } else {
      $("#UserLastName").parent().parent().find("span").html("");
    }
    if ((isNaN(AreaID) && isNaN(CityID)) || isNaN(CityID)) {
      $("#areacitysuggestion")
        .parent()
        .parent()
        .find("span")
        .html("Please select location");
      ++validationCount;
    } else {
      $("#areacitysuggestion").parent().parent().find("span").html("");
    }
    // if (
    //   UserPassword === "" ||
    //   UserPassword === undefined ||
    //   UserPassword === null
    // ) {
    //   $("#UserPassword").parent().parent().find("span").html("Required");
    //   ++validationCount;
    // } else {
    //   $("#UserPassword").parent().parent().find("span").html("");
    // }
    // if (UserPassword.length < 8) {
    //   $("#UserPassword")
    //     .parent()
    //     .parent()
    //     .find("span")
    //     .html("Your password should have at least 8 or more characters");
    //   ++validationCount;
    // } else {
    //   $("#UserPassword").parent().parent().find("span").html("");
    // }
    if (validationCount === 0) {
      var raw = JSON.stringify({
        FirstName: UserFirstName,
        LastName: UserLastName,
        PhoneNumber: UserMobileNo,
        CityId: CityID,
        AreaId: AreaID,
        // password: UserPassword,
        isfromadminpanel: false,
        isfromvenueowner: false,
      });

      await CustomPostAPI("/Auth/RegisterUser", raw).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
          SetShowLoader(false);
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            const cookies = new Cookies();
            // cookies.set("IsPersist", "yes", {
            //   maxAge: 1440000,
            // });
            window.localStorage.setItem(
              "phoneno",
              JSON.stringify(result.userDetails.phoneNumber)
            );
            setCookie("AuthToken", result.token, { path: "/home" });
            window.localStorage.setItem("userid", result.userDetails.id);
            window.localStorage.setItem("flag", 1);
            window.localStorage.setItem("loggedin", "true");
            window.localStorage.setItem("UserType", "User");
            window.localStorage.setItem(
              "UserName",
              result.userDetails.firstName
                ? result.userDetails.firstName
                : "" + " " + result.userDetails.lastName
                ? result.userDetails.lastName
                : ""
            );
            if (isValid(result.userDetails.profileImage)) {
              window.localStorage.setItem(
                "UserProfileImage",
                `/userimage/` + result.userDetails.profileImage
              );
            }
            window.localStorage.setItem(
              "UserMobileNo",
              result.userDetails.phoneNumber
            );
            window.localStorage.setItem(
              "UserProfileStatus",
              result.progressbarValue
            );
            const redirectURL = window.localStorage.getItem("RedirectURL");
            if (
              redirectURL !== null &&
              redirectURL !== "" &&
              redirectURL !== undefined
            ) {
              window.location = redirectURL;
            } else {
              window.location = "/home";
            }
          } else {
            alert(result.message);
            SetShowLoader(false);
          }
        }
      });
    } else {
      $(".bottomImg").css("position", "relative");
      SetShowLoader(false);
      return;
    }
  };
  const resendOTP = () => {
    var resendLink = $("#ResendOTP").find("a").addClass("d-none");
    $("#errorTxt").text("");
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      SetShowLoader(false);
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      SetShowLoader(false);
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_BASE_URL + "/Auth/ExistingUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          $("#ResendOTP").removeClass("d-none");
          timerOTP();
          SetShowLoader(false);
        } else {
          alert(result.message);
          SetShowLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const timerOTP = () => {
    var timerDiv = $("#countdowntimer");
    var resendLink = $("#ResendOTP").find("a");
    var timeleft = 59;
    var downloadTimer = setInterval(function () {
      timeleft--;
      timeleft = (timeleft < 10 ? "0" : "") + timeleft.toString();
      timerDiv.html(timeleft);
      if (timeleft <= 0) {
        $(resendLink).removeClass("d-none");
        clearInterval(downloadTimer);
      }
    }, 1000);
  };
  const showPassword = () => {
    if ($(".openEye").is(":visible")) {
      $("#UserPassword").attr("type", "text");
      $(".openEye").addClass("d-none");
      $(".closeEye").removeClass("d-none");
    } else {
      $("#UserPassword").attr("type", "password");
      $(".closeEye").addClass("d-none");
      $(".openEye").removeClass("d-none");
    }
  };
  const showpwddiv = () => {
    $(".openEye").removeClass("d-none");
  };

  return (
    <>
      <div className="container">
        <div className="row px-2 mt-5">
          <div className="col-12">
            <a href={IsPartner === "true" ? "/partner" : "/welcome"}>
              <img src="/images/backArrow.svg" />
            </a>
          </div>
        </div>
        <div style={{ marginTop: "0%" }}>
          <div className="col-12 ">
            <img src="/images/avtar.svg" className="avtarImg" />
          </div>
          <div className="col-12 text-center mt-2">
            <span className="formHeading">Create Account</span>
          </div>
          {IsPartner === "true" && (
            <div className="col-12   text-center">
              <span className="cricketAppTxtReg">
                CricBuddy partner program
              </span>
            </div>
          )}
        </div>
        <div className="row p-2">
          <div className="col-12  pt-3">
            <div className="posRel">
              <img src="/images/contact.svg" className="inputIcons1" />
              <input
                id="UserMobileNo"
                type="number"
                inputMode="numeric"
                className="form-control disableClass formControl"
                placeholder="Mobile Number"
                autoComplete="new-password"
                onChange={validateMobileNo}
                maxLength={10}
              />
              <span className="ErrorText" style={{ color: "#A51E22" }}></span>
            </div>
          </div>
          <div className="col-12  pt-2">
            <div className="d-none posRel" id="OTPInputDiv">
              <img src="/images/OTP1.svg" className="inputIcons1 topotp" />
              <input
                id="UserOTP"
                type="number"
                inputMode="numeric"
                className="form-control formControl"
                placeholder="OTP"
                onChange={validateOTP}
              />
              <span className="ErrorText" style={{ color: "#A51E22" }}></span>
            </div>
          </div>
          <div id="UserRegistrationForm" className="d-none">
            <div className="col-12 pt-2">
              <div className="row">
                <div className="col-6">
                  <div className="posRel">
                    <input
                      id="UserFirstName"
                      type="text"
                      className="form-control disableClass formControl"
                      placeholder="First Name"
                      autoComplete="new-password"
                    />

                    <img src="/images/profile.svg" className="inputIcons" />
                  </div>
                  <span
                    style={{ color: "rgb(165, 30, 34)" }}
                    className="errorDiv"
                  ></span>
                </div>
                <div className="col-6 ">
                  <div className="posRel">
                    <img src="/images/profile.svg" className="inputIcons" />
                    <input
                      id="UserLastName"
                      type="text"
                      className="form-control disableClass formControl"
                      placeholder="Last Name"
                      autoComplete="new-password"
                    />
                  </div>
                  <span
                    style={{ color: "rgb(165, 30, 34)" }}
                    className="errorDiv"
                  ></span>
                </div>
              </div>
            </div>
            <div className="col-12  pt-2">
              <div className="posRel">
                <img src="/images/location.svg" className="inputIcons1" />
                <input
                  type="text"
                  className="form-control disableClass formControl"
                  id="areacitysuggestion"
                  placeholder="Location"
                  flagsuggestion="0"
                  autoComplete="none"
                  value={location}
                  onKeyUp={suggKeyup}
                  onKeyDown={suggKeyDown}
                  onChange={(e) => setLocation(e.target.value)}
                />

                <div
                  className="suggBox mt-1"
                  style={{ overflow: "scroll", maxHeight: "170px" }}
                  ref={wrapperRef}
                >
                  {showLoaderCity ? (
                    <>
                      <div className="text-center">
                        <img
                          id="loaderImg"
                          className="col-1 hidden"
                          height="30px"
                          width="30px"
                          src="/images/loder.svg"
                        />
                      </div>
                    </>
                  ) : (
                    cityArea
                  )}
                </div>

                <input
                  type="text"
                  className="d-none"
                  id="areacitysuggestion-fake"
                  placeholder="Location"
                  flagsuggestion="0"
                  autoComplete="on"
                />
              </div>
              <span style={{ color: "rgb(165, 30, 34)" }}></span>
            </div>
            <span
              className="suggestionMsg"
              style={{ fontSize: "14px", opacity: "0.5" }}
            >
              Start typing your city or area and choose from the options
              provided.
            </span>
            {/* <div className="col-12 pt-2">
              <div className="posRel">
                <img src="/images/pwd.svg" className="inputIcons1" />
                <input
                  type="password"
                  id="UserPassword"
                  className="form-control disableClass formControl"
                  placeholder="Password"
                  autoComplete="new-password"
                  onFocus={showpwddiv}
                />
                <img
                  src="/images/view1.svg"
                  className="eyeIcon openEye d-none"
                  onClick={showPassword}
                />
                <img
                  src="/images/hidden1.svg"
                  className="eyeIcon closeEye d-none"
                  onClick={showPassword}
                />
              </div>
              <span style={{ color: "rgb(165, 30, 34)" }}></span>
            </div> */}
          </div>

          <div className="col-12 text-center my-2">
            <div className="justify-content-center">
              <button
                type="submit"
                id="sendOTPBtn"
                className="my-2 col-6 w-auto loginbtn"
                onClick={SendOTP}
              >
                Send Verification Code
              </button>
              <button
                type="submit"
                id="verifyOTPBtn"
                className="mt-2 col-6 loginbtn d-none"
                onClick={verifyOTP}
              >
                Verify OTP
              </button>
              <div className="col-12 text-center my-2 resendOTPClass ">
                <span
                  className="errorTxt"
                  id="errorTxt"
                  style={{ color: "#A51E22", fontSize: "14px" }}
                ></span>
              </div>
              <button
                type="submit"
                id="RegisterOTPBtn"
                className="mt-2 col-6 loginbtn d-none"
                onClick={registerUser}
              >
                Get Started
              </button>
              {showLoader ? (
                <img
                  id="loaderImg"
                  className="col-1 hidden"
                  height="30px"
                  width="30px"
                  src="/images/loder.svg"
                />
              ) : null}
            </div>
            <small className="signuppolicy justify-content-center">
              By signing up , you agree to the{" "}
              <a href="/terms-and-conditions">Terms of service</a> and{" "}
              <a href="/privacy-policy">Privacy policy</a>
            </small>

            <div id="ResendOTP" className="d-none px-2">
              <div className="pt-2">
                <h6>
                  Resend OTP in 00:<span id="countdowntimer">59</span>
                </h6>
              </div>
              <a
                style={{ color: "#0d6efd", textDecoration: "underline" }}
                className="d-none"
                onClick={resendOTP}
              >
                Resend OTP
              </a>
            </div>
          </div>
        </div>
        <div className="row Fotterimg">
          <div className="col-12  d-flex justify-content-center px-0 ">
            <img src="/images/Cricket_Line _Art.svg" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
}
