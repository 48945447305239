import { useEffect, useState } from "react";
import Loader from "./Utils/Loader";
import { UserDetails } from "./Utils/PopupModal/UserDetails";
import "../css/common.css";
import { Card } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { DBConvertDatetime, GetPlayerRole } from "./constants";
import { useDispatch } from "react-redux";
import { changeColor } from "./redux/reducer";
import { useSelector } from "react-redux";
import { GetAPI, PostAPI } from "./Utils/APIConnector";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
export default function FriendRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allMatchesData, setAllMatchesData] = useState([]);
  const [isLoading, SetIsLoading] = useState(true);
  const [userPopUp, SetUserPopUp] = useState(false);
  const [userDetails, SetUserDetails] = useState({});
  const [bowlingStyle, SetBowlingStyle] = useState("");
  const [battingStyle, SetBattingStyle] = useState("");
  const [isPresent, setisPresent] = useState([]);
  const [showLoader, SetShowLoader] = useState(false);
  const [PlayerStats, SetPlayerStats] = useState([]);
  var matchRequest = [];
  var appliedMatches = [];

  const userType = window.localStorage.getItem("UserType");
  const userid = window.localStorage.getItem("userid");

  useEffect(async () => {
    if (userType === null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    await GetAPI("/CreateMatches/GetMatchRequests/" + userid).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.matchList;
        for (var i = 0; i < matchList.length; i++) {
          matchRequest.push(matchList[i]);
        }
        matchRequest.reverse();
        setTimeout(() => {
          SetIsLoading(false);
        }, 1000);
        setAllMatchesData(matchRequest);
        var isarray = matchRequest.filter((item) => item.status === 1);
        setisPresent(isarray);
      }
    });
    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };
    // fetch(
    //   "https://playerstation.azurewebsites.net/api/CreateMatches/GetMatchRequests/" +
    //     userid,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     var matchList = result.matchList;
    //     for (var i = 0; i < matchList.length; i++) {
    //       matchRequest.push(matchList[i]);
    //     }
    //     matchRequest.reverse();
    //     setTimeout(() => {
    //       SetIsLoading(false);
    //     }, 1000);
    //     setAllMatchesData(matchRequest);
    //     var isarray = matchRequest.filter((item) => item.status === 1);
    //     setisPresent(isarray);
    //   })
    //   .catch((error) => console.log("error", error));
  }, []);

  const showUserDetails = async (detailsUserID) => {
    await GetAPI(
      "/MatchScoreCard/GetPlayersStatistics/?userid=" + parseInt(detailsUserID)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        if (result.status === "success") {
          SetUserDetails(result.userDetails);
          SetBattingStyle(result.battingstyle);
          SetBowlingStyle(result.bowlingstyleName);
          var player = new Array();
          player.push(result.playerStatistics);

          SetPlayerStats(player);
          SetUserPopUp(true);
        }
      }
    });

    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };
    // fetch(
    //   "https://playerstation.azurewebsites.net/api/User/GetAllUser/" +
    //     detailsUserID,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result.status === "Success") {
    //       SetUserDetails(result.userDetails);
    //       SetBattingStyle(result.battingstyle);
    //       SetBowlingStyle(result.bowlingstyleName);
    //       SetUserPopUp(true);
    //       // SetShowPopup(true);
    //     }
    //     // else{
    //     //     alert(result.error);
    //     // }
    //   })
    //   .catch((error) => console.log("error", error));
  };
  const closePopupHandler = () => {
    SetUserPopUp(false);
  };
  async function confirmMatch(statusType, matchId) {
    if ($(`#RequestIcon${matchId}`).find(".loaderIcon").toggleClass("d-none"));
    if ($(`#RequestIcon${matchId}`).find(".actionIcon").toggleClass("d-none"));
    var matchID = parseInt(matchId);
    var statusCode =
      statusType === "accept" ? 2 : statusType === "delete" ? 3 : 1;

    var raw = JSON.stringify({
      cricketmatchid: matchID,
      StatusCode: statusCode,
    });
    await PostAPI("/CreateMatches/UpdateStatus", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        SetShowLoader(false);
        window.location.reload();
      }
    });
  }
  const matchDetails = (matchID) => {
    window.localStorage.setItem("MatchDetailID", matchID);
    window.localStorage.setItem("RedirectURL", window.location.href);
    dispatch(changeColor(window.location.href));
    window.location = "/match-details";
  };
  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="Container">
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={() => navigate("/home")}
                />
              </div>
            </div>
            <Row className="mx-2">
              <div className="p-2 text-center">
                <label>
                  <h4>
                    <b>Match Requests</b>
                  </h4>
                </label>
              </div>

              {allMatchesData.length === 0 ? (
                <Card className="mt-3 matchListItem p-0 text-center">
                  <Card.Body className="p-0">
                    <div className="">
                      <div className="profileformControl small">
                        <b>No records found.</b>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                allMatchesData.map((str, index) => {
                  var returData;
                  returData =
                    str.status === 1 ? (
                      <Card
                        key={index}
                        className="mt-2 matchListItem px-0"
                        data-item={str.id}
                        data-index={index}
                        style={{ backgroundColor: "#C4C4C426" }}
                      >
                        <Card.Body className="">
                          <div className="row">
                            <div className="col-12 d-flex">
                              <div className="col-9">
                                <span className="userName">
                                  <a
                                    key={index}
                                    onClick={() =>
                                      showUserDetails(str.cricketPlayerId)
                                    }
                                  >
                                    <b>
                                      {str.firstname} {str.lastname}{" "}
                                    </b>
                                  </a>
                                  <span className="matchName">
                                    {GetPlayerRole(str.playerRole)}
                                  </span>

                                  <br />
                                </span>
                                <span className="row my-1">
                                  <div className="col-3">
                                    <img src="./images/groundIcon.svg" alt="" />
                                  </div>

                                  {str.groundName}
                                </span>
                                <span className="row my-1">
                                  <div className="col-3">
                                    <img
                                      src="./images/DateandTime.svg"
                                      alt=""
                                    />
                                  </div>
                                  {DBConvertDatetime(str.matchDate)}
                                </span>
                                <span className="matchName row">
                                  <a
                                    key={index}
                                    onClick={() =>
                                      matchDetails(str.cricketMatchId)
                                    }
                                    style={{ textDecoration: "underline" }}
                                  >
                                    <b>{str.cricName}</b>
                                  </a>
                                </span>
                              </div>

                              <div
                                className="col-3 "
                                style={{ paddingLeft: "5px" }}
                              >
                                <div className="row mb-3">
                                  <div className="col-10 m-auto">
                                    <label
                                      htmlFor="upload-button"
                                      className="userProfileImageDiv"
                                    >
                                      <img
                                        key={index}
                                        src={
                                          str.profileImage === null ||
                                          str.profileImage === ""
                                            ? "/images/dummyavtar.png"
                                            : `${str.profileImage}`
                                        }
                                        className="userProfileImage"
                                        onClick={() =>
                                          showUserDetails(str.cricketPlayerId)
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  id={`RequestIcon${str.id}`}
                                >
                                  <div className="col-6 m-auto d-none loaderIcon">
                                    <img
                                      id="loaderImg"
                                      className=""
                                      height="30px"
                                      width="30px"
                                      src="/images/loder.svg"
                                    />
                                  </div>
                                  <>
                                    <div className="col-6 pr-4 actionIcon">
                                      <a
                                        key={index}
                                        onClick={() =>
                                          confirmMatch("accept", str.id)
                                        }
                                      >
                                        <img src="/images/Match Status/Approve.svg" />
                                      </a>
                                    </div>
                                    <div className="col-6 actionIcon">
                                      <a
                                        key={index}
                                        onClick={() =>
                                          confirmMatch("delete", str.id)
                                        }
                                      >
                                        <img src="/images//Match Status/Reject.svg" />
                                      </a>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : null;
                  return returData;
                })
              )}
              {userPopUp ? (
                <UserDetails
                  show={userPopUp}
                  UserDetails={userDetails}
                  BattingStyle={battingStyle}
                  BowlingStyle={bowlingStyle}
                  PlayerStatistics={PlayerStats}
                  onHide={closePopupHandler}
                  onClick={closePopupHandler}
                />
              ) : null}
            </Row>
          </div>
        )}
      </div>
    </>
  );
}
