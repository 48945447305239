import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Navigate } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CustomPostAPI } from "../Utils/APIConnector";
import VenueOwnerDetailsPopup from "../Popup/VenueOwnerDetailsPopup";
import VenueOwnerOfflineBooking from "../Popup/VenueOwnerOfflineBooking";
import { formatDateToInput } from "../Utils/common";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

export default function VenueCalender({
  VenueId,
  selectedDate,
  setSelectedDate,
  activeCourtID,
}) {
  const formatEvents = (events) => {
    return events.map((event) => ({
      start: new Date(event.start), // Ensure this is a Date object
      end: new Date(event.end), // Ensure this is a Date object
      title: event.title,
      username: event.username,
      userPhone: event.userPhone,
      // Add any other properties you want to keep
    }));
  };
  const [Events, setEvents] = useState([]);
  const [activeButton, setActiveButton] = useState();
  const [surfaces, setSurfaces] = useState();
  const [show, setShow] = useState({ popup: false, data: "", title: "" });
  const [showoffline, setShowoffline] = useState({
    popup: false,
    data: "",
    title: "",
    date: "",
  });
  const windowWidth = window.innerWidth;
  const [CalenderSelectedDate, setCalenderSelectedDate] =
    useState(selectedDate);
  useEffect(() => {
    setCalenderSelectedDate(selectedDate);
  }, [selectedDate]);
  useEffect(() => {
    const raw = JSON.stringify({
      VenueId: VenueId,
      venuecourtid: activeCourtID ? activeCourtID : null,
    });
    CustomPostAPI("/Venue/GetBookedvenueCalendar", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        const transformedEvents = result.bookingDetails.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }));
        setEvents(transformedEvents);
        setSurfaces(result?.surfaceDetails);
        setActiveButton(result?.surfaceDetails[0]?.name);
      }
    });
  }, [activeCourtID]);
  const getdata = (id) => {
    const raw = JSON.stringify({
      VenueId: VenueId,
      venuecourtid: id ? id : null,
    });
    CustomPostAPI("/Venue/GetBookedvenueCalendar", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        const transformedEvents = result.bookingDetails.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }));
        setEvents(transformedEvents);
        setSurfaces(result?.surfaceDetails);
        setActiveButton(
          result?.surfaceDetails?.find((detail) => detail.id === id).name
        );
      }
    });
  };
  return (
    <React.Fragment>
      {/* {windowWidth < 787 && (
        <>
          <div className="row my-2 p-2 px-5">
            <input
              type="date"
              name="CalenderSelectedDate"
              value={formatDateToInput(CalenderSelectedDate)}
              id=""
              className="form-control"
              onChange={(e) => setCalenderSelectedDate(e.target.value)}
            />
          </div>
          <hr className="m-0" />
        </>
      )} */}

      {/* <div className="row mt-2 p-2 px-3">
        <div className="rbc-toolbar justify-content-start">
          <span class="rbc-btn-group">
            {surfaces?.map((surface) => (
              <button
                className={activeButton === surface?.name ? "rbc-active" : ""}
                type="button"
                onClick={() => {
                  setActiveButton(surface?.name);
                  getdata(surface?.id);
                }}
              >
                {surface?.name}
              </button>
            ))}
          </span>
        </div>
        <div className="row"></div>
      </div> */}
      <div className="myCustomHeight p-3">
        <Calendar
          date={CalenderSelectedDate}
          onNavigate={(date) => {
            // setCalenderSelectedDate(date);
            setSelectedDate(date);
          }}
          // views={["month", "day", "agenda", "work_week"]}
          views={["day", "month"]}
          selectable
          startAccessor="start"
          endAccessor="end"
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={"day"}
          events={Events}
          style={{ height: "60vh" }}
          popup={true}
          showMultiDayTimes={true}
          onSelectEvent={(event) =>
            setShow({ popup: true, data: event, title: "Booking Details" })
          }

          // onSelectSlot={(slot) => {
          //   setShowoffline({
          //     date: slot?.start,
          //     popup: true,
          //     data: slot,
          //     title: "Book Your Venue Offline",
          //   });
          // }}
        />
      </div>
      <VenueOwnerDetailsPopup show={show} setShow={setShow} />
      <VenueOwnerOfflineBooking
        showoffline={showoffline}
        setShowoffline={setShowoffline}
        VenueId={VenueId}
      />
    </React.Fragment>
  );
}
