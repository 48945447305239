import React from "react";
import { useState } from "react";
import "../css/common.css";
import $ from "jquery";
import Loader from "./Utils/Loader";
import { isValid } from "./constants";
import { CustomPostAPI } from "./Utils/APIConnector";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";

export function Loginpage() {
  const [phone, setPhone] = useState("");
  const [pwd, setPWD] = useState("");
  const [blankPhone, setBlankPhone] = useState("");
  const [blankPWD, setBlankPWD] = useState("");
  const [showLoader, SetShowLoader] = useState(false);
  const [cookies, setCookie] = useCookies(["User"]);
  const [IsPartner, setIsPartner] = useState(localStorage.getItem("IsPartner"));
  const RemoveValidation = () => {
    if (phone !== "undefined") {
      setBlankPhone("");
    }
    if (pwd != "") {
      setBlankPWD("");
    }
  };
  const redirectURL = window.localStorage.getItem("RedirectURL");

  const loginUser = async () => {
    SetShowLoader(true);
    $(".signupagainClass").addClass("d-none");
    var temp = 0;
    if (phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (phone === "") {
        setBlankPhone("PhoneNumber is required!!");
        SetShowLoader(false);
      } else if (!pattern.test(phone)) {
        setBlankPhone("Please enter only number.");
        SetShowLoader(false);
      } else if (phone.length != 10) {
        setBlankPhone("Please enter valid phone number.");
        SetShowLoader(false);
      } else {
        setBlankPhone("");
        temp++;
      }
    }

    if (pwd === "") {
      setBlankPWD("Password is required");
      SetShowLoader(false);
    } else {
      setBlankPWD("");
      temp++;
    }

    if (temp >= 2) {
      var raw = JSON.stringify({
        PhoneNumber: phone,
        Password: pwd,
      });

      await CustomPostAPI("/Auth/Login", raw).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
          SetShowLoader(false);
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            SetShowLoader(false);

            window.localStorage.setItem(
              "phoneno",
              result.userDetails.phoneNumber
            );
            setCookie("AuthToken", result.token, { path: "/" });
            window.localStorage.setItem("userid", result.userDetails.id);
            var userdetailsdata = result.userDetails;
            if (userdetailsdata.statusId === 2) {
              SetShowLoader(false);

              if (userdetailsdata.roleType === 1) {
                window.localStorage.setItem("UserType", "User");
              } else if (userdetailsdata.roleType === 2) {
                window.localStorage.setItem("UserType", "Admin");
              } else if (userdetailsdata.roleType === 3) {
                window.localStorage.setItem("UserType", "Venueowner");
              } else if (userdetailsdata.roleType === 4) {
                window.localStorage.setItem("UserType", "Hybrid");
              } else if (userdetailsdata.roleType === 5) {
                window.localStorage.setItem("UserType", "Staff");
              }

              window.localStorage.setItem(
                "UserName",
                result.userDetails.firstName + " " + result.userDetails.lastName
              );
              if (isValid(result.userDetails.profileImage)) {
                window.localStorage.setItem(
                  "UserProfileImage",
                  result.userDetails.profileImage
                );
              }
              window.localStorage.setItem(
                "UserMobileNo",
                result.userDetails.phoneNumber
              );
              window.localStorage.setItem(
                "UserProfileStatus",
                result.progressbarValue
              );
              localStorage.setItem("loggedin", "true");
              if (
                redirectURL !== null &&
                redirectURL !== "" &&
                redirectURL !== undefined
              ) {
                window.location = redirectURL;
              } else {
                if (userdetailsdata.roleType === 1) {
                  window.location = "/venue-booking-details";
                } else if (userdetailsdata.roleType === 2) {
                  window.location = "/home-venue-owner";
                } else if (userdetailsdata.roleType === 3) {
                  window.location = "/home-venue-owner";
                } else if (userdetailsdata.roleType === 4) {
                  window.location = "/home-venue-owner";
                } else if (userdetailsdata.roleType === 5) {
                  window.location = "/home-venue-owner";
                }
              }
            } else {
              SetShowLoader(false);
              $(".signupagainClass").removeClass("d-none");
            }
          } else {
            // alert(result.message);
            $(".signupagainClass").removeClass("d-none");
            SetShowLoader(false);
          }
        }
      });
    }
  };

  const showPassword = () => {
    if ($(".openEye").is(":visible")) {
      $("#showHidePwd").attr("type", "text");
      $(".openEye").addClass("d-none");
      $(".closeEye").removeClass("d-none");
    } else {
      $("#showHidePwd").attr("type", "password");
      $(".closeEye").addClass("d-none");
      $(".openEye").removeClass("d-none");
    }
  };

  const verifyOTP = async () => {
    var OTP = $("#UserOTP").val();

    if (OTP.length === 0) {
      $("#UserOTP").parent().find("span").text("Please enter valid OTP");
      return;
    } else if (OTP.length < 6) {
      $("#UserOTP").parent().find("span").text("Please enter 6 digit OTP");
      return;
    } else if (OTP.length > 6) {
      $("#UserOTP").parent().find("span").text("Please enter 6 digit OTP");
      return;
    } else {
      $("#UserOTP").parent().find("span").text(" ");
    }

    var mobileNo = $("#UserMobileNo").val();
    $("#OTPmsgdiv").addClass("d-none");
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
      VerifyCode: OTP,
    });

    await CustomPostAPI("/Auth/VerifyWhatsappCode", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
        SetShowLoader(false);
      } else {
        result = JSON.parse(result);
        if (result.status === "Success") {
          const cookies = new Cookies();
          // cookies.set("IsPersist", "yes", {
          //   maxAge: 1440000,
          // });
          cookies.set("userid", result.userDetails.id, {
            maxAge: 1440000,
          });
          $("#errorTxt").text("");
          SetShowLoader(false);
          $("#errorTxt").text("");
          window.localStorage.setItem(
            "phoneno",
            result.userDetails.phoneNumber
          );
          setCookie("AuthToken", result.token, { path: "/" });
          window.localStorage.setItem("userid", result.userDetails.id);
          var userdetailsdata = result.userDetails;
          if (userdetailsdata.statusId === 2) {
            SetShowLoader(false);

            if (userdetailsdata.roleType === 1) {
              window.localStorage.setItem("UserType", "User");
            } else if (userdetailsdata.roleType === 2) {
              window.localStorage.setItem("UserType", "Admin");
            } else if (userdetailsdata.roleType === 3) {
              window.localStorage.setItem("UserType", "Venueowner");
            } else if (userdetailsdata.roleType === 4) {
              window.localStorage.setItem("UserType", "Hybrid");
            } else if (userdetailsdata.roleType === 5) {
              window.localStorage.setItem("UserType", "Staff");
            }
            window.localStorage.setItem(
              "UserName",
              result.userDetails.firstName
                ? result.userDetails.firstName
                : "" + " " + result.userDetails.lastName
                ? result.userDetails.lastName
                : ""
            );
            if (isValid(result.userDetails.profileImage)) {
              window.localStorage.setItem(
                "UserProfileImage",
                result.userDetails.profileImage
              );
            }
            window.localStorage.setItem(
              "UserMobileNo",
              result.userDetails.phoneNumber
            );
            window.localStorage.setItem(
              "UserProfileStatus",
              result.progressbarValue
            );
            window.localStorage.setItem("UserEmail", result.userDetails.email);
            localStorage.setItem("loggedin", "true");
            if (
              redirectURL !== null &&
              redirectURL !== "" &&
              redirectURL !== undefined
            ) {
              window.location = redirectURL;
            } else {
              if (result.userDetails.roleType === 1) {
                window.location = "/venue-booking-details";
              } else if (result.userDetails.roleType === 2) {
                window.location = "/home-venue-owner";
              } else if (result.userDetails.roleType === 3) {
                window.location = "/home-venue-owner";
              } else if (result.userDetails.roleType === 4) {
                window.location = "/home-venue-owner";
              } else if (result.userDetails.roleType === 5) {
                window.location = "/home-venue-owner";
              }
            }
          }
        } else if (result.status === "Invalid") {
          $("#errorTxt").text("Please enter valid OTP");
        } else if (result.status === "expired") {
          $("#errorTxt").text("OTP expired");
        }
      }
    });
  };

  const timerOTP = () => {
    var timerDiv = $("#countdowntimer");
    var resendLink = $("#ResendOTP").find("a");
    var timeleft = 59;
    var resendtimer = $("#resendtimer").removeClass("d-none");

    var downloadTimer = setInterval(function () {
      timeleft--;
      timeleft = (timeleft < 10 ? "0" : "") + timeleft.toString();
      timerDiv.html(timeleft);
      if (timeleft <= 0) {
        $(resendLink).removeClass("d-none");
        var resendtimer = $("#resendtimer").addClass("d-none");
        clearInterval(downloadTimer);
      }
    }, 1000);
  };

  const resendOTP = () => {
    timerOTP();
    var resendLink = $("#ResendOTP").find("a").addClass("d-none");
    $("#errorTxt").text("");
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      SetShowLoader(false);
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      SetShowLoader(false);
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_API_BASE_URL + "/Auth/Newlogin", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === "success") {
          $("#ResendOTP").removeClass("d-none");
          SetShowLoader(false);
        } else {
          alert(result.message);
          SetShowLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const [mobileNo, setmobileNo] = useState();
  const SendOTP = async () => {
    SetShowLoader(true);
    var mobileNo = $("#UserMobileNo").val();
    var pattern = new RegExp(/^[0-9\b]+$/);
    $("#UserMobileNo").parent().find("span").text("");
    $("#UserMobileNo").val(mobileNo);
    if (!pattern.test(mobileNo)) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter valid number");
      SetShowLoader(false);
      return;
    } else if (mobileNo.length < 10) {
      $("#UserMobileNo")
        .parent()
        .find("span")
        .text("Please enter 10 digit mobile number");
      SetShowLoader(false);
      return;
    }
    var raw = JSON.stringify({
      PhoneNumber: mobileNo,
    });
    // $("#OTPInputDiv").removeClass("d-none");
    // $("#sendOTPBtn").addClass("d-none");
    // $("#OTPmsgdiv").removeClass("d-none");
    // $("#verifyOTPBtn").removeClass("d-none");
    // $("#ResendOTP").removeClass("d-none");

    // SetShowLoader(false);
    await CustomPostAPI("/Auth/Newlogin", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
        SetShowLoader(false);
      } else {
        result = JSON.parse(result);
        if (result.status === "success") {
          // console.log(result)
          $("#OTPInputDiv").removeClass("d-none");
          $("#sendOTPBtn").addClass("d-none");
          $("#verifyOTPBtn").removeClass("d-none");
          $("#ResendOTP").removeClass("d-none");
          $("#OTPmsgdiv").removeClass("d-none");
          timerOTP();
          SetShowLoader(false);
          $(".signupagainClass").addClass("d-none");
        } else if (result.status === "UserNotFound") {
          SetShowLoader(false);
          $(".signupagainClass").removeClass("d-none");
        }
      }
    });
  };
  const handlelength = (e) => {
    if (e.target.value.length > 10) {
      return;
    }
    setmobileNo(e.target.value);
  };
  const showpwddiv = () => {
    $(".openEye").removeClass("d-none");
  };
  return (
    <React.Fragment>
      <div className="container">
        <div className="row mt-4 px-2">
          <div className="col-12">
            <a href={IsPartner === "true" ? "/partner" : "/welcome"}>
              <img src="/images/backArrow.svg" />
            </a>
          </div>
        </div>
        <div className="row" style={{ marginTop: "0%" }}>
          <div className="col-12 ">
            <img src="/images/avtar.svg" className="avtarImg" />
          </div>

          <div className="col-12 text-center mt-2">
            <span className="formHeading">Login</span>
          </div>
          <div className="col-12 pt-3" id="LoginText">
            <p className="paraTxt text-center">Welcome Back!</p>
            {/* <p className="paraTxt">Enter Your Mobile Number</p> */}
          </div>
          {/* <div id="OTPmsgdiv" className="d-none text-center">
            <small className="text-center">
              OTP will be send in your whatsapp message
            </small>
          </div> */}
        </div>
        <div className="row p-2">
          <div className="col-12  pt-1">
            <div className="posRel">
              <img src="/images/contact.svg" className="inputIcons1" />

              <input
                id="UserMobileNo"
                type="number"
                inputMode="numeric"
                className="form-control disableClass formControl"
                placeholder="Mobile Number"
                autoComplete="new-password"
                value={mobileNo}
                // onChange={validateMobileNo}
                maxLength={10}
                onChange={(e) => handlelength(e)}
              />

              <span className="ErrorText" style={{ color: "#A51E22" }}></span>
            </div>
          </div>
          <div className="col-12  pt-1">
            <div className="posRel d-none" id="OTPInputDiv">
              <img src="/images/OTP1.svg" className="inputIcons1 topotp" />
              <input
                id="UserOTP"
                type="number"
                inputMode="numeric"
                className="form-control formControl"
                placeholder="OTP"
                // onChange={validateOTP}
              />
              <span className="ErrorText" style={{ color: "#A51E22" }}></span>
            </div>
          </div>
          <div id="OTPmsgdiv" className="d-none mt-1">
            <small>You will receive the OTP on your WhatsApp number.</small>
          </div>
          <div className="col-12 text-center my-1">
            <div className="justify-content-center">
              <button
                type="submit"
                id="sendOTPBtn"
                className="mt-2 col-6 w-auto loginbtn"
                onClick={SendOTP}
              >
                Send Verification Code
              </button>
              <button
                type="submit"
                id="verifyOTPBtn"
                className="mt-2 col-6 loginbtn d-none"
                onClick={verifyOTP}
              >
                Verify OTP
              </button>

              {showLoader ? (
                <img
                  id="loaderImg"
                  className="col-1 hidden"
                  height="30px"
                  width="30px"
                  src="/images/loder.svg"
                />
              ) : null}
            </div>
            <div className="col-12 text-center my-1 resendOTPClass">
              <span
                className="errorTxt"
                id="errorTxt"
                style={{ color: "#A51E22", fontSize: "14px" }}
              ></span>
            </div>
            <div id="ResendOTP" className="d-none px-2">
              <div className="pt-2">
                <h6 id="resendtimer">
                  Resend OTP in 00:<span id="countdowntimer">59</span>
                </h6>
              </div>
              <a
                style={{ color: "#0d6efd", textDecoration: "underline" }}
                className="d-none"
                onClick={resendOTP}
              >
                Resend OTP
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-5 signupagainClass d-none">
            <span className="errorTxt">
              User not found. Please{" "}
              <a
                style={{ color: "#0d6efd", textDecoration: "underline" }}
                href="/registration"
              >
                sign up here.
              </a>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4 d-flex justify-content-center  ">
            <img src="./images/Cricket_Line _Art.svg" className="img-fluid " />
          </div>
        </div>
      </div>
    </React.Fragment>
    // <>
    //   <div className="container ">
    //     <div className="row mt-5 px-2">
    //       <div className="col-12">
    //         <a href={IsPartner === "true" ? "/partner" : "/welcome"}>
    //           <img src="/images/backArrow.svg" />
    //         </a>
    //       </div>
    //     </div>
    //     <div className="px-2" style={{ marginTop: "15%" }}>
    //       {IsPartner === "true" && (
    //         <div className="col-12 mt-4 pt-4 text-center">
    //           <span className="cricketAppTxtReg">
    //             CricBuddy partner program
    //           </span>
    //         </div>
    //       )}

    //       <div className="col-12 pt-3">
    //         <span className="formHeading">Login</span>
    //       </div>
    //       <div className="col-12 pt-3" id="LoginText">
    //         <p className="paraTxt">
    //           Amet minim mollit non deserunt ullamcoei sitaliqua dolor do amet
    //           sintelit officia.
    //         </p>
    //         <p
    //           className="d-none paraTxt loginTimeoutText text-center"
    //           style={{ color: "#a51e22", fontSize: "14px" }}
    //         >
    //           Your session has expired. Please login to continue.
    //         </p>
    //       </div>
    //       <div className="row  px-2 ">
    //         <div className="col-12">
    //           <div className="posRel">
    //             <img src="/images/contact.svg" className="inputIcons1" />
    //             <input
    //               type="text"
    //               className="form-control formControl"
    //               value={phone}
    //               onMouseLeave={RemoveValidation}
    //               onChange={(e) => setPhone(e.target.value)}
    //               placeholder="Mobile Number"
    //               autoComplete="new-password"
    //             />
    //             <span style={{ color: "red" }}>{blankPhone}</span>
    //           </div>
    //         </div>
    //         <div className="col-12  pt-4">
    //           <div className="posRel">
    //             <img src="/images/pwd.svg" className="inputIcons1" />
    //             <input
    //               type="password"
    //               id="showHidePwd"
    //               className="form-control formControl"
    //               value={pwd}
    //               onFocus={showpwddiv}
    //               onMouseLeave={RemoveValidation}
    //               onChange={(e) => setPWD(e.target.value)}
    //               placeholder="Password"
    //               autoComplete="new-password"
    //             />
    //             <span style={{ color: "red" }}>{blankPWD}</span>
    //             <img
    //               src="/images/open_eye.svg"
    //               className="eyeIcon openEye d-none"
    //               onClick={showPassword}
    //             />
    //             <img
    //               src="/images/close_eye.svg"
    //               className="eyeIcon closeEye d-none"
    //               onClick={showPassword}
    //             />
    //           </div>
    //         </div>
    //         <div className="col-12 text-center mt-4 signupagainClass d-none">
    //           <span className="errorTxt">
    //             User not found. Please{" "}
    //             <a
    //               style={{ color: "#0d6efd", textDecoration: "underline" }}
    //               href="/registration"
    //             >
    //               sign up here.
    //             </a>
    //           </span>
    //         </div>
    //         <div className="col-12 text-center pt-4">
    //           <button
    //             type="submit"
    //             className="mt-2 col-6 loginbtn"
    //             onClick={loginUser}
    //           >
    //             LOGIN
    //           </button>
    //           {showLoader ? (
    //             <img
    //               id="loaderImg"
    //               className="col-1"
    //               height="30px"
    //               width="30px"
    //               src="/images/loder.svg"
    //             />
    //           ) : (
    //             ""
    //           )}
    //         </div>
    //         <div className="col-12 py-5 text-center">
    //           <span className="forgotTxt">FORGOT PASSWORD ? </span>
    //           <a href="/forgotpwd" className="errorTxt">
    //             {" "}
    //             Reset here
    //           </a>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-12 centerAlign">
    //           <img src="/images/Cricket_Line _Art.svg" className="img-fluid " />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}
