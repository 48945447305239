import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`${this.props.show ? "" : "d-none"}`}>
        <Modal
          show={this.props.show}
          onHide={() => this.props.onHide({ msg: "Cross Icon Clicked!" })}
          className={this.props.class}
          size={this.props.venueflag === 1 ? "md" : "xl"}
        >
          {this.props.teamname && (
            <Modal.Header
              // closeButton
              className="centerAlign"
              style={{
                background: "rgba(196, 196, 196, 0.15)",
                color: "#A51E22",
              }}
            >
              <Modal.Title className="">{this.props.teamname}</Modal.Title>
            </Modal.Header>
          )}

          <Modal.Body>
            {!this.props.teamname && (
              <div className="text-end">
                <b
                  className="cursor-pointer"
                  onClick={() =>
                    this.props.onHide({ msg: "Cross Icon Clicked!" })
                  }
                  style={{ fontSize: "20px" }}
                >
                  x
                </b>
              </div>
            )}
            {this.props.children}
          </Modal.Body>
          {/* <Modal.Footer style={{ fontSize: "13px" }}></Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

export default Popup;
