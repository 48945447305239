import "../css/common.css";
export function Welcomepage() {
  const loginredirect = () => {
    window.location = "/login";
  };

  const newRegistration = () => {
    window.location = "/registration";
  };

  const url = localStorage.getItem("RedirectURL");
  let text = "continue";
  if (url && url.includes("/play-cricket")) {
    text = "set up your match";
  } else {
    text = "continue";
  }
  return (
    <>
      <div className="container">
        <div className="row mt-4 px-2">
          <div className="col-12">
            <img
              className="cursorClass"
              src="/images/backArrow.svg"
              alt=""
              onClick={() => (window.location = "/home")}
            />
          </div>
        </div>
        <div className="wlcmMainDiv row px-0">
          <div className="container-fluid   pt-md-3 px-0  p-2 position-relative">
            <div className="col-12   text-center">
              <span className="welcomeOrangeTxt">Welcome to</span>
              <br />
              <span className="cricketAppTxt">CricBuddy</span>
            </div>
            <div className="col-12 px-4 mt-4">
              <p className="paraTxt text-center">
                Login or Create a New Account to {text}.
              </p>
            </div>
            <div className="col-12 text-center">
              <button className="mt-3 col-6 loginbtn" onClick={loginredirect}>
                LOGIN
              </button>
            </div>
            <div className="col-12 text-center ">
              <button
                className="mt-4 col-6 Registerbtn"
                onClick={newRegistration}
              >
                CREATE ACCOUNT
              </button>
            </div>
            <div className="col-12 mt-4 d-flex justify-content-center ">
              <img
                src="/images/Newloginfooter.png"
                className="img-fluid "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
