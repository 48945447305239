import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { venueSportsData } from "../Utils/common";
import SweetAlert from "react-bootstrap-sweetalert";
import { CustomPostAPI, DeleteAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";
import { useNavigate } from "react-router-dom";

export default function VenueList({
  venuesData,
  isLoading,
  setIsLoading,
  setVenuesData,
  setIsHomePage,
  setSelectedVenue,
}) {
  const [deleteAlerts, setDeleteAlerts] = useState({});
  const [switchAlerts, setSwitchAlerts] = useState({});
  const UserType = localStorage.getItem("UserType");
  const navigate = useNavigate();
  const handleSwitchStatus = (event, id, IsAcrive, index) => {
    if (event.target.checked === true) {
      handleSwitch(id, IsAcrive, index);
    } else {
      toggleSwitchAlert(id);
    }
  };

  const handleSwitch = (id, IsAcrive, index) => {
    let arrayData = [...venuesData];
    arrayData[index].isAcrive = !IsAcrive;
    setVenuesData({ ...venuesData, arrayData });
    var raw = JSON.stringify({
      venueid: id,
      IsAcrive: !IsAcrive,
    });

    CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        window.location.reload();
      }
    });
  };

  const toggleDeleteAlert = (venueId) => {
    setDeleteAlerts((prevState) => ({
      ...prevState,
      [venueId]: !prevState[venueId],
    }));
  };

  const toggleSwitchAlert = (venueId) => {
    setSwitchAlerts((prevState) => ({
      ...prevState,
      [venueId]: !prevState[venueId],
    }));
  };

  const deleteVenue = async (id) => {
    try {
      const result = await DeleteAPI(`/Venue/DeleteVenue/${id}`);
      if (!result || result === "") {
        alert("Something went wrong");
      } else {
        window.location.reload();
      }
    } catch (error) {
      alert("An error occurred while deleting the venue");
    }
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          {venuesData?.map((venue, index) => (
            <div className="col-md-6 my-2">
              {" "}
              <Card className="mt-3 h-100 venueListCard">
                <Card.Body
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-9">
                      <p className="venueNameHeader m-0">{venue.name}</p>
                    </div>
                    <div className="col-3 text-end">
                      {UserType != "Staff" && (
                        <div className="row">
                          <div className="col-6 d-flex align-items-center">
                            {venue?.isAcrive != 2 && venue?.isAcrive != 3 && (
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  role="switch"
                                  id={`flexSwitchCheckChecked${index}`}
                                  checked={venue?.isAcrive}
                                  onClick={(event) => {
                                    handleSwitchStatus(
                                      event,
                                      venue.id,
                                      venue.isAcrive,
                                      index
                                    );
                                  }}
                                />
                                <SweetAlert
                                  key={venue.id}
                                  show={switchAlerts[venue.id] || false}
                                  danger
                                  showCancel
                                  confirmBtnText="Yes, disable it!"
                                  confirmBtnBsStyle="danger"
                                  title="Are you sure?"
                                  onConfirm={() => {
                                    handleSwitch(
                                      venue.id,
                                      venue.isAcrive,
                                      index
                                    );
                                    toggleSwitchAlert(venue.id);
                                  }}
                                  onCancel={() => toggleSwitchAlert(venue.id)}
                                  focusCancelBtn
                                >
                                  Your venue will be disabled and not shown to
                                  the user!
                                </SweetAlert>
                              </div>
                            )}
                          </div>
                          <div className="col-6">
                            {" "}
                            <img
                              src="/images/LatestDeleteIcon.svg"
                              alt=""
                              className="cursor-pointer"
                              style={{ width: "20px" }}
                              onClick={() => toggleDeleteAlert(venue.id)}
                            />
                            <SweetAlert
                              key={venue.id}
                              show={deleteAlerts[venue.id] || false}
                              danger
                              showCancel
                              confirmBtnText="Yes, delete it!"
                              confirmBtnBsStyle="danger"
                              title="Are you sure?"
                              onConfirm={() => deleteVenue(venue?.id)}
                              onCancel={() => toggleDeleteAlert(venue.id)}
                              focusCancelBtn
                            >
                              You will not be able to recover this venue!
                            </SweetAlert>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      {venue?.isAcrive === 2 && (
                        <span className="venueText text-warning">
                          Your verification is pending with the admin.
                        </span>
                      )}
                      {venue?.isAcrive === 3 && (
                        <span className="venueText text-danger">
                          Your request has been rejected by the admin.
                        </span>
                      )}
                    </div>
                    <div className="col-12">
                      <img
                        className="img-fluid me-1"
                        src="/images/LocationLatestIcon.svg"
                        alt=""
                      />
                      <span className="venueListAddress">{venue.address}</span>
                    </div>
                    <div className="col-12">
                      <span className="venueListAddress fw-400">
                        {" "}
                        <b>
                          You have total {venue?.venuebookings} booking today
                        </b>
                      </span>
                    </div>
                    <div className="col-8 mt-2">
                      <div className="text-start">
                        {venue?.venueSports?.map((data, i) => (
                          <>
                            {i < 3 && (
                              <div
                                className="sportimage me-2 mt-1"
                                style={{
                                  borderRadius: "3px",
                                  background: "white",
                                }}
                              >
                                <img
                                  src={`/images/${venueSportsData(data?.name)}`}
                                  className="img-fluid"
                                  alt="image"
                                  title={data?.name}
                                  style={{
                                    borderRadius: "11px",
                                    padding: "3px 3px",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>
                            )}
                            {i === 3 && venue?.venueSports?.length > 3 && (
                              <div
                                className="mx-lg-1 mx-1 Montserrat"
                                style={{ fontSize: "14px" }}
                              >
                                + {venue?.venueSports?.length - 3} more
                              </div>
                            )}
                          </>
                        ))}

                        {venue?.venueSports?.length === 0 && (
                          <span className="venueText">No sports available</span>
                        )}
                      </div>
                    </div>
                    <di className="col-4 text-end mt-3 text-danger ">
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          setIsLoading(true);
                          setSelectedVenue(venue?.id);
                          setIsHomePage(false);
                        }}
                      >
                        <span>Details</span>{" "}
                        <img
                          src="/images/NextClickIcon.svg"
                          className="cursor-pointer"
                          alt=""
                        />
                      </div>
                    </di>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
          {UserType != "Staff" && (
            <div className="d-flex mt-4 justify-content-center">
              <button
                className="NewAddVenueBtn"
                onClick={(event) => {
                  navigate("/venue-information");
                }}
              >
                <img src="/images/TransparentPlus.svg" alt="" /> Add Venue
              </button>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}
