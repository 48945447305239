import { React, useState, useEffect, useReducer, useLayoutEffect } from "react";
import { INITIAL_STATE, formReducer } from "./Reducers/VenueInfoReducer";
import { CustomGetAPI } from "./Utils/APIConnector";
import { useLocation, useNavigate } from "react-router-dom";
import { AddSurface } from "./VenueInformation/AddSurface";
import VenueInfo from "./VenueInformation/VenueInfo";
import Amenities from "./VenueInformation/Amenities";
import StepProgressBar from "./VenueInformation/StepProgressBar";
import { minToHour } from "./Utils/common";

export default function VenueInformation() {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const navigate = useNavigate();
  const loaction = useLocation();
  const [currentStep, setcurrentStep] = useState("1");
  const [location, setLocation] = useState({ cityName: [], stateName: [] });
  const [surfaces, setsurfaces] = useState();
  const [sportstype, setsportstype] = useState([]);
  const [Amenitieslist, setAmenitieslist] = useState();
  const [isFirstTime, setIsFirstTime] = useState("0");
  const [isEditFirst, setEditFirst] = useState("0");
  const [isSecondTime, setIsSecondTime] = useState("0");
  const [checkedAmenities, setCheckedAmenities] = useState([]);
  const [venueOffers, setVenueOffers] = useState();
  const [venueOffersImage, setVenueOffersImage] = useState();
  const [weekdaytype, setweekdaytype] = useState("No");

  const [time, setTime] = useState({
    isCustomHours: false,
  });
  const [multipleImages, setMultipleImages] = useState([]);
  const venueid = loaction?.state?.id ? loaction?.state?.id : 0;
  const [slotDuration, setSlotDuration] = useState(0.5);
  var listData = [
    {
      venuesportId: null,
      SurfaceType: state?.category === "sports" ? "" : 10,
      Courtname: "",
      CourtCount: "1",
      MaxCapacity: "1",
      NoOfCarts: "1",
      // minSlotTime: "0.5",
      Sports: [],
      weekDay: [
        {
          weekDays: "",
          data: [
            {
              startTime: "00:00",
              endTime: "00:00",
              amount: "0",
              duration1: "",
              duration2: "",
              duration3: "",
              id: 0,
            },
          ],
        },
      ],
      VenueId: 0,
    },
  ];
  const [formValuesSportsFacilities, setFormValues] = useState(listData);
  useEffect(() => {
    setFormValues(listData);
  }, [state?.category]);
  useLayoutEffect(() => {
    if (venueid != 0) {
      CustomGetAPI("/Venue/EditVenueDetails/" + venueid).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          state.VenueName = result.name;
          state.category = result.category.toLowerCase();
          state.venueimage = result.venueImages;
          state.DescText = result.description;
          state.StartTime = result.startTime;
          state.EndTime = result.endTime;
          state.MinSlotTime = result.minSlotTime.toString();
          setSlotDuration(minToHour(result.minSlotTime));
          state.Cancelpolicy = result.cancellationTime
            ? result.cancellationTime
            : 0;
          state.RescheduleHours = result.rescheduleHours
            ? result.rescheduleHours
            : 0;
          state.RescheduleCount = result.rescheduleCount
            ? result.rescheduleCount
            : 0;
          state.Email = result.email;
          state.Phone = result.phone;
          state.Address1 = result.address.addressline1;
          state.Address2 = result.address.addressline2;
          state.Address3 = result.address.addressline3;
          state.stateid = result.address.stateId.toString();
          state.cityid = result.address.cityId.toString();
          dispatch({
            type: "CHANGE_INPUT",
            payload: {
              name: "ZipCode",
              value: result.address.zipcode,
            },
          });
          const transformedArray = result?.sportsFacility?.map((facility) => ({
            venuesportId: facility?.venuesportId,
            SurfaceType: facility.surfaceType,
            Courtname: facility.courtname,
            CourtCount: facility.courtCount,
            MaxCapacity: facility.maxCapacity,
            Sports: facility.sports,
            MonFri: facility.monFri,
            // minSlotTime: parseInt(minToHour(facility.minSlotTime)),
            weekDay: facility?.weekDay.map((day) => ({
              weekDays: day.weekDays,
              data: day.data,
            })),
            SatSun: facility.satSun,
            VenueId: result.id,
            rehearsalprice: facility?.rehearsalprice || "",
            extrahourprice: facility?.extrahourprice || "",
            NoOfCarts: facility?.noOfCart === 0 ? 1 : facility?.noOfCart,
          }));
          setFormValues(transformedArray);

          setweekdaytype(result?.weekendRate ? "Yes" : "No");
          setCheckedAmenities(
            result?.venueAmenities
              ?.map((selectedItem) => parseInt(selectedItem.id))
              .filter((id) => !isNaN(id))
          );

          setTime({ isCustomHours: result?.customtime });

          state.venueimage = multipleImages.concat(result.venueImages);
          setMultipleImages(multipleImages.concat(result.venueImages));
          setVenueOffersImage(result.venueOffers[0]?.image);
          setVenueOffers(result.venueOffers[0]?.description);

          setIsFirstTime("1");
          setIsSecondTime("1");
          setEditFirst("1");
        }
      });
    } else if (venueid === 0) {
      dispatch({ type: "RESET_STATE" });
    }
  }, [venueid]);

  useEffect(() => {
    CustomGetAPI(`/Venue/Getvenueinfo?category=${state?.category}`).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          setLocation({
            ...location,
            stateName: result?.citystate,
          });
          setsurfaces(result?.surfaces);
          setsportstype(result?.sports);
          setAmenitieslist(
            result?.amenities?.map((amenity) => ({
              ...amenity,
              checked: false,
            }))
          );
        }
      }
    );
  }, [state?.category]);

  async function convertImagePathsToFiles(imagePaths) {
    const files = await Promise.all(
      imagePaths.map(async (imagePathObj) => {
        // Extract the image path from the object
        const imagePath = imagePathObj.image;

        // Fetch the image data using fetch or any other appropriate method
        const response = await fetch(imagePath);
        const blob = await response.blob();

        // Extract filename from the imagePath
        const filename = imagePath.split("/").pop();

        // Create a File object from the blob with the extracted filename
        return new File([blob], filename);
      })
    );

    return files;
  }

  return (
    <>
      <div className="row px-2">
        <div className="col-12">
          {currentStep === "1" && (
            <img
              className="cursorClass"
              src="/images/backArrow.svg"
              onClick={() => navigate(-1)}
            />
          )}
        </div>
      </div>

      <div className="Container mb-3">
        <div className="p-2 text-center">
          <StepProgressBar currentStep={currentStep} totalSteps={3} />
          <label>
            <h4 className="mt-3">
              <b>VENUE DETAILS</b>
            </h4>
          </label>
        </div>

        <div className="venueDetails">
          {currentStep == 1 ? (
            <VenueInfo
              state={state}
              stateid={venueid}
              setTime={setTime}
              time={time}
              dispatch={dispatch}
              // CityFilter={CityFilter}
              // setCityFilter={setCityFilter}
              currentStep={currentStep}
              setcurrentStep={setcurrentStep}
              location={location}
              setLocation={setLocation}
              setFormValues={setFormValues}
              formValuesSportsFacilities={formValuesSportsFacilities}
              setIsFirstTime={setIsFirstTime}
              isFirstTime={isFirstTime}
              isEditFirst={isEditFirst}
              setEditFirst={setEditFirst}
              setMultipleImages={setMultipleImages}
              multipleImages={multipleImages}
              listData={listData}
              slotDuration={slotDuration}
              setSlotDuration={setSlotDuration}
            />
          ) : null}
          {currentStep == 2 ? (
            <AddSurface
              state={state}
              setcurrentStep={setcurrentStep}
              surfaces={surfaces}
              sportstype={sportstype}
              setFormValues={setFormValues}
              formValuesSportsFacilities={formValuesSportsFacilities}
              listData={listData}
              isSecondTime={isSecondTime}
              setIsSecondTime={setIsSecondTime}
              weekdaytype={weekdaytype}
              setweekdaytype={setweekdaytype}
              slotDuration={slotDuration}
            />
          ) : null}
          {currentStep == 3 ? (
            <Amenities
              setcurrentStep={setcurrentStep}
              // isLoading={isLoading}
              Amenitieslist={Amenitieslist}
              setAmenitieslist={setAmenitieslist}
              formValuesSportsFacilities={formValuesSportsFacilities}
              setCheckedAmenities={setCheckedAmenities}
              checkedAmenities={checkedAmenities}
              setIsSecondTime={setIsSecondTime}
              setEditFirst={setEditFirst}
              isEditFirst={isEditFirst}
              setVenueOffersImage={setVenueOffersImage}
              setVenueOffers={setVenueOffers}
              venueOffersImage={venueOffersImage}
              venueOffers={venueOffers}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
