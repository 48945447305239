import React, { useLayoutEffect, useState } from "react";
import Loader from "./Utils/Loader";
import { CustomGetAPI } from "./Utils/APIConnector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GoogleMapLocation from "./VenueListing/GoogleMapLocation";
import { convertTo12HourFormat, venueSportsData } from "./Utils/common";
import Carousel from "react-bootstrap/Carousel";

export default function ShowListDetailsAdmin() {
  const [venueData, setVenueData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const userID = localStorage.getItem("userid");
  useLayoutEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    CustomGetAPI("/Venue/GetvenuecitybyID/" + location.state.id).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result?.status === "success") {
            CustomGetAPI(
              "/Venue/GetvenuebyID/" +
                result.venuename +
                "/" +
                (userID ? userID : 0)
            ).then((result) => {
              if (result === undefined || result === "") {
                alert("Something went wrong");
              } else {
                result = JSON.parse(result);
                setIsLoading(false);
                if (result?.status === "Success") {
                  // console.log(result?.venue);
                  setVenueData(result?.venue);
                } else {
                  navigate("/home");
                }
              }
            });
          }
        }
      }
    );
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <div className="mb-5">
          <div className="row px-2">
            <div className="col-12">
              <img
                src="/images/backArrow.svg"
                onClick={() => window.history.go(-1)}
              />
            </div>
          </div>
          <div className="form-control mt-2" style={{ background: "#F7F7F7" }}>
            <div className="row ">
              <div className="col-12 text-center position-relative">
                <div className="venuePlaceName">{venueData?.name}</div>
              </div>
            </div>

            <div className="row ">
              <div className="col-12">
                <div className="row">
                  {/* <div className="col-1">
                  <img
                    src="/images/googlemap.svg"
                    alt=""
                    onClick={() => {
                      setShowGoogleMap(true);
                     
                    }}
                  />
                </div> */}
                  <GoogleMapLocation
                    address={venueData?.addresses}
                    // getDirections={handleGetDirections}
                  />

                  <div className="col-1">
                    <img src="/images/line.svg" alt="" />
                  </div>
                  <div className="col-10">
                    <div className="venueText mt-1">{venueData?.addresses}</div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-10">
                      <span className="venueText" style={{ fontSize: "13px" }}>
                        Operating Hours:{" "}
                      </span>
                      <span className="colorClass" style={{ fontSize: "13px" }}>
                        {convertTo12HourFormat(venueData?.startTime) ===
                          "12:00 am" &&
                        convertTo12HourFormat(venueData?.endTime) === "12:00 am"
                          ? "24 Hours"
                          : `${convertTo12HourFormat(
                              venueData?.startTime
                            )} - ${convertTo12HourFormat(venueData?.endTime)}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Container mt-2">
            <div className="my-3">
              <Carousel controls={true} indicators={false}>
                {venueData?.venueImages?.map((data, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={data}
                      className="venuePhotos1 img-fluid w-100"
                      alt="image"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              {/* <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/Frame.svg"
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/Frame.svg"
                  alt="Third slide"
                />
              </Carousel.Item>
              </Carousel> */}
            </div>

            {/* <div className="row mt-3">
                    <div className="col-6">
                        <div className="venuePlaceCard">
                            <div className="row mt-2">
                                <span className='venueText1'>Amenities available</span>
                            </div>
                            <div className="row my-2 mx-0">
                                <div className="col-2">
                                    <img src="/images/parking-area.svg" className='sportsAmenities' alt="image" />
                                </div>
                                <div className="col-2">
                                    <img src="/images/food-court.svg" className='sportsAmenities' alt="image" />
                                </div>
                                <div className="col-md-8 col-8">
                                    <span className='venueText' onClick={openAmenitiesModal} style={{cursor:'pointer'}}>+3 more</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <divName="col-6">
                        <div className="venuePlaceCard">
                            <div className="row mt-2">
                                <span className='venueText1'>Sports available</span>
                            </div>

                            <div className="row my-2 mx-0">
                                <div className="col-md-2 col-2">
                                    <img src="/images/Group.svg" className='sportsAmenities' alt="image" />
                                </div>
                                <div className="col-md-2 col-2">
                                    <img src="/images/SportsSoccer.svg" className='sportsAmenities' alt="image" />
                                </div>
                                <div className="col-md-8 col-8">
                                    <span className='venueText' onClick={openSportsModal} style={{cursor:'pointer'}}>+4 more</span>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div> */}

            <div className="row mt-1">
              <span className="venueText" style={{ fontSize: "16px" }}>
                Surface types available
              </span>

              <div className="grid-container text-center mt-1">
                <>
                  {venueData?.venueSurface?.length != 0 ? (
                    venueData?.venueSurface?.map((data) => {
                      return (
                        <button key={data?.id} className="citiesbox border-0">
                          {data?.name}
                        </button>
                      );
                    })
                  ) : (
                    <div className="citiesbox border-0">
                      No Surface Type available
                    </div>
                  )}
                </>
              </div>
            </div>

            <div className="row mt-4">
              <span className="venueText" style={{ fontSize: "16px" }}>
                Sports Available
              </span>

              <div className="grid-container text-center mt-1">
                <>
                  {venueData?.venueSports?.length != 0 ? (
                    venueData?.venueSports?.map((data) => {
                      return (
                        <button key={data?.id} className="citiesbox border-0">
                          <img
                            src={`/images/${venueSportsData(data?.name)}`}
                            alt=""
                            className="mx-1"
                          />
                          {data?.name}
                        </button>
                      );
                    })
                  ) : (
                    <div className="citiesbox border-0">
                      No Sports available
                    </div>
                  )}
                </>
              </div>
            </div>

            <div className="row mt-4">
              <span className="venueText" style={{ fontSize: "16px" }}>
                Amenities Available
              </span>

              <div className="grid-container text-center mt-1">
                <>
                  {venueData?.venueAmenities?.length != 0 ? (
                    venueData?.venueAmenities?.map((data, i) => {
                      return (
                        <div key={i} className="citiesbox border-0">
                          {data}
                        </div>
                      );
                    })
                  ) : (
                    <div className="citiesbox border-0">
                      No Amenities available
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
