import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import $ from "jquery";
import Swal from "sweetalert2";
import {
  CheckValidation,
  convertDecimalToTime,
  validateEmail,
  VenueDate,
} from "../Utils/common";
export default function VenueOwnerOfflineBooking({
  showoffline,
  setShowoffline,
  VenueId,
  isReschedule,
  bookedVenueDetails,
  totalAmount,
  activeCourtID,
}) {
  const [bookbyData, setBookbyData] = useState({
    Name: "",
    Email: "",
    Number: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  const suggestionref = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (
      suggestionref.current &&
      !suggestionref.current.contains(event.target)
    ) {
      setSuggestions([]);
    }
  };
  const [paymentData, setPaymentData] = useState({
    paid: "",
    discount: "",
  });
  const [paymentDataError, setPaymentDataError] = useState({
    paid: false,
    discount: false,
  });
  const [dob, setDob] = useState(showoffline?.date);
  const [slotNumber, setSlotNumber] = useState(0);
  const [endExpiryDate, setendExpiryDate] = useState(dob);
  useEffect(() => {
    if (dob) {
      const nextDay = new Date(dob);
      nextDay.setDate(nextDay.getDate() + 1);
      const minDate = nextDay.toISOString().split("T")[0];
      setendExpiryDate(minDate);
    }
  }, [dob]);
  useEffect(() => {
    setSlotNumber(Math.round(bookedVenueDetails?.slotDuration * 2));
  }, [bookedVenueDetails]);
  const [venuePrice, setVenuePrice] = useState(0);

  const [emailError, setEmailError] = useState(false);
  const [isReplicateBooking, setIsReplicateBooking] = useState(false);
  const [durationType, setDurationType] = useState(0);

  const [SurfaceSportID, setSurfaceSportID] = useState({
    surface: { id: "", val: "" },
    sport: { id: "", val: "" },
  });
  const [showTimeList, setShowTimeList] = useState({
    value: "",
    show: false,
  });
  const userID = localStorage.getItem("userid");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [venueSlots, setVenueSlots] = useState();
  const TimeRef = useRef(null);
  const [surfacetype, setsurfacetype] = useState(null);
  const [venueData, setVenueData] = useState();
  const [surfaceData, setSurfaceData] = useState([]);
  const [Duration, setDuration] = useState(null);
  const [timeDuration, setTimeDuration] = useState(0);
  const [cartCount, setCartCount] = useState(1);
  const [selectedCartCount, setSelectedCartCount] = useState(1);
  const [nextAvailableSlots, setNextAvailableSlots] = useState(0);
  const [slotDuration, setSlotDuration] = useState(0.5);
  const [repeatEndDate, setRepeatEndDate] = useState();
  // useEffect(() => {
  //   setSlotDuration(
  //     venueData?.minSlotTime === 60
  //       ? 0.5
  //       : venueData?.minSlotTime === 30
  //       ? 0.5
  //       : 0
  //   );
  // }, [venueData?.minSlotTime]);
  useEffect(() => {
    if (timeDuration && SurfaceSportID?.surface?.id) {
      GetTimeslot();
    }
  }, [timeDuration, SurfaceSportID?.surface?.id]);

  useEffect(() => {
    const totalPrice = selectedTimeSlots.reduce(
      (acc, selectedSlot) => acc + selectedSlot.price,
      0
    );
    setVenuePrice(totalPrice);
  }, [selectedTimeSlots]);
  useEffect(() => {
    function handleClickOutside(ref) {
      return function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowTimeList((prevState) => ({ ...prevState, show: false }));
        }
      };
    }
    const handleTimeClick = handleClickOutside(TimeRef);
    document.addEventListener("mousedown", handleTimeClick);

    return () => {
      document.removeEventListener("mousedown", handleTimeClick);
    };
  }, [TimeRef]);

  const sportsHandle = (val) => {
    setDob(showoffline?.date);
    setSelectedSurfaceId(0);
    const selectedOption = $("#selectSportsName")
      .find("option:selected")
      .attr("data-name");
    setSurfaceSportID((prevState) => ({
      ...prevState,
      sport: { ...prevState.sport, id: val, val: selectedOption },
    }));
    setVenueSlots([]);
    setsurfacetype([]);
    setShowTimeList((prevState) => ({ ...prevState, value: "" }));
    let sportData = surfaceData?.find((item) => item.id === parseInt(val));
    setSportData(sportData);
    setSelectedTimeSlots([]);
    setSlotDuration(0.5);
    setNextAvailableSlots(0);
    if (val != 0) {
      CustomGetAPI(`/venue/Getsurfaces/${venueData?.id}/${val}`).then(
        (result) => {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            if (result?.status === "Success") {
              setDuration(result?.duration);
              setTimeDuration(result?.duration[0]?.time);
              setCartCount(result?.noOfCarts);
              setsurfacetype(result.response);
              if (result.response.length === 1) {
                setSurfaceSportID((prevState) => ({
                  ...prevState,
                  surface: {
                    ...prevState.surface,
                    id: result.response[0].surfaceid,
                    val: result.response[0].surfacename,
                  },
                }));
                setSelectedSurfaceId(result.response[0].surfaceid);
                GetTimeslot(parseInt(val), dob, result.response[0].surfaceid);
              } else if (isReschedule === "false") {
                setSurfaceSportID((prevState) => ({
                  ...prevState,
                  surface: {
                    ...prevState.surface,
                    id: "",
                    val: "",
                  },
                }));
              }
            }
          }
        }
      );
    }
  };

  async function GetTimeslot(val, date, surfaceid) {
    if (date) {
      setDob(date);
    } else {
      setDob(showoffline?.date);
      date = showoffline?.date;
    }
    date = new Date(date);
    const year = date ? date.getFullYear() : dob.getFullYear();
    const month = (date ? date.getMonth() + 1 : dob.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const day = date
      ? date.getDate().toString().padStart(2, "0")
      : dob.getDate().toString().padStart(2, "0");
    const sportid = val ? val : SurfaceSportID?.sport?.id;

    const formattedDate = `${year}-${month}-${day}`;
    await CustomGetAPI(
      `/Venue/GetTimeslots/${venueData?.id}/${sportid}/${
        surfaceid ? surfaceid : SurfaceSportID?.surface?.id
      }/${timeDuration ? timeDuration : 0}/false/${
        isReschedule ? false : true
      }?date=${formattedDate}`
    ).then((result) => {
      if (result === "" || result === undefined) {
        alert("Error");
      } else {
        result = JSON.parse(result);
        setVenueSlots(result);
      }
    });
  }
  const [sportData, setSportData] = useState([]);
  const [selectedSportId, setSelectedSportId] = useState(0);
  const [selectedSurfaceId, setSelectedSurfaceId] = useState(0);

  useEffect(() => {
    if (VenueId) {
      fetchData();
    }
  }, [VenueId]);

  useEffect(() => {
    if (venueData?.venueSports?.length === 1 && selectedSportId === 0) {
      setSelectedSportId(venueData?.venueSports[0].id);
      sportsHandle(venueData?.venueSports[0]?.id);
    }
  }, [venueData?.venueSports]);
  async function fetchData() {
    CustomGetAPI("/Venue/GetvenuecitybyID/" + VenueId).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "success") {
          CustomGetAPI(
            "/Venue/GetvenuebyID/" +
              result.venuename +
              "/" +
              (userID ? userID : 0)
          ).then((result) => {
            if (result === undefined || result === "") {
              alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              if (result?.status === "Success") {
                setVenueData(result?.venue);
              }
            }
          });
        }
      }
    });
  }
  const handleContactPicker = async () => {
    if ("contacts" in navigator && "ContactsManager" in window) {
      try {
        const contacts = await navigator.contacts.select(
          ["name", "email", "tel"],
          { multiple: false }
        );

        // Debug: Log the contacts object

        if (contacts.length > 0) {
          const selectedContact = contacts[0];

          // Debug: Alert to check the selected fields
          alert(`Phone Number: ${selectedContact.tel?.[0]}`);

          // Update state
          setBookbyData({
            Name: selectedContact.name || "Unknown Name",
            Email: selectedContact.email?.[0] || "No Email",
            Number: selectedContact.tel?.[0] || "No Number",
          });

          // Debug: Log the state update
        } else {
          alert("No contact selected.");
        }
      } catch (error) {
        alert("An error occurred while accessing contacts.");
        console.error("Error accessing contacts:", error);
      }
    } else {
      alert("Contact Picker API is not supported on this browser.");
    }
  };

  const handleinputchange = (e) => {
    if (e.target.name === "Number" && e.target.value.length > 9) {
      setSuggestions([]);
    }
    if (e.target.name === "Number" && e.target.value.length > 10) {
      return;
    }
    if (e.target.name === "Email" && !validateEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setBookbyData({ ...bookbyData, [e.target.name]: e.target.value });
    if (e.target.value && e.target.name === "Number") {
      try {
        const response = CustomGetAPI(
          `/user/GetVenueusers/${e.target.value}/null/${localStorage.getItem(
            "userid"
          )}`
        ).then((result) => {
          result = JSON.parse(result);
          setSuggestions(result);
        });
        // setSuggestions(data.suggestions);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handlesubmit = (e) => {
    var isFormValid = CheckValidation({ formID: "payPopup" });
    if (selectedTimeSlots.length != "0") {
      const selectedTimeSlots =
        document.getElementsByClassName("selectedtimeslots");
      selectedTimeSlots[0].style.display = "none";
    } else {
      const selectedTimeSlots =
        document.getElementsByClassName("selectedtimeslots");
      selectedTimeSlots[0].style.display = "block";
    }
    const ValidateEmail = validateEmail(bookbyData?.Email);
    if (isFormValid && selectedTimeSlots.length != "0" && ValidateEmail) {
      const totalPrice = selectedTimeSlots.reduce(
        (acc, selectedSlot) => acc + selectedSlot.price,
        0
      );
      var raw = JSON.stringify({
        VenueId: VenueId,
        SurfaceId: SurfaceSportID?.surface?.id,
        venueBookings: selectedTimeSlots.map((slot) => ({
          date: slot.startTime.split("T")[0],
          startTime: slot.startTime.split("T")[1].substring(0, 5),
          endTime: slot.endTime.split("T")[1].substring(0, 5),
          SlotDuration: slotDuration,
        })),
        Date: dob,
        SportId: SurfaceSportID?.sport?.id,
        BookBy: userID,
        name: bookbyData?.Name,
        Phonenumber: bookbyData?.Number,
        Email: bookbyData?.Email,
        userID: userID,
        Duration: timeDuration ? timeDuration : 0,
        NoOfPeople: selectedCartCount ? selectedCartCount : 0,
        Isrepatedbooking: isReplicateBooking,
        ReoccurringDuration: parseInt(durationType),
        Enddate: repeatEndDate,
        Offlinebookingamount: totalPrice,
        OfflineAdvanceAmount: parseFloat(paymentData?.paid),
        OfflineDiscountAmount: parseFloat(paymentData?.discount),
      });
      CustomPostAPI(`/Venue/offlinebooking`, raw).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result?.status === "success") {
            Swal.fire({
              icon: "success",
              title: "Congratulations!",
              text: "Your offline booking is confirmed.",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                setShowoffline((prevState) => ({ ...prevState, popup: false }));
                // window.location.reload();
              }
            });
          } else if (result?.status === "error") {
            const errorDiv = document.getElementById("repetedSlotError");
            errorDiv.classList.remove("d-none");
            errorDiv.textContent = result?.message || "";
          }
        }
      });
    }
  };

  const rescheduleBookingRequest = () => {
    var raw = JSON.stringify({
      Id: bookedVenueDetails?.venuebookingId,
      Date: dob,
      venueBookings: selectedTimeSlots.map((slot) => ({
        date: slot.startTime.split("T")[0],
        startTime: slot.startTime.split("T")[1].substring(0, 5),
        endTime: slot.endTime.split("T")[1].substring(0, 5),
        SlotDuration: slotDuration,
      })),
    });

    CustomPostAPI(`/Venue/RescheduleBooking`, raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Congratulations!",
            text: "Your booking is Rescheduled.",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setShowoffline((prevState) => ({
                ...prevState,
                popup: false,
              }));
              window.location.reload();
            }
          });
        }
      }
    });
  };
  const handleReshedule = (e) => {
    if (
      selectedTimeSlots.length != "0" &&
      selectedTimeSlots.length === slotNumber
    ) {
      const selectedTimeSlots =
        document.getElementsByClassName("selectedtimeslots");
      selectedTimeSlots[0].style.display = "none";
    } else {
      const selectedTimeSlots =
        document.getElementsByClassName("selectedtimeslots");
      selectedTimeSlots[0].style.display = "block";
      selectedTimeSlots[0].textContent = `Please select total ${
        slotNumber / 2
      } hr slot's`;
    }

    if (
      selectedTimeSlots.length != "0" &&
      selectedTimeSlots.length === slotNumber
    ) {
      if (selectedTimeSlots.length > 0) {
        const Amount = selectedTimeSlots.reduce(
          (total, slot) => total + slot.price,
          0
        );
        if (Amount > totalAmount) {
          Swal.fire({
            icon: "warning",
            title: "Warning!",
            text: `This slot cannot be selected. The price is higher than your previous selection.`,
            showConfirmButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          if (bookedVenueDetails?.isAcrive == 4) {
            rescheduleBookingRequest();
          } else {
            let timerInterval = "";
            Swal.fire({
              title: "Reschedule request sent successfully!",
              html: "It may take upto <b></b> minutes.<br/><br/><p class='text-danger'>*Do not navigate away from this page or close your browser until the request is complete.</p>",
              timer: 300000,
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  const timeLeft = Swal.getTimerLeft();
                  const minutes = Math.floor(timeLeft / 60000);
                  const seconds = Math.floor((timeLeft % 60000) / 1000);
                  timer.textContent = `${minutes}:${
                    seconds < 10 ? "0" : ""
                  }${seconds}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                  icon: "error",
                  title: "Please try different timeslot or venue!",
                  text: "Your request has rejected.",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              }
            });

            CustomGetAPI(
              `/payment/Reschedulerequest/${
                bookedVenueDetails?.convertedID
              }?date=${VenueDate(dob).formattedDate}&selectedtime=${
                selectedTimeSlots[0]?.startTime
              }&duration=${bookedVenueDetails?.slotDuration}`
            ).then((result) => {
              if (result === undefined || result === "") {
                alert("Something went wrong");
              } else {
                result = JSON.parse(result);
                if (result.status === "accepted") {
                  rescheduleBookingRequest();
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Please try again later!",
                    text: "Your request has failed.",
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // window.location.reload();
                    }
                  });
                }
              }
            });
          }
        }
      }
    }
  };

  const handleslotadditionchange = (e) => {
    if (nextAvailableSlots != 0) {
      setNextAvailableSlots((count) => count - 1);
      setSlotDuration((prevDuration) => prevDuration + 0.5);
      const nextslot = venueSlots.filter(
        (venueslot) =>
          venueslot.startTime >
          selectedTimeSlots[selectedTimeSlots.length - 1].startTime
      )[0];
      setSelectedTimeSlots((prevSelected) => [...prevSelected, nextslot]);
    }
  };

  const handleTimeSlot = (slots) => {
    if (isReschedule) {
      let count = 0;
      const selectedStartTime = slots?.startTime;

      for (let i = 0; i < venueSlots.length; i++) {
        const venueslot = venueSlots[i];

        // Check if the slot's start time is after the selected start time
        if (venueslot.startTime > selectedStartTime) {
          if (!venueslot.booked) {
            count++;
          } else {
            break;
          }
        }
      }
      if (count + 1 >= slotNumber) {
        const currentSlotIndex = venueSlots.findIndex(
          (slot) => slot.startTime === slots.startTime
        );
        const nextSlots = venueSlots.slice(
          currentSlotIndex,
          currentSlotIndex + slotNumber
        );
        setSelectedTimeSlots((prevSelected) => {
          const isSlotSelected =
            prevSelected.length > 0 &&
            prevSelected[0].startTimeString === slots.startTimeString;

          if (isSlotSelected) {
            // If already selected, remove it from the selected slots
            return [];
          } else {
            // If not selected, add the entire slot object to the selected slots
            setShowTimeList((prevState) => ({
              ...prevState,
              show: false,
            }));
            return nextSlots;
          }
        });
        const selectedTimeSlots =
          document.getElementsByClassName("selectedtimeslots");
        selectedTimeSlots[0].style.display = "none";
        selectedTimeSlots[0].textContent = ``;
      } else {
        const selectedTimeSlots =
          document.getElementsByClassName("selectedtimeslots");
        selectedTimeSlots[0].style.display = "block";
        selectedTimeSlots[0].textContent = `${
          slotNumber / 2
        } hr total slot's not available for this start time`;
      }
    } else {
      if (!slots?.booked) {
        let count = 0;
        const selectedStartTime = slots?.startTime;

        for (let i = 0; i < venueSlots.length; i++) {
          const venueslot = venueSlots[i];

          // Check if the slot's start time is after the selected start time
          if (venueslot.startTime > selectedStartTime) {
            if (!venueslot.booked) {
              count++;
            } else {
              break;
            }
          }
        }
        setSlotDuration(0.5);
        setNextAvailableSlots(count);
        setSelectedTimeSlots((prevSelected) => {
          const isSlotSelected =
            prevSelected.length > 0 &&
            prevSelected[0].startTimeString === slots.startTimeString;

          if (isSlotSelected) {
            return [];
          } else {
            setShowTimeList((prevState) => ({
              ...prevState,
              show: false,
            }));
            return [slots];
          }
        });
      }
    }
  };

  const surfaceHandle = (val, sportId) => {
    const selectedOption = $("#selectSurfaceName")
      .find("option:selected")
      .attr("data-name");
    setSurfaceSportID((prevState) => ({
      ...prevState,
      surface: { ...prevState.surface, id: val, val: selectedOption },
    }));
    getData(val);
    setSlotDuration(0.5);
    setNextAvailableSlots(0);
    GetTimeslot(sportId ? sportId : SurfaceSportID?.sport?.id, dob, val);
  };

  const getData = async (val) => {
    await CustomGetAPI(`/Venue/GetPrices/${venueData?.id}/0/${val}`).then(
      (result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result?.status === "Success") {
            setSurfaceData(result?.surfaceSport?.data);
          }
        }
      }
    );
  };

  const hadledatechange = (e) => {
    setDob(e);
    GetTimeslot(SurfaceSportID?.sport?.id, e);
    setSelectedTimeSlots([]);
    setSlotDuration(0.5);
    setNextAvailableSlots(0);
  };

  const handleTimeChange = (e) => {
    if (
      SurfaceSportID?.surface.id != "" &&
      SurfaceSportID?.surface?.id != "0"
    ) {
      setShowTimeList((prevState) => ({
        ...prevState,
        show: !prevState?.show,
      }));
    }
  };

  useEffect(() => {
    if (showoffline?.date && bookedVenueDetails) {
      const sport = venueData?.venueSports?.find(
        (data) => data.name === bookedVenueDetails.sportName
      );
      if (sport) {
        setSelectedSportId(sport.id);
        sportsHandle(sport.id);
      }
      const surface = venueData?.venueSurface?.find(
        (data) => data.name === bookedVenueDetails.surfaceName
      );
      if (surface) {
        setSelectedSurfaceId(surface.id);
        surfaceHandle(surface.id, sport.id);
        setSurfaceSportID((prevState) => ({
          ...prevState,
          surface: {
            ...prevState.surface,
            id: surface?.id,
            val: surface?.name,
          },
        }));
      }
    }
  }, [
    bookedVenueDetails?.sportName,
    bookedVenueDetails?.surfaceName,
    venueData,
    showoffline,
  ]);
  return (
    <React.Fragment>
      <Modal
        className="popupBtn"
        show={showoffline?.popup}
        onHide={() => {
          setShowoffline((prevState) => ({ ...prevState, popup: false }));
          if (isReschedule) {
            window.location.reload();
          }
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">{showoffline?.title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" id="payPopup">
            <div className="col-12">
              <div className="row mb-3 parent">
                <div className="col-5">
                  <span className="venueText">
                    {venueData?.category === "Sports" ? "Sport" : "Event"}*
                  </span>
                </div>
                <div className="col-7 parent">
                  <select
                    id="selectSportsName"
                    required
                    className="form-select"
                    style={{ lineHeight: "1.7" }}
                    aria-label="Select Sports"
                    name=""
                    onChange={(e) => {
                      sportsHandle(e.target.value);
                      setSelectedSportId(e.target.value);
                    }}
                    value={selectedSportId}
                    disabled={bookedVenueDetails?.sportName}
                  >
                    <option value="0">Select {venueData?.category}</option>
                    {venueData?.venueSports?.map((data) => (
                      <option
                        key={data?.id}
                        value={data?.id}
                        data-name={data?.name}
                      >
                        {data?.name}
                      </option>
                    ))}
                  </select>
                  <span className="form-text invalid-feedback">
                    * This field is Required!
                  </span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-5">
                  <span className="venueText">
                    {venueData?.category === "Sports"
                      ? "Court/Surface"
                      : "Space"}
                    *
                  </span>
                </div>
                <div className="col-7 parent">
                  <select
                    id="selectSurfaceName"
                    required
                    className="form-select"
                    style={{
                      background:
                        surfacetype?.length < 1 || surfacetype === null
                          ? "#F7F7F7"
                          : "",
                      opacity:
                        surfacetype?.length < 1 || surfacetype === null
                          ? "0.4"
                          : "1",
                    }}
                    aria-label="Select Sports"
                    onChange={(e) => {
                      surfaceHandle(e.target.value);
                      setSelectedSurfaceId(e.target.value);
                    }}
                    name=""
                    value={selectedSurfaceId}
                    disabled={
                      surfacetype?.length < 1 ||
                      surfacetype === null ||
                      isReschedule
                    }
                  >
                    <option value="0">Select Surface</option>
                    {surfacetype?.map((surface, i) => (
                      <option
                        value={surface.surfaceid}
                        data-name={surface?.surfacename}
                        selected={surfacetype.length === 1 && i === 0}
                      >
                        {surface.surfacename}
                      </option>
                    ))}
                  </select>
                  <span className="form-text invalid-feedback">
                    * This field is Required!
                  </span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-5 pe-0">
                  <label htmlFor="" className="venueText">
                    Date of booking*{" "}
                  </label>
                </div>
                <div className="col-7 ">
                  <DatePicker
                    showIcon
                    minDate={isReschedule ? new Date() : null}
                    disabled={SurfaceSportID?.surface.id === ""}
                    className="w-100 form-control"
                    selected={dob ? new Date(dob) : null}
                    onChange={(e) => {
                      hadledatechange(e);
                    }}
                    icon="fa fa-calendar"
                  />
                </div>
              </div>
              {!isReschedule && (
                <>
                  {SurfaceSportID?.sport?.val &&
                  SurfaceSportID?.sport?.val === "Go-Karting" ? (
                    <>
                      <div className="row ">
                        <div className="col-5 mt-3">
                          <span className="venueText">Duration</span>
                        </div>
                        <div className="col-7">
                          <div className="row">
                            {Duration?.map((item) => (
                              <>
                                <div className="col-md-6 col-6 parent position-relative mt-3 ">
                                  <div className="input-group ">
                                    <div
                                      className={`form-control text-center ${
                                        timeDuration === item?.time
                                          ? "active-time"
                                          : ""
                                      }`}
                                      id=""
                                      onClick={() => {
                                        setTimeDuration(item?.time);
                                        setSelectedTimeSlots([]);
                                      }}
                                    >
                                      <span>{item?.time} Min</span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-5 ">
                          <span className="venueText">No. of Cart</span>
                        </div>
                        <div className="col-7">
                          <select
                            className="form-select"
                            value={selectedCartCount}
                            onChange={(e) =>
                              setSelectedCartCount(e.target.value)
                            }
                          >
                            {Array.from(
                              { length: cartCount },
                              (_, i) => i + 1
                            ).map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-5">
                        <span className="venueText">Min Duration</span>
                      </div>
                      <div className="col-7 parent position-relative">
                        <div className="input-group">
                          <div
                            className="form-control"
                            id=""
                            style={{ background: "#F7F7F7", opacity: "0.7" }}
                          >
                            <span>{venueData?.minSlotTime}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="row my-3 ">
                <div className="col-5 pe-0">
                  <label htmlFor="" className="venueText">
                    {isReschedule ? "Time*" : "Start Time*"}
                  </label>
                </div>

                <div className="col-7  position-relative p-0" ref={TimeRef}>
                  <div
                    className="input-group timebox"
                    onClick={handleTimeChange}
                    disabled
                  >
                    <div
                      className="form-control"
                      id="TimeInput"
                      style={{
                        background: SurfaceSportID?.surface.id
                          ? "#fff"
                          : "#f7f7f7",
                      }}
                    >
                      {selectedTimeSlots?.length === 0 ? (
                        "-- : --"
                      ) : (
                        <span>
                          {isReschedule ? (
                            <>
                              {selectedTimeSlots[0]?.startTimeString} -{" "}
                              {
                                selectedTimeSlots[selectedTimeSlots.length - 1]
                                  ?.endTimeString
                              }
                            </>
                          ) : (
                            <>{selectedTimeSlots[0]?.startTimeString}</>
                          )}
                        </span>
                      )}
                    </div>
                    <span className="input-group-text" id="basic-time">
                      <img
                        src="/images/clock.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>

                  {showTimeList?.show && (
                    <div className="time-dropdown">
                      <div
                        className="filtersOpen mx-0"
                        style={{ maxHeight: "150px", overflowY: "auto" }}
                      >
                        <div className="row mx-0">
                          {venueSlots?.length !== 0 ? (
                            venueSlots
                              ?.filter(
                                (slot) =>
                                  VenueDate(slot.startTime.split("T")[0])
                                    .formattedDate ===
                                  VenueDate(dob).formattedDate
                              )
                              ?.map((slots, i) => {
                                const isSlotSelected =
                                  selectedTimeSlots[0]?.startTimeString ===
                                  slots?.startTimeString;

                                return (
                                  <div className="col-6 py-1 px-1 my-1" key={i}>
                                    <button
                                      className={`citiesbox px-1 ${
                                        isSlotSelected ? "slot-active" : ""
                                      }`}
                                      onClick={() => {
                                        handleTimeSlot(slots);
                                      }}
                                      disabled={slots?.booked}
                                      style={{
                                        opacity: slots.booked ? "0.3" : "",
                                      }}
                                    >
                                      {/* {convertTo12HourFormat(
                                          slots?.startTime
                                        )} */}
                                      {slots?.startTimeString}
                                    </button>
                                  </div>
                                );
                              })
                          ) : (
                            <div className="citiesbox border-0">
                              No Time Slots available
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <span className="selectedtimeslots form-text invalid-feedback">
                    * This field is Required!
                  </span>
                </div>
              </div>

              {!isReschedule && (
                <>
                  <div
                    className="row mt-3"
                    style={{
                      display:
                        SurfaceSportID?.sport?.val != "Go-Karting"
                          ? ""
                          : "none",
                    }}
                  >
                    <div className="col-5 ">
                      <span className="venueText">Duration*</span>
                    </div>
                    <div className="col-7 parent">
                      <div className="row align-items-center">
                        <div className="col-2 pe-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            class={`cursor-pointer br-50 ${
                              slotDuration === 0.5
                                ? "DisableDurationBtn"
                                : "AddDurationBtn"
                            }`}
                            onClick={(e) => {
                              if (slotDuration != 0.5) {
                                setNextAvailableSlots((count) => count + 1);
                                setSlotDuration(
                                  (prevDuration) => prevDuration - 0.5
                                );
                                setSelectedTimeSlots((prevSelected) => [
                                  ...prevSelected.slice(0, -1),
                                ]);
                              }
                            }}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <div className="col-8 p-0 text-center">
                          {convertDecimalToTime(slotDuration)}
                        </div>
                        <div className="col-2 ps-0 text-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            class={`${
                              nextAvailableSlots === 0
                                ? "DisableDurationBtn"
                                : "AddDurationBtn"
                            } cursor-pointer br-50`}
                            onClick={(e) => {
                              handleslotadditionchange(e);
                            }}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3 d-flex align-items-center">
                    <div className="col-12 mb-2">
                      <b>Is this a recurring booking?</b>
                    </div>
                    <div className="col-12 mb-2">
                      <input
                        type="radio"
                        name="replicateBooking"
                        id="yes"
                        value="yes"
                        className="form-check-input me-1 "
                        checked={isReplicateBooking}
                        onClick={(e) => {
                          setIsReplicateBooking(!isReplicateBooking);
                        }}
                      />
                      <label htmlFor="yes" className="venueText me-2">
                        Yes
                      </label>

                      <input
                        type="radio"
                        name="replicateBooking"
                        id="no"
                        value="no"
                        className="form-check-input me-1 "
                        checked={!isReplicateBooking}
                        onClick={(e) => {
                          setIsReplicateBooking(!isReplicateBooking);
                        }}
                      />
                      <label htmlFor="no" className="venueText">
                        No
                      </label>
                    </div>
                    {isReplicateBooking && (
                      <>
                        <div className="col-6  parent">
                          Frequency:
                          <select
                            className="form-select py-0 mt-2"
                            value={durationType}
                            required
                            onChange={(e) => {
                              setDurationType(e.target.value);
                            }}
                          >
                            <option value="0">Select Duration</option>
                            <option value="1">Daily</option>
                            <option value="2">Weekly</option>
                            <option value="3">Monthly</option>
                          </select>
                          <span className="form-text invalid-feedback">
                            * This field is Required!
                          </span>
                        </div>
                        <div className="col-6 parent">
                          End Date:
                          <input
                            type="date"
                            required
                            className="form-control mt-2"
                            name="replicateEndDate"
                            id=""
                            value={repeatEndDate}
                            onChange={(e) => {
                              setRepeatEndDate(e.target.value);
                            }}
                            min={endExpiryDate}
                          />
                          <span className="form-text invalid-feedback">
                            * This field is Required!
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row my-3 d-flex align-items-center">
                    <div className="col-12 mb-2">
                      <b>Payment Details</b>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-5 pe-0">
                          <label htmlFor="" className="venueText">
                            Advance Payment
                          </label>
                        </div>
                        <div className="col-7 ">
                          <input
                            type="number"
                            placeholder="Advance Payment in Rupee"
                            name="paid"
                            inputMode="numeric"
                            maxLength={10}
                            className="form-control"
                            value={paymentData?.paid}
                            required
                            onChange={(e) => {
                              if (
                                parseFloat(e.target.value) >
                                parseFloat(venuePrice)
                              ) {
                                setPaymentDataError({
                                  ...paymentDataError,
                                  [e.target.name]: true,
                                });
                                return;
                              } else {
                                setPaymentDataError({
                                  ...paymentDataError,
                                  [e.target.name]: false,
                                });
                                setPaymentData({
                                  ...paymentData,
                                  [e.target.name]: e.target.value,
                                });
                              }
                            }}
                          />
                          {paymentDataError?.paid && (
                            <small className="text-danger">
                              Amount Should Not Be Greater Than Total Amount
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row my-2 parent">
                        <div className="col-5 pe-0">
                          <label htmlFor="" className="venueText">
                            Discount Amount
                          </label>
                        </div>
                        <div className="col-7 ">
                          <input
                            type="number"
                            inputMode="numeric"
                            maxLength={10}
                            placeholder="Discount Amount in Rupee"
                            className="form-control"
                            name="discount"
                            value={paymentData?.discount}
                            onChange={(e) => {
                              if (
                                parseFloat(e.target.value) >
                                parseFloat(venuePrice) -
                                  parseFloat(paymentData?.paid)
                              ) {
                                setPaymentDataError({
                                  ...paymentDataError,
                                  [e.target.name]: true,
                                });
                                return;
                              } else {
                                setPaymentDataError({
                                  ...paymentDataError,
                                  [e.target.name]: false,
                                });
                                setPaymentData({
                                  ...paymentData,
                                  [e.target.name]: e.target.value,
                                });
                              }
                            }}
                          />
                          {paymentDataError?.discount && (
                            <small className="text-danger">
                              Discount Should Be Less Than Pending Amount
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3 d-flex align-items-center">
                    <div className="col-12 mb-2">
                      <b>Booked By</b>
                    </div>
                    {/* <div className="col-6 text-end">
                      <button
                        className="selectContactBtn px-2 py-1"
                        onClick={() => {
                          handleContactPicker();
                        }}
                      >
                        Select Contact
                      </button>
                    </div> */}
                    <div className="col-12 mt-3">
                      <div className="row">
                        <div className="col-5 pe-0">
                          <label htmlFor="" className="venueText">
                            Name*
                          </label>
                        </div>
                        <div className="col-7 parent">
                          <input
                            type="text"
                            placeholder="Name"
                            name="Name"
                            className="form-control"
                            value={bookbyData?.Name}
                            required
                            onChange={(e) => handleinputchange(e)}
                          />
                          <span className="selectedtimeslots form-text invalid-feedback">
                            * This field is Required!
                          </span>
                        </div>
                      </div>
                      <div className="row my-2 parent">
                        <div className="col-5 pe-0">
                          <label htmlFor="" className="venueText">
                            Phone Number*
                          </label>
                        </div>
                        <div className="col-7 " ref={suggestionref}>
                          <div className="position-relative">
                            <input
                              type="number"
                              inputMode="numeric"
                              maxLength={10}
                              placeholder="Number"
                              className="form-control"
                              name="Number"
                              required
                              value={bookbyData?.Number}
                              onChange={(e) => handleinputchange(e)}
                            />
                            <span className="selectedtimeslots form-text invalid-feedback">
                              * This field is Required!
                            </span>
                            {suggestions.length > 0 && (
                              <ul className="suggestions-list w-100 cursor-pointer py-3">
                                {suggestions?.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      setBookbyData({
                                        ...bookbyData,
                                        Name: suggestion?.firstName,
                                        Number: suggestion?.phoneNumber,
                                        Email: suggestion?.email,
                                      });
                                      setSuggestions([]);
                                    }}
                                  >
                                    {suggestion?.phoneNumber}{" "}
                                    {suggestion?.firstName &&
                                      suggestion?.firstName}
                                    {index != suggestions.length - 1 && <hr />}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 pe-0">
                          <label htmlFor="" className="venueText">
                            Email
                          </label>
                        </div>
                        <div className="col-7 ">
                          <input
                            type="text"
                            placeholder="Email"
                            className="form-control"
                            name="Email"
                            required
                            value={bookbyData?.Email}
                            onChange={(e) => handleinputchange(e)}
                          />
                          {emailError && (
                            <small className="text-danger">
                              Please enter valid email Id
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-12 text-center">
              <small className="text-danger d-none " id="repetedSlotError">
                Error in Slot
              </small>
              <button
                className="venueListingTop booking  w-100 justify-content-center"
                onClick={(e) => {
                  if (isReschedule === "true") {
                    handleReshedule(e);
                  } else {
                    handlesubmit(e);
                  }
                }}
              >
                Book Now {venuePrice != 0 && <>(₹ {venuePrice})</>}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
