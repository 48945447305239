import React from "react";
import Loader from "../Utils/Loader";
import { venueSportsData } from "../Utils/common";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { CustomPostAPI } from "../Utils/APIConnector";
import { useNavigate } from "react-router-dom";

export default function AdminVenueList({
  venuesData,
  isLoading,
  setIsLoading,
}) {
  const navigate = useNavigate();
  const handleVenueStatus = (id, flag) => {
    setIsLoading(true);

    var raw = JSON.stringify({
      venueid: id,
      flag: flag,
    });
    CustomPostAPI("/Venue/AcceptOrRejectVenue", raw).then((result) => {
      if (result === undefined || result === "") {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      } else {
        setIsLoading(false);
        const message =
          flag === true
            ? "Venue approved successfully"
            : "Venue rejected successfully";
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: message,
        }).then(() => {
          window.location.reload();
        });
      }
    });
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          {venuesData?.map((venue, index) => (
            <div className="col-6 my-2">
              {" "}
              <Card className="mt-3 h-100 venueListCard">
                <Card.Body
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-9">
                      <p className="venueNameHeader m-0">{venue.name}</p>
                    </div>
                    <div className="col-3 text-end">
                      {" "}
                      <div className="row pe-2">
                        <div className="col-6">
                          <img
                            src="/images/Match Status/Approve.svg"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleVenueStatus(venue?.id, true);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <img
                            src="/images//Match Status/Reject.svg"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleVenueStatus(venue?.id, false);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <img
                        className="img-fluid me-1"
                        src="/images/LocationLatestIcon.svg"
                        alt=""
                      />
                      <span className="venueListAddress">{venue.address}</span>
                    </div>

                    <div className="col-8 mt-2">
                      <div className="text-start">
                        {venue?.venueSports?.map((data, i) => (
                          <>
                            {i < 3 && (
                              <div
                                className="sportimage me-2 mt-1"
                                style={{
                                  borderRadius: "3px",
                                  background: "white",
                                }}
                              >
                                <img
                                  src={`/images/${venueSportsData(data?.name)}`}
                                  className="img-fluid"
                                  alt="image"
                                  title={data?.name}
                                  style={{
                                    borderRadius: "11px",
                                    padding: "3px 3px",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>
                            )}
                            {i === 3 && venue?.venueSports?.length > 3 && (
                              <div
                                className="mx-lg-1 mx-1 Montserrat"
                                style={{ fontSize: "14px" }}
                              >
                                + {venue?.venueSports?.length - 3} more
                              </div>
                            )}
                          </>
                        ))}

                        {venue?.venueSports?.length === 0 && (
                          <span className="venueText">No sports available</span>
                        )}
                      </div>
                    </div>
                    <div className="col-4 text-end mt-3 text-danger ">
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/venue-owner-venue-details`, {
                            state: { id: venue?.id },
                          })
                        }
                      >
                        <span>Details</span>{" "}
                        <img
                          src="/images/NextClickIcon.svg"
                          className="cursor-pointer"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </>
      )}
    </React.Fragment>
  );
}
