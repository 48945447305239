import React, { useEffect, useState } from "react";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import { useLocation, useParams } from "react-router-dom";
import { VenueDate, convertTo12HourFormat } from "../Utils/common";
import Loader from "../Utils/Loader";
import Swal from "sweetalert2";

export default function RescheduleRequest() {
  const param = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const date = queryParams.get("date");
  const time = queryParams.get("time");
  const duration = queryParams.get("duration");
  const [bookingData, setBookingData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    CustomGetAPI(
      `/venue/Getofflinereschedulerequestdata/${param.bookingid}`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        setIsLoading(false);
        result = JSON.parse(result);
        if (result.status === "Success") {
          setBookingData(result?.venueDetails);
        } else if (result.status === "error") {
          Swal.fire({
            icon: "warning",
            title: "Link Expired!",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/home";
            }
          });
        }
      }
    });
  }, []);

  const handlerequest = (flag) => {
    setIsLoading(true);
    CustomGetAPI(
      `/payment/ReschedulerequestAction/${flag}/${param.bookingid}`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result.status === "success") {
          const message =
            flag === true
              ? "Thank you for accepting request, reschedule will be confirmed once user confirms!"
              : "Request rejected";
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: message,
          }).then(() => {
            window.location.href = "/home";
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Link Expired!",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/home";
            }
          });
        }
      }
      setIsLoading(false);
    });
  };
  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <Loader mt="20%" />
        ) : (
          <>
            {bookingData ? (
              <div className="row">
                <div className="col-12 bookingrequestdiv mt-3">
                  <h1 className="bookingrequestheading text-center m-0 py-2">
                    Booking Request
                  </h1>
                </div>
                <div className="col-12 bookingrequestdiv py-3 mt-3">
                  <div>
                    <img
                      src={bookingData?.venueImage}
                      alt=""
                      className="venuePhotos2 mb-3"
                    />
                  </div>
                  <div className="my-2">
                    <b>Venue :</b>
                    <span>{bookingData?.venueName}</span>
                  </div>
                  <div className="my-2">
                    <b>venue Address :</b>
                    <span>{bookingData?.venueAddress}</span>
                  </div>
                  <div className="my-2">
                    <b>Date of rescheduled booking :</b>
                    <span>{date}</span>
                  </div>
                  <div className="my-2">
                    <b>Time: </b>
                    <span>{time}</span>
                  </div>
                  <div className="my-2">
                    <b>Duration: </b>
                    <span>
                      {duration <= 1 ? `${duration} hr` : `${duration} hrs`}
                    </span>
                  </div>

                  <div className="my-2">
                    <b>Sport :</b>
                    <span>{bookingData?.sportName}</span>
                  </div>
                  <div className="my-2">
                    <b>Court :</b>
                    <span>{bookingData?.surfaceName}</span>
                  </div>

                  <div className="row mt-4 ">
                    <div className="col-6 text-end">
                      <button
                        className="venueListingTop booking  px-4 "
                        onClick={(e) => handlerequest(false)}
                      >
                        Reject
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        className="venueListingTop booking  px-3 "
                        style={{ background: "rgb(76, 175, 80)" }}
                        onClick={(e) => handlerequest(true)}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row"></div>
            )}
          </>
        )}
      </>
    </React.Fragment>
  );
}
