import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StaffCard from "./StaffCard";
import AddUpdateStaff from "./AddUpdateStaff";
import Popup from "../LiveScoreCardpopups/Popup";
import { CustomDeleteAPI, CustomGetAPI } from "../Utils/APIConnector";
import Swal from "sweetalert2";
import Loader from "../Utils/Loader";
export default function StaffMembers({ venueID }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState({ popup: false, updateData: null });
  const [staffData, setStaffData] = useState([]);
  useEffect(() => {
    GetStaffMembers();
  }, []);
  const GetStaffMembers = async () => {
    try {
      setIsLoading(true);
      await CustomGetAPI(`/Venue/GetStaffMembers/${venueID}`).then((result) => {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result?.status === "success") {
            setStaffData(result?.staff);
          }
        }
      });
    } catch (error) {
      console.error("GetStaffMembers: ", error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteStaffMember = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await CustomDeleteAPI(`/venue/DeleteStaffMember/${id}`).then(
          (result) => {
            if (result) {
              result = JSON.parse(result);
              GetStaffMembers();
            }
          }
        );
      }
    });
  };
  return (
    <Fragment>
      <div className="px-4 px-md-0 mt-3">
        <div className="row ">
          {isLoading ? (
            <Loader mt="30%" />
          ) : (
            <>
              <div className="d-flex justify-content-center my-2">
                <button
                  className="VenueOwnerBookOfflineBtn  py-2 px-2 float-end Montserrat fw-500"
                  style={{ color: "#000000CC" }}
                  onClick={() => {
                    setShow({ popup: true, updateData: null });
                  }}
                >
                  <img src="/images/plus-circle.svg" alt="" /> Add Staff
                </button>
              </div>
              {staffData?.length != 0 ? (
                staffData?.map((item, i) => {
                  return (
                    <div className="col-md-6 col-12 px-3 mt-3 " key={item?.id}>
                      <div className="row staff-box position-relative Montserrat">
                        <StaffCard data={item} />
                        <img
                          className="staff-action-icons-edit px-0"
                          src="/images/EditNewIcon.svg"
                          alt=""
                          onClick={() => {
                            setShow({ popup: true, updateData: item });
                          }}
                        />
                        <img
                          className="staff-action-icons-delete px-0"
                          src="/images/delete2.svg"
                          alt=""
                          onClick={() => {
                            deleteStaffMember(item?.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="fs-5 fw-600 text-center">No record found</span>
              )}
            </>
          )}
        </div>
      </div>
      <Popup
        show={show?.popup}
        onHide={() => setShow({ popup: false, updateData: null })}
        venueflag={1}
      >
        <AddUpdateStaff
          onHide={() => setShow({ popup: false, updateData: null })}
          GetStaffMembers={GetStaffMembers}
          show={show}
          venueID={venueID}
        />
      </Popup>
    </Fragment>
  );
}
