import React, { useEffect, useState } from "react";
import { GetPlayerRole } from "../constants";
import $ from "jquery";
import { configure } from "@testing-library/react";
import { GetAPI, PostAPI } from "../Utils/APIConnector";

export default function LiveMatchPalyers_1({ nextStep }) {
  const MatchID = parseInt(window.localStorage.getItem("LiveMatchDetailID"));
  const [joinMatchPackages, setjoinMatchPackagesData] = useState([]);
  const [AllMatchesData, setAllMatchesData] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [lengthList, SetLengthList] = useState([]);
  const [availableCount, setavailableCount] = useState(0);

  var playersDetails = [
    { Name: "Jane Cooper", Phone: 1111111111, images: "Batsmen" },
    { Name: "Leslie Alexander", Phone: 1111111111, images: "AllRounder" },
    { Name: "Bessie Cooper", Phone: 1111111111, images: "Bowler" },
    { Name: "Albert Flores", Phone: 1111111111, images: "WicketKeeper" },
  ];
  var list = [];
  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  const [showExitPrompt, setShowExitPrompt] = useState(true);

  const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  };
  useEffect(async () => {
    const userType = window.localStorage.getItem("UserType");

    if (userType === null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    initBeforeUnLoad(showExitPrompt);
    await GetAPI(
      "/Matches/DetailsOfCreatedMatch/" + parseInt(matchDetailsID)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.matchDetails;
        setavailableCount(result.availableCount);
        setAllMatchesData(matchList[0].matchesPackage.numberOfExistingPlayers);
        // setAllMatchesData(3);
        setjoinMatchPackagesData(matchList[0].joinMatchPackages);
        // window.localStorage.removeItem("LiveMatchDetailID");
        if (result.availableCount === 0) {
          SetLengthList(list);
        } else {
          for (
            var i = 0;
            i < result.availableCount;
            // i < 3;
            i++
          ) {
            list.push({
              Fullname: "",
              Mobilenumber: "",
              PlayerRoleID: "",
            });
          }
          SetLengthList(list);
        }
      }
    });
  }, [showExitPrompt]);
  var pattern = new RegExp(/^[0-9\b]+$/);

  const handleInputPhoneChange = (e, index) => {
    if (e.target.value.length === 10) {
      var valueArrs = joinMatchPackages.map(function (item) {
        return item.mobileNumber;
      });
      var isDuplicates = valueArrs.indexOf(e.target.value);
      if (isDuplicates > -1) {
        if ($(`#phone${index}`).find("span").hasClass("d-none")) {
          $(`#phone${index}`).find("span").removeClass("d-none");
          $(`#phone${index}`).find("span").text("Already Registered");
        }
      } else {
        $(`#phone${index}`).find("span").addClass("d-none");
        $(`#phone${index}`).find("input").val();
      }
    }
  };
  var playerObj = new Object();
  playerObj.playerList = new Array();
  const Continue = async () => {
    if (availableCount === 0) {
      nextStep(1);
    } else {
      var obj = new Object();
      var totalCount = 0;
      $(".newPlayersDetails").each(function () {
        var countValue = 0;
        // var indexValue = $(this).find(".newplayerName").attr("data-index");
        var newplayerName = $(this).find(".newplayerName").val();
        var newplayerPhoneNo = $(this).find(".newplayerPhoneNo").val();
        var roleTypeID = $(this).find("input:radio:checked", $(this)).val();
        if (
          roleTypeID === undefined ||
          roleTypeID === "" ||
          roleTypeID === null ||
          roleTypeID === 0
        ) {
          $(this).find(".validationRadio").find("span").removeClass("d-none");
        } else {
          ++countValue;
          $(this).find(".validationRadio").find("span").addClass("d-none");
        }
        // if (
        //   newplayerPhoneNo === null ||
        //   newplayerPhoneNo === "" ||
        //   newplayerPhoneNo.length < 10
        // ) {
        //   $(this)
        //     .find(".newplayerPhoneNo")
        //     .parent()
        //     .find("span")
        //     .removeClass("d-none");
        //   $(this)
        //     .find(".newplayerPhoneNo")
        //     .parent()
        //     .find("span")
        //     .text("Required");
        // } else {
        //   ++countValue;
        //   $(this)
        //     .find(".newplayerPhoneNo")
        //     .parent()
        //     .find("span")
        //     .addClass("d-none");
        // }
        if (newplayerName === null || newplayerName === "") {
          $(this)
            .find(".newplayerName")
            .parent()
            .find("span")
            .removeClass("d-none");
          $(this).find(".newplayerName").parent().find("span").text("Required");
        } else {
          ++countValue;
          $(this)
            .find(".newplayerName")
            .parent()
            .find("span")
            .addClass("d-none");
        }
        if (countValue === 2) {
          ++totalCount;
          list.push({
            Fullname: newplayerName,
            Mobilenumber: newplayerPhoneNo,
            PlayerRoleID: parseInt(roleTypeID),
          });
          var value = new Object();
          value.Fullname = newplayerName;
          value.Mobilenumber = newplayerPhoneNo;
          value.PlayerRoleID = parseInt(roleTypeID);
          playerObj.playerList.push(value);
          setInputList(list);
        }
      });
      // var valueArr = list.map(function (item) {
      //   return item.Mobilenumber;
      // });
      // var isDuplicate = valueArr.some(function (item, idx) {
      //   return valueArr.indexOf(item) != idx;
      // });

      // if (!isDuplicate) {
      $(".sameMobileNo").addClass("d-none");
      if (AllMatchesData === totalCount) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        var raw = JSON.stringify({
          ExistingPlayers: playerObj.playerList,
          MatchId: parseInt(matchDetailsID),
        });

        await PostAPI("/creatematches/AddExistingPlayers/", raw).then(
          (result) => {
            if (result === undefined || result === "") {
              alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              if (result.status === "Success") {
                nextStep(1);
              } else {
                alert(result.Message);
              }
            }
          }
        );
      }
      // } else {
      //   $(".sameMobileNo").removeClass("d-none");
      // }
    }
  };

  const nextPage = () => {
    nextStep(1);
  };
  return (
    <div>
      <div
        className="row mb-3 py-3"
        style={{ background: "#EDEDED", marginTop: "-10px" }}
      >
        <div
          className="centerAlign"
          style={{ fontWeight: "600", fontSize: "19px" }}
        >
          MATCH PLAYERS
        </div>
      </div>
      {/* <div className="row my-3 text-end">
        <div
          className="col-12 "
          style={{ fontWeight: "500", fontSize: "14px" }}
        >
          Add Players
          <img
            className="px-2"
            src="./images/plusIcon.png"
            alt=""
            onClick={handleAddClick}
          />
        </div>
      </div> */}
      <div className="my-3 h4 text-center">
        Please enter details of your existing players. These details will be
        used in score card.
      </div>
      <div className="LiveContainer py-2">
        <div className="row px-3">
          <div className="col-5">Name</div>
          <div className="col-5">Phone No.</div>
        </div>
        {joinMatchPackages.map((data, i) => {
          return (
            <>
              <div key={i} className=" form-group row px-3 my-2">
                <div className=" col-5 justify-content-start ">
                  <input
                    disabled
                    className="form-control compName"
                    name="Name"
                    value={data.fullName}
                    // onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className=" col-5 justify-content-start ">
                  <input
                    disabled
                    className="form-control Phone"
                    name="Phone"
                    value={data.mobileNumber}
                    // onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-2 centerAlign">
                  {data.playerRoleID != null ? (
                    <>
                      <img
                        src={`/images/PlayerType/${GetPlayerRole(
                          data.playerRoleID
                        )
                          .replace("(", "")
                          .replace(")", "")
                          .replace(/\s/g, "")}.svg`}
                        className="img-fluid"
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {/* {inputList.length !== 1 && (
                    <img
                      className="me-2"
                      src="./images/minusIcon.png"
                      alt=""
                      onClick={() => handleRemoveClick(i)}
                    />
                  )}
                  {data.images != "" ? (
                    <img
                      src={`/images/PlayerType/${data.images}.svg`}
                      className="img-fluid"
                    />
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </>
          );
        })}

        {lengthList.map((data, i) => {
          return (
            <>
              <div className="newPlayersDetails">
                <div key={i} className=" form-group row px-3 my-2">
                  <div
                    className=" col-5 justify-content-start "
                    id={`name${i}`}
                  >
                    <input
                      className="form-control newplayerName"
                      name="Fullname"
                      // onKeyUp={(e) => handleInputNameChange(e, i)}
                      data-index={i}
                      autoComplete="none"
                    />
                    <span
                      className="d-none"
                      style={{ color: "red", float: "right" }}
                    >
                      Required
                    </span>
                  </div>
                  <div
                    className=" col-5 justify-content-start "
                    id={`phone${i}`}
                  >
                    <input
                      type="number"
                      inputMode="numeric"
                      className="form-control Phone newplayerPhoneNo"
                      name="Mobilenumber"
                      onKeyUp={(e) => handleInputPhoneChange(e, i)}
                      autoComplete="none"
                      data-index={i}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                    />
                    {/* <span
                      className="d-none"
                      style={{ color: "red", float: "right" }}
                    >
                      Required
                    </span> */}
                  </div>
                  <div className="col-2 centerAlign">
                    {/* <img
                    src={`/images/PlayerType/${data.images}.svg`}
                    className="img-fluid"
                  /> */}
                    {/* {inputList.length !== 1 && (
                    <img
                      className="me-2"
                      src="./images/minusIcon.png"
                      alt=""
                      onClick={() => handleRemoveClick(i)}
                    />
                  )}
                  {data.images != "" ? (
                    <img
                      src={`/images/PlayerType/${data.images}.svg`}
                      className="img-fluid"
                    />
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>

                <div className="row centerAlign roleTypeCheck" data-index={i}>
                  <div className="col-3 centerAlign">
                    <input
                      type="radio"
                      id="roleType"
                      value="1"
                      className="form-check-input me-1"
                      name={`roleType${i}`}
                    />
                    <label>
                      <div className="">
                        <img
                          src="/images/PlayerType/Batsmen.svg"
                          className="img-fluid"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-3 centerAlign">
                    <input
                      type="radio"
                      id="roleType"
                      value="2"
                      className="form-check-input me-1"
                      name={`roleType${i}`}
                    />
                    <label>
                      <div className="">
                        <img
                          src="/images/PlayerType/Bowler.svg"
                          className="img-fluid"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-3 centerAlign">
                    <input
                      type="radio"
                      id="roleType"
                      value="3"
                      className="form-check-input me-1"
                      name={`roleType${i}`}
                    />
                    <label>
                      <div className="">
                        <img
                          src="/images/PlayerType/AllRounder.svg"
                          className="img-fluid"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-3 centerAlign">
                    <input
                      type="radio"
                      id="roleType"
                      value="4"
                      className="form-check-input me-1"
                      name={`roleType${i}`}
                    />
                    <label>
                      <div className="">
                        <img
                          src="/images/PlayerType/WicketKeeper.svg"
                          className="img-fluid"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="row text-end validationRadio">
                    <span className="d-none" style={{ color: "red" }}>
                      Required
                    </span>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <div className="row my-2 sameMobileNo d-none">
          <span className="centerAlign" style={{ color: "red" }}>
            Mobile No. should not be same
          </span>
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <hr />
        <div className="col-12 text-end">
          <img src="/images/Fill_NextArrow.svg" onClick={Continue} />
        </div>
      </div>

      <div
        className="col-12 p-0 centerAlign"
        // style={{
        //   justifyContent: "flex-end",
        //   display: "flex",
        //   alignItems: "flex-end",
        // }}
      >
        {/* <button className="loginbtn" onClick={nextPage}>
          Next Page
        </button> */}
        {/* <img
              src="/images/Fill_NextArrow.svg"
              onClick={Continue}
            /> */}
      </div>
    </div>
  );
}
