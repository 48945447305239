import { useState, useEffect } from "react";
import $ from "jquery";
import { ConvertDateTime } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomGetAPI } from "../Utils/APIConnector";

export const MatchVerified = ({
  nextStep,
  handleChange,
  values,
  handleTotalPlayer,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  const timeAndDate = values.MatchDate + " " + values.MatchTime;
  const navigate = useNavigate();
  const location = useLocation();
  const redirectToMatches = () => {
    window.location = "/created-matches";
  };
  const printOrder = () => {
    window.print();
  };
  const booknow = (e, id) => {
    CustomGetAPI("/Venue/GetvenuecitybyID/" + id).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "success") {
          navigate(
            `/venue-search/${result.cityname.toLocaleLowerCase()}/${result.venuename
              .split(" ")
              ?.join("-")}`
          );
        }
      }
    });
    // window.location = "/venue-search/ahmedabad/" + id;
  };
  return (
    <>
      <div className="centerAlign printRecieptTitle ">
        <div class="" style={{ width: "50%" }}>
          <img src="/images/cricketFlash.svg" class="img-fluid ms-2" /> <hr />
        </div>
      </div>
      {values.Status === "captured" ? (
        <div className="confirmedPayment">
          <form>
            <div className="paymentTitle centerAlign p-2 mt-1">
              <div className="">
                <img
                  src="/images/Payment Status/Successful.svg"
                  className="img-fluid"
                />
              </div>
              <div className="" style={{ paddingLeft: "5px" }}>
                <h4 className="paymentTitle mb-0" style={{ color: "#339900" }}>
                  <b>Confirmed</b>
                </h4>
              </div>
            </div>
            <div className="profileformControl mt-2">
              <div className="row text-left">
                <div className="col-5">
                  <label class="form-label textLabel">Match Name : </label>
                </div>
                <div className="col-7">
                  <lable className="dataText">{values.MatchName}</lable>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-5">
                  <label class="form-label textLabel">Match Time : </label>
                </div>
                <div className="col-7">
                  <lable className="dataText">
                    {ConvertDateTime(timeAndDate)}
                  </lable>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-5">
                  <label class="form-label textLabel">Ground Name : </label>
                </div>
                <div className="col-7">
                  <lable className="dataText">{values.NameOfGround}</lable>
                </div>
              </div>
              <div
                className=" text-center centerAlign mt-3 p-2 text-center"
                style={{ backgroundColor: "white" }}
              >
                <label class="textLabel" style={{ fontSize: "19px" }}>
                  Total Fees Paid{" "}
                </label>
              </div>
              <div className="text-center pt-2">
                <lable className="dataText" style={{ fontSize: "19px" }}>
                  ₹{" "}
                  {parseInt(values.currentPlayer) * parseInt(values.MatchFees)}
                </lable>
              </div>
              <div className="textLabel row mt-3" style={{ fontSize: "14px" }}>
                {/*<div className="col-12">
                  <label class="form-label textLabel">Order Id : </label>{" "}
                  {values.OrderID}
                </div> */}
                <div className="col-12" style={{ textAlign: "center" }}>
                  <label
                    class="form-label textLabel"
                    style={{ fontSize: "11px" }}
                  >
                    Your payment confirmation number is{" "}
                    <label className="dataText" style={{ fontSize: "11px" }}>
                      {values.PaymentID}
                    </label>
                  </label>
                </div>
              </div>
              <div className="profileformControl text-center">
                <span
                  className=""
                  style={{ fontSize: "14px", color: "#A51E22" }}
                >
                  *We will charge{" "}
                  <b>{process.env.REACT_APP_CUSTOM_PLATFORM_FEE}</b> platform
                  fees and <b>{process.env.REACT_APP_CUSTOM_REFUND_FEE}</b> fees
                  will be refunded to your payment source after match is
                  completed.
                </span>
              </div>
            </div>
          </form>
        </div>
      ) : values.Status === "failed" ? (
        <div className="failedPayment">
          <form>
            <div className="paymentTitle centerAlign p-2 mt-1">
              <div className="">
                <img
                  src="/images/Payment Status/Failed.svg"
                  className="img-fluid"
                />
              </div>
              <div className="" style={{ paddingLeft: "5px" }}>
                <h4 className="paymentTitle mb-0" style={{ color: "#CD0D0D" }}>
                  <b>Failed</b>
                </h4>
              </div>
            </div>
            <div className="profileformControl mt-2">
              <div className="row text-left">
                <div className="col-6">
                  <label class="form-label textLabel">Match Name : </label>
                </div>
                <div className="col-6">
                  <lable className="dataText">{values.MatchName}</lable>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-6">
                  <label class="form-label textLabel">Match time : </label>
                </div>
                <div className="col-6">
                  <lable className="dataText">
                    {ConvertDateTime(timeAndDate)}
                  </lable>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-6">
                  <label class="form-label textLabel">Ground Name : </label>
                </div>
                <div className="col-6">
                  <lable className="dataText">{values.NameOfGround}</lable>
                </div>
              </div>
              <div
                className=" text-center centerAlign mt-3 p-2 text-center"
                style={{ backgroundColor: "white" }}
              >
                <label class="textLabel" style={{ fontSize: "19px" }}>
                  Total Fees Paid{" "}
                </label>
              </div>
              <div className="text-center pt-2">
                <lable className="dataText" style={{ fontSize: "19px" }}>
                  ₹{" "}
                  {parseInt(values.currentPlayer) * parseInt(values.MatchFees)}
                </lable>
              </div>
              <div className="textLabel row mt-3" style={{ fontSize: "14px" }}>
                {/* <div className="col-12">
                  <label class="form-label textLabel">Order Id : </label>{" "}
                  {values.OrderID}
                </div> */}
                <div className="col-12">
                  <label class="form-label textLabel">
                    Your payment confirmation number is{" "}
                  </label>{" "}
                  {values.PaymentID}
                </div>
              </div>
              <div className="profileformControl text-center">
                <span
                  className=""
                  style={{ fontSize: "14px", color: "#A51E22" }}
                >
                  *We will charge{" "}
                  <b>{process.env.REACT_APP_CUSTOM_PLATFORM_FEE}</b> platform
                  fees and <b>{process.env.REACT_APP_CUSTOM_REFUND_FEE}</b> fees
                  will be refunded to your payment source after match is
                  completed.
                </span>
              </div>
              <div className="profileformControl text-center pt-0">
                <span className="" style={{ color: "red" }}>
                  Unfortunetly, your payment failed.
                </span>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pendingPayment">
          <form>
            <div className="paymentTitle centerAlign p-2 mt-1">
              <div className="">
                <img
                  src="/images/Payment Status/Pending.svg"
                  className="img-fluid"
                />
              </div>
              <div className="" style={{ paddingLeft: "5px" }}>
                <h4 className="paymentTitle mb-0" style={{ color: "#FFB038" }}>
                  <b>Pending</b>
                </h4>
              </div>
            </div>
            <div className="profileformControl mt-2">
              <div className="row text-left">
                <div className="col-6">
                  <label class="form-label textLabel">Match Name : </label>
                </div>
                <div className="col-6">
                  <lable className="dataText">{values.MatchName}</lable>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-6">
                  <label class="form-label textLabel">Match time : </label>
                </div>
                <div className="col-6">
                  <lable className="dataText">
                    {ConvertDateTime(timeAndDate)}
                  </lable>
                </div>
              </div>
              <div className="row text-left">
                <div className="col-6">
                  <label class="form-label textLabel">Ground Name : </label>
                </div>
                <div className="col-6">
                  <lable className="dataText">{values.NameOfGround}</lable>
                </div>
              </div>
              <div
                className=" text-center centerAlign mt-3 p-2 text-center"
                style={{ backgroundColor: "white" }}
              >
                <label class="textLabel" style={{ fontSize: "19px" }}>
                  Total Fees Paid{" "}
                </label>
              </div>
              <div className="text-center pt-2">
                <lable className="dataText" style={{ fontSize: "19px" }}>
                  ₹{" "}
                  {parseInt(values.currentPlayer) * parseInt(values.MatchFees)}
                </lable>
              </div>
              <div className="textLabel row mt-3" style={{ fontSize: "14px" }}>
                {/* <div className="col-12">
                  <label class="form-label textLabel">Order Id : </label>{" "}
                  {values.OrderID}
                </div> */}
                <div className="col-12">
                  <label class="form-label textLabel">
                    Your payment confirmation number is{" "}
                  </label>{" "}
                  {values.PaymentID}
                </div>
              </div>
              <div className="profileformControl text-center">
                <span
                  className=""
                  style={{ fontSize: "14px", color: "#A51E22" }}
                >
                  *We will charge{" "}
                  <b>{process.env.REACT_APP_CUSTOM_PLATFORM_FEE}</b> platform
                  fees and <b>{process.env.REACT_APP_CUSTOM_REFUND_FEE}</b> fees
                  will be refunded to your payment source after match is
                  completed.
                </span>
              </div>
              <div className="profileformControl text-center pt-0">
                <span className="" style={{ color: "#FFB038" }}>
                  Payment will take 1-2 working days to process.
                </span>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="row">
        <div className="col-md-6 col-10 m-auto">
          <div className=" mt-3">
            <button
              type="submit"
              className="loginbtn w-100"
              onClick={redirectToMatches}
            >
              My Created Matches
            </button>
          </div>
        </div>
        <div className="col-md-6 col-10 m-auto">
          <div className=" mt-3">
            <button
              type="submit"
              className="loginbtn w-100"
              onClick={() => {
                navigate(
                  `/venue-search/${values?.SelectedCityName}/sports/all`,
                  { state: { Date: values?.MatchDate } }
                );
              }}
              style={{ background: "#339900" }}
            >
              Book Venue
            </button>
          </div>
        </div>
        <div className="col-12   mt-2 centerAlign">
          <a
            className=""
            onClick={printOrder}
            style={{ color: "#3c3c40", fontWeight: 600 }}
          >
            Print Receipt
          </a>
        </div>
        {/* {values.MatchType === "Box" ? (
          <>
            <div className="col-12   mt-2 text-center">
              If you haven't booked the venue yet, don't delay -
              <a
                className=""
                onClick={(e) => {
                  booknow(e, values.GroundName);
                }}
                style={{ color: "#3c3c40", fontWeight: 600 }}
              >
                Book Now!
              </a>
            </div>
          </>
        ) : null} */}
      </div>
    </>
  );
};
