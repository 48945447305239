import { useState, useEffect } from "react";
import $ from "jquery";
import { minDate, maxDate } from "../constants";
import { Button, Modal } from "react-bootstrap";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import { checkTime } from "../Utils/common";

export const MatchInfo = ({
  prevStep,
  nextStep,
  handleChange,
  values,
  validatedInputs,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  const [show, SetShow] = useState(false);
  const userid = window.localStorage.getItem("userid");
  const [listofGrounds, setlistofGrounds] = useState([]);
  const [listofCity, setlistofCity] = useState([]);
  const [selectedCity, setselectedCity] = useState(0);

  const Continue = (e) => {
    var isValidated = true;
    if (values.MatchName === "" || parseInt(values.MatchName) === 0) {
      isValidated = checkValidation("MatchName", values.MatchName);
    }
    if (values.GroundName === "" || parseInt(values.GroundName) === "0") {
      isValidated = checkValidation("MatchGround", values.GroundName);
    }

    var validMatchdate = $("#MatchDate").find("#datefield").val();
    validatedInputs("MatchDate", validMatchdate);

    var validmatchtime = $("#MatchTime").find("#mtime").val();
    validatedInputs("MatchTime", validmatchtime);
    if (values.TypeOfMatch === "" || parseInt(values.TypeOfMatch) === 0) {
      isValidated = checkValidation("MatchType", values.TypeOfMatch);
    }
    if (values.TotalOvers === "" || parseInt(values.TotalOvers) === 0) {
      isValidated = checkValidation("TotalOvers", values.TotalOvers);
    }
    var totalfess = $("#minmatchfees").find("#fees").val();
    if (parseInt(totalfess) < 100) {
      $("#minmatchfees")
        .find(".requiredValidation")
        .text("Value should be greater than 100");
      isValidated = false;
    } else {
      validatedInputs("minmatchfees", totalfess);
    }
    if (values.MatchDate === new Date().toISOString().split("T")[0]) {
      if (checkTime(values.MatchTime)) {
        $(".time-error").text("");
      } else {
        $(".time-error").text(
          "Please select a time that is at least 1 hour from the current time."
        );
        isValidated = false;
      }
    }
    if (values.NameOfGround === "Other" && values.otherGroundAddress === "") {
      isValidated = checkValidation(
        "otherGroundAddress",
        values.otherGroundAddress
      );
    }
    if (isValidated) {
      e.preventDefault();
      nextStep();
    }
  };
  const checkValidation = (divID, Value) => {
    if (Value === "" || parseInt(Value) === 0) {
      $("#" + divID)
        .find(".requiredValidation")
        .text("Required");
      return false;
    } else {
      $("#" + divID)
        .find(".requiredValidation")
        .text("");
    }
  };
  const changeBackground = () => {
    $(".showdiv").removeClass("d-none");
  };

  const handleModal = () => {
    SetShow(!show);
  };
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  const GroundNameHandler = (e) => {
    validatedInputs("GroundName", e.target.value);
    validatedInputs(
      "NameOfGround",
      e.target.options[e.target.selectedIndex].text
    );
  };

  const DefultDateSet = () => {
    var dateindex = 6;
    var d = new Date();
    var dayIndex = 6;
    d.setDate(d.getDate() + ((dayIndex - 1 - d.getDay() + 7) % 7) + 1);
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yyyy = d.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var today = yyyy + "-" + mm + "-" + dd;

    return today;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const citychange = (e) => {
    var cityid = parseInt(e.target.value, 10);
    validatedInputs(
      "SelectedCityName",
      e.target.options[e.target.selectedIndex].text
    );
    setselectedCity();
    CustomGetAPI(
      `/venue/GetAllVenuesBySport/${userid}/${values.MatchType}/${cityid}`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // console.log(result);
        setlistofGrounds(result.ListOfGround);
        setlistofCity(result.Allcities);
        setselectedCity(result.City);
      }
    });
  };

  useEffect(async () => {
    var tempstr = null;
    await CustomGetAPI(
      `/venue/GetAllVenuesBySport/${userid}/${values.MatchType}/0`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // console.log(result);
        setlistofGrounds(result.ListOfGround);
        setlistofCity(result.Allcities);
        setselectedCity(result.City);
        validatedInputs(
          "SelectedCityName",
          result.Allcities.filter((items) => items?.value === result.City)[0]
            ?.label
        );
      }
    });
  }, []);
  return (
    <>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js"></script>

      <div className="px-2">
        <div className="row mt-2 ">
          <div className="p-2 text-center">
            <label style={{ color: "#A51E22" }}>
              <h4>
                <b>Match Details</b>
              </h4>
            </label>
            <hr />
          </div>
          <div className="col-12">
            <form>
              <div id="MatchCity" className="mb-3 text-left">
                <label className="form-label textLabel">In which city?</label>
                <div className="col-lg-6 col-md-6">
                  <select
                    className="form-select "
                    id="MatchCityID"
                    aria-label="Select Ground"
                    value={selectedCity}
                    onChange={(e) => citychange(e)}
                  >
                    {selectedCity === 0 ? (
                      <option
                        value="0"
                        defaultValue={true}
                        style={{ backgroundColor: "black !important" }}
                      >
                        City
                      </option>
                    ) : null}

                    {listofCity.map((data, i) => {
                      var returnData;
                      returnData = (
                        <option value={data.value}>{data.label}</option>
                      );
                      return returnData;
                    })}
                  </select>
                </div>
              </div>
              <div id="MatchGround" className="mb-3 text-left">
                <label className="form-label textLabel">On which ground?</label>
                <div className="col-lg-6 col-md-6">
                  <select
                    className="form-select "
                    id="MatchGroundID"
                    aria-label="Select Ground"
                    value={values.GroundName}
                    onChange={GroundNameHandler}
                  >
                    <option
                      value="0"
                      defaultValue={true}
                      style={{ backgroundColor: "black !important" }}
                    >
                      Ground
                    </option>
                    {listofGrounds.length === 0 && (
                      <option value="" disabled>
                        No ground found
                      </option>
                    )}
                    {listofGrounds.map((data, i) => {
                      return (
                        <option key={i} value={data.groundId}>
                          {data.groundFullName}
                        </option>
                      );
                    })}
                    <option value="other">Other</option>
                  </select>
                  <span className="requiredValidation validationText">{}</span>
                </div>
              </div>
              <div id="otherGroundAddress">
                {values.GroundName === "other" && (
                  <div className="my-1">
                    <label className="form-label textLabel">
                      Complete Address:
                    </label>
                    <input
                      type="text"
                      className="form-control profileformControl "
                      placeholder="Enter full address of ground"
                      id="otherGroundAddress"
                      name="otherGroundAddress"
                      value={values.otherGroundAddress}
                      onChange={handleChange("otherGroundAddress")}
                    />
                    <span className="requiredValidation validationText">
                      {}
                    </span>
                  </div>
                )}
              </div>
              <div className="row mb-3 ">
                <label className="form-label textLabel">
                  On which Date and Time?
                </label>
                <div id="MatchDate" className="col-6">
                  <input
                    type="date"
                    id="datefield"
                    className="form-control profileformControl "
                    value={
                      values.MatchDate === ""
                        ? DefultDateSet()
                        : values.MatchDate
                    }
                    min={minDate()}
                    onChange={handleChange("MatchDate")}
                    placeholder="0"
                    max={maxDate()}
                  />
                  <span className="requiredValidation validationText">{}</span>
                </div>
                <div id="MatchTime" className="col-6">
                  <input
                    id="mtime"
                    type="time"
                    className="form-control profileformControl "
                    value={values.MatchTime === "" ? "07:00" : values.MatchTime}
                    onChange={handleChange("MatchTime")}
                    placeholder="0"
                    min={getCurrentTime()}
                  />
                  <span className="requiredValidation validationText">{}</span>
                  <small className="text-danger time-error"></small>
                </div>
              </div>
              <div id="MatchType" className="mb-3  ">
                <label className="form-label textLabel">Types of Cricket</label>

                <div className="d-flex profileformControl form-control">
                  <div className="col-6 textLabel">
                    <input
                      type="radio"
                      id="maleCheck"
                      className="form-check-input me-1"
                      value="Tennis"
                      name="TypeOfMatch"
                      checked={values.TypeOfMatch === "Tennis"}
                      defaultChecked
                      onChange={handleChange("TypeOfMatch")}
                    />
                    <label>Tennis</label>
                  </div>
                  <div className="col-6 textLabel">
                    <input
                      type="radio"
                      id="femaleCheck"
                      className="form-check-input me-1"
                      value="Season"
                      name="TypeOfMatch"
                      checked={values.TypeOfMatch === "Season"}
                      onChange={handleChange("TypeOfMatch")}
                    />
                    <label>Season</label>
                  </div>
                </div>
                <span className=" mt-2 requiredValidation validationText">
                  {}
                </span>
              </div>
              <div className="mb-3 d-flex">
                <div className="col-6" id="TotalOvers">
                  <label className="form-label textLabel">Total Overs</label>
                  <div className="col-6">
                    <input
                      type="number"
                      inputMode="numeric"
                      className="form-control profileformControl "
                      value={values.TotalOvers}
                      onChange={handleChange("TotalOvers")}
                      placeholder="0"
                    />
                    <span className="requiredValidation validationText">
                      {}
                    </span>
                  </div>
                </div>
                <div className="col-6" id="MatchFees">
                  <label className="form-label textLabel">Match Fees</label>
                  <img
                    className="imagehover"
                    src="./images/questionmark.svg"
                    style={{ width: "15px", marginLeft: "7px" }}
                    onClick={handleModal}
                  />

                  <div className="col-6" id="minmatchfees">
                    <input
                      id="fees"
                      type="number"
                      inputMode="numeric"
                      className="form-control profileformControl "
                      value={values.MatchFees}
                      onChange={handleChange("MatchFees")}
                      placeholder="₹ 100"
                      min={"100"}
                    />
                    <span
                      className="requiredValidation validationText"
                      style={{ fontSize: "12px" }}
                    >
                      {}
                    </span>
                  </div>
                </div>
              </div>

              <div className="maindiv">
                <Modal show={show} onHide={handleModal}>
                  <Modal.Body>
                    <div className="modalcalss">
                      Upon successful completion of the match, a refund of{" "}
                      <span className="fw-bold">
                        {process.env.REACT_APP_CUSTOM_REFUND_FEE}
                      </span>{" "}
                      of the match fees will be refunded to your original
                      payment method. However, please note that a{" "}
                      <span className="fw-bold">
                        {process.env.REACT_APP_CUSTOM_PLATFORM_FEE}
                      </span>{" "}
                      platform fee will be deducted from the total charged
                      amount.
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <hr />
          {/* <div className="row">
            <div className="col-6 ">
              <a onClick={Previous}>
                <img src="/images/Fill_BackArrow.svg" />
              </a>
            </div>
            <div
              className="col-6"
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <a onClick={Continue}>
                <img src="/images/Fill_NextArrow.svg" />
              </a>
            </div>
          </div> */}
          <div className="row mt-4 d-flex justify-content-center">
            <div className="col-md-3 col-4">
              <button onClick={Previous} className="btn btn-gray">
                Previous
              </button>
            </div>
            <div className="col-md-3 col-4">
              <button onClick={Continue} className="btn btn-danger">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
