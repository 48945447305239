import React, { useEffect, useState } from "react";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import VenueList from "./VenueList";
import VenueOwnerDashboard from "./VenueOwnerDashboard";
import { Dropdown } from "react-bootstrap";
import VenueOwnerOfflineBooking from "../Popup/VenueOwnerOfflineBooking";
import { formatDateToInput } from "../Utils/common";
import { useNavigate } from "react-router-dom";
import AdminVenueList from "./AdminVenueList";
import VenueOwnerDashbooardBooking from "./VenueOwnerDashbooardBooking";

export default function VenueOwnerHome() {
  const [venuesData, setVenuesData] = useState();
  const [selectedVenue, setSelectedVenue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isHomePage, setIsHomePage] = useState(true);
  const userID = localStorage.getItem("userid");
  const [venueDetails, setVenueDetails] = useState();
  const [activeCourt, setActiveCourt] = useState("All");
  const [activeCourtID, setActiveCourtId] = useState(0);
  const [activeCalendarID, setCalendarCourtId] = useState(0);
  const [dateid, setDateid] = useState(0);
  const [startDate, setStartDate] = useState(formatDateToInput(new Date()));
  const [endDate, setEndDate] = useState(formatDateToInput(new Date()));
  const UserType = localStorage.getItem("UserType");
  const navigate = useNavigate();
  const [showoffline, setShowoffline] = useState({
    popup: false,
    data: "",
    title: "",
    date: new Date(),
  });
  const [isMainPage, setIsMainPage] = useState(true);

  useEffect(() => {
    if (selectedVenue != 0) {
      const raw = JSON.stringify({
        venueId: selectedVenue,
        dateid: dateid,
        CourtId: activeCourtID,
        CalenderId: activeCalendarID,
        Startdate: startDate,
        Enddate: endDate,
      });
      CustomPostAPI("/venue/Dashboard", raw).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result.status === "Success") {
            setIsLoading(false);
            setVenueDetails(result?.response);
          }
        }
      });
    }
  }, [
    selectedVenue,
    dateid,
    activeCourtID,
    activeCalendarID,
    startDate,
    endDate,
    showoffline?.popup,
  ]);

  const handleSelectCourt = (index, id) => {
    if (index >= 0 && index < venueDetails?.surfaces?.length) {
      setActiveCourt(venueDetails?.surfaces[index]?.courtName);
      setActiveCourtId(venueDetails?.surfaces[index]?.id);
    } else {
      setActiveCourt("All");
      setActiveCourtId(0);
    }
  };
  useEffect(async () => {
    await CustomGetAPI(
      `/Venue/NewGetUserCreatedVenues/${userID ? userID : "0"}`
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setIsLoading(false);
        setVenuesData(result?.venues);
      }
    }, []);
  }, []);
  return (
    <React.Fragment>
      <div
        className={`row px-2 pt-3 ${
          isHomePage || UserType === "Staff" ? "" : "dashboardHeaderBg"
        }`}
      >
        <div className="col-1">
          <img
            src="/images/backArrow.svg"
            onClick={() => {
              if (!isMainPage) {
                setIsMainPage(true);
              } else {
                isHomePage ? (window.location = "/home") : setIsHomePage(true);
                setSelectedVenue(0);
              }
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        {isHomePage ? (
          <div className="col-11 ps-0 text-center">
            <h1 className="venueListHeader">Venue Lists</h1>
          </div>
        ) : (
          <>
            {UserType === "Staff" ? (
              <>
                <VenueOwnerDashbooardBooking
                  selectedVenue={selectedVenue}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  activeCourtID={activeCourtID}
                  showoffline={showoffline}
                />
              </>
            ) : (
              <div className="col-11 text-start pe-0">
                <div className="row mx-0 d-flex align-items-center ">
                  <div className="col-12">
                    <h1 className="venueListHeader">
                      {venueDetails?.venuename}
                    </h1>
                  </div>
                  <div className="col-7">
                    <Dropdown
                      className="custom-dropdown"
                      onSelect={handleSelectCourt}
                    >
                      <Dropdown.Toggle
                        as="div"
                        className="fw-semibold cursor-pointer"
                        style={{ color: "#a51e22" }}
                      >
                        {activeCourt ? activeCourt : "All"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom-dropdown-menu">
                        <Dropdown.Item id={0} active={activeCourt === "All"}>
                          All
                        </Dropdown.Item>
                        {venueDetails?.surfaces?.map((court, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={index}
                            id={court?.id}
                            active={court?.courtName === activeCourt}
                          >
                            {court?.courtName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-5 ">
                    <button
                      className="VenueOwnerBookOfflineBtn w-100 py-2"
                      onClick={(e) => {
                        setShowoffline({
                          date: new Date(),
                          popup: true,
                          data: "",
                          title: "Book Your Venue Offline",
                        });
                      }}
                    >
                      New Booking
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="row">
        {isHomePage ? (
          <>
            {UserType === "Admin" ? (
              <>
                <p
                  className="adminText cursor-pointer text-center pe-2 m-0"
                  style={{ color: "#a51e22" }}
                  onClick={() => navigate("/all-listed-venues")}
                >
                  <b> View All Venues</b>
                </p>
                <AdminVenueList
                  venuesData={venuesData}
                  setVenuesData={setVenuesData}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setIsHomePage={setIsHomePage}
                  setSelectedVenue={setSelectedVenue}
                />
              </>
            ) : (
              <>
                <VenueList
                  venuesData={venuesData}
                  setVenuesData={setVenuesData}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setIsHomePage={setIsHomePage}
                  setSelectedVenue={setSelectedVenue}
                />
              </>
            )}
          </>
        ) : (
          <>
            {UserType === "Staff" ? (
              <></>
            ) : (
              <VenueOwnerDashboard
                selectedVenue={selectedVenue}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setVenueDetails={setVenueDetails}
                venueDetails={venueDetails}
                setDateid={setDateid}
                dateid={dateid}
                activeCalendarID={activeCalendarID}
                setCalendarCourtId={setCalendarCourtId}
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                activeCourtID={activeCourtID}
                showoffline={showoffline}
                setIsMainPage={setIsMainPage}
                isMainPage={isMainPage}
              />
            )}
          </>
        )}
      </div>
      {showoffline?.popup && (
        <VenueOwnerOfflineBooking
          showoffline={showoffline}
          setShowoffline={setShowoffline}
          VenueId={selectedVenue}
        />
      )}
    </React.Fragment>
  );
}
