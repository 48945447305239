import * as React from "react";
import "../../css/common.css";
import { Card } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "../Utils/Loader";
import { ConvertDateTime, DBConvertDatetime } from "../constants";
import { GetAPI } from "../Utils/APIConnector";
import { useNavigate } from "react-router-dom";
export function JoinedMatches() {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [matchJoined, setMatchJoined] = useState([]);
  const [filterDatas, setfilterDatas] = useState([]);
  var userid = window.localStorage.getItem("userid");
  if (userid === null || userid === undefined) {
    userid = 0;
  }
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, SetIsLoading] = useState(true);
  var matches = [];
  useEffect(async () => {
    var UserID = window.localStorage.getItem("userid");
    if (isNaN(UserID) || UserID === null) {
      window.location = "/login";
    }
    await GetAPI("/Matches/MyJoinMatches/" + userid).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.myJoinMatches;
        for (var i = 0; i < matchList.length; i++) {
          matches.push(matchList[i]);
        }
        setTimeout(() => {
          SetIsLoading(false);
        }, 1000);
        setMatchJoined(matches);
        setfilterDatas(matches.reverse());
      }
    });
  }, [window.localStorage.getItem("userid")]);

  const closePopupHandler = () => {
    setShow(false);
  };
  const matchDetails = (matchID) => {
    window.localStorage.setItem("MatchDetailID", matchID);
    window.localStorage.setItem("RedirectURL", window.location.href);
    window.location = "/match-details";
  };

  const handleChange = (e) => {
    setValue(e);
    e = parseInt(e);
    const filter = matchJoined;
    var filterData = [];
    if (e === 0) {
      filterData.push(filter);
      setfilterDatas(filterData[0]);
    } else {
      for (let i = 0; i < filter.length; i++) {
        if (e === filter[i].status) {
          filterData.push(filter[i]);
        }
      }
      setfilterDatas(filterData);
    }
  };
  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={(e) => navigate("/home")}
                />
              </div>
            </div>
            <div className="Container mb-3">
              <Row className="mx-2">
                <h5 className="pt-1 text-center">
                  <b>Your Joined Matches</b>{" "}
                </h5>
                <hr className="mb-0" />

                <div className="col-12 text-end my-2 px-0 allMatcesSelect">
                  <select
                    className="ps-2"
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    <option value="0">All Matches</option>
                    <option value="1">Requested</option>
                    <option value="2">Approved</option>
                    <option value="3">Rejected</option>
                    <option value="4">Refunded</option>
                  </select>
                </div>

                {filterDatas.length === 0 ? (
                  <Card className="mt-3 matchListItem p-0 text-center">
                    <Card.Body className="p-0">
                      <div className="">
                        <div className="profileformControl small">
                          <b>No records found.</b>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  filterDatas.map((str, index) => {
                    var returData;
                    var statusOfMatch =
                      str.status === 1
                        ? "Requested"
                        : str.status === 2
                        ? "Approved"
                        : str.status === 3
                        ? "Rejected"
                        : str.status === 4
                        ? "Refunded"
                        : null;
                    var colorCode =
                      str.status === 2
                        ? "#D5F5E3"
                        : str.status === 3
                        ? "#FADBD8"
                        : str.status === 4
                        ? "#D6DBDF"
                        : "#E8E8E8";
                    returData = (
                      <Card
                        key={index}
                        className="mt-3 matchListItem p-0 "
                        data-item={str.id}
                        data-index={index}
                      >
                        <Card.Body className="p-0">
                          <div className="" style={{ background: colorCode }}>
                            <div className="p-3 small">
                              Your status for Match{" "}
                              <a
                                onClick={() => matchDetails(str.cricketMatchId)}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <b>
                                  {str.matchName} - {str.groundName}
                                </b>
                              </a>{" "}
                              at <b> {DBConvertDatetime(str.matchDate)}</b> is{" "}
                              <b>{statusOfMatch}</b>.
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );

                    return returData;
                  })
                )}
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
}
