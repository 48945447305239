import { React, useEffect, useState, useLayoutEffect } from "react";
import { Card } from "react-bootstrap";
import Heart from "react-heart";
import { useNavigate, useParams } from "react-router-dom";
import { GetAPI } from "../Utils/APIConnector";
import {
  CustomGetAPI,
  PostAPI,
  PutAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import Loader from "../Utils/Loader";

export default function SavedVenues() {
  const [active, setActive] = useState(false);
  const [savedVenuesData, setSavedVenuesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [venueData, setVenueData] = useState({});
  const navigation = useNavigate();
  const userID = localStorage.getItem("userid");
  // console.log(userID)
  const params = useParams();
  const goToVenueSearch = () => {
    navigation(-1);
  };
  const handleVenuePlace = (id) => {
    CustomGetAPI("/Venue/GetvenuecitybyID/" + id).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "success") {
          navigation(
            `/venue-search/${result.cityname.toLocaleLowerCase()}/${result.venuename
              .trim()
              .split(" ")
              ?.join("-")}`
          );
        }
      }
    });
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    await CustomGetAPI(
      "/Venue/GetvenuebyID/" + params?.id + "/" + (userID ? userID : 0)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setIsLoading(false);
        if (result?.status === "Success") {
          // console.log(result?.venue);
          setVenueData(result?.venue);
        }
      }
    });
  }

  useEffect(() => {
    fetchSavedVenues();
  }, []);
  const fetchSavedVenues = async () => {
    await GetAPI(`/Venue/GetSavedVenues`).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setIsLoading(false);
        // console.log(result);
        setSavedVenuesData(result.savedVenues);
      }
    });
  };

  const handleSavedVal = async (i, flag, id, event) => {
    event.stopPropagation();
    var list = [...savedVenuesData];
    list[i]["isSaved"] = !flag;
    setSavedVenuesData(list);
    var raw = JSON.stringify(!flag);
    await PostAPI(`/Venue/ManageSavedVenue/${id}`, raw).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result?.status === "Success") {
          fetchSavedVenues();
          // console.log(result);
        }
      }
    });
  };

  return (
    <>
      <div className="savedVenueContainer position-relative">
        <div className="row px-2">
          <div className="col-12">
            <img
              className=""
              src="/images/arrow1.svg"
              style={{ cursor: "pointer", position: "absolute" }}
              onClick={goToVenueSearch}
            />
            <div className="text-center">
              <span>
                <h4 className="text-center">
                  <b>Saved Venues</b>
                </h4>
              </span>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader mt="10%" />
        ) : (
          <>
            {savedVenuesData?.length != 0 ? (
              <>
                {savedVenuesData?.map((data, index) => {
                  return (
                    <>
                      <div className="venueCard">
                        <Card
                          className="mt-3"
                          style={{
                            borderRadius: "16px",
                            background: "white",
                            border: "none",
                            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
                          }}
                          onClick={() => {
                            handleVenuePlace(data?.id);
                          }}
                        >
                          <Card.Body>
                            <div className="row">
                              <div className="col-4 display-center">
                                {data?.venueImages?.map((images, i) => {
                                  if (i === 0) {
                                    return (
                                      <>
                                        <div className="col-12 px-0" key={i}>
                                          <img
                                            src={images}
                                            className="venuePhotos img-fluid w-100"
                                            // style={{height:'150px'}}
                                            alt="image"
                                          />
                                        </div>
                                      </>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                              <div className="col-8">
                                <div className="row">
                                  <div className="col-8">
                                    <span className="venueName cursorClass text-decoration-underline">
                                      {data?.name}
                                    </span>
                                  </div>
                                  <div className="col-4">
                                    <div
                                      className="venueIcons"
                                      style={{ position: "relative" }}
                                    >
                                      {/* <img
                                    src="/images/share.svg"
                                    alt="image"
                                    style={{
                                      float: "right",
                                      height: "30px",
                                      position: "absolute",
                                      top: "-5px",
                                      right: "3px",
                                      borderRadius: "50%",
                                      padding: "4px",
                                    }}
                                  /> */}
                                      {/* <Heart
                                        isActive={data?.isSaved}
                                        onClick={() =>
                                          handleSavedVal(
                                            index,
                                            data?.isSaved,
                                            data?.id
                                          )
                                        }
                                        style={{
                                          width: "2rem",
                                          float: "right",
                                          height: "30px",
                                          position: "absolute",
                                          top: "-5px",
                                          right: "14px",
                                          borderRadius: "50%",
                                          padding: "4px",
                                        }}
                                      /> */}
                                      {data?.isSaved === true ? (
                                        <img
                                          src="/images/heart_filled.svg"
                                          onClick={(e) =>
                                            handleSavedVal(
                                              index,
                                              data?.isSaved,
                                              data?.id,
                                              e
                                            )
                                          }
                                        />
                                      ) : (
                                        <img
                                          src="/images/heart_empty.svg"
                                          onClick={(e) =>
                                            handleSavedVal(
                                              index,
                                              data?.isSaved,
                                              data?.id,
                                              e
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="row mt-2">
                                  <div className="col-10">
                                    <span className="venueRate">
                                      Address:{" "}
                                      <span className="">{data?.address}</span>
                                    </span>
                                  </div>
                                  {/* <div className="col-6">
                                <img src="/images/star.svg" alt="star" />
                                4.5 (12)
                              </div> */}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <div
                  className="citiesbox border-0 text-center"
                  style={{ background: "transparent" }}
                >
                  No Saved Venues Found
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
