import axios from "axios";
import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function FAQCOMP(props) {
  const [FaqData, setFaqData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  useLayoutEffect(() => {
    var validUrl = true;
    axios
      .get("/jsonFiles/FAQData.json")
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (location.pathname === res.data[i].PageName) {
            setFaqData(res.data[i]);
            validUrl = false;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <React.Fragment>
      <div
        className={`container-fluid ${
          props.isfaq != "false" ? "pt-lg-5" : "pt-0"
        }  pt-2`}
      >
        {props.isfaq != "false" && (
          <div className="col-12 text-center">
            <h2 className="clr0E2B3D  headerFontSizeH6">FAQ’s</h2>
          </div>
        )}

        <div className="row d-flex justify-content-center">
          <div
            className={`accordion ${
              props.isfaq != "false" ? "mt-4" : "mt-0"
            } mb-3 px-md-2 ps-0 pe-0`}
            id="accordionExample"
          >
            {FaqData?.SchemaData?.mainEntity?.map((data, i) => {
              const isActive = i === activeIndex;
              return (
                <>
                  <div
                    className="accordion-item accordion-item-landing-page mb-3 position-relative"
                    style={{ zIndex: "99" }}
                    key={i}
                  >
                    {isActive && (
                      <img
                        src="/images/accordian-landing-page.png"
                        alt=""
                        className="accordian-img"
                      />
                    )}
                    <h3 className="accordion-header" id={`heading${i}`}>
                      <button
                        className={`accordions-button ${
                          !isActive && "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${i}`}
                        aria-expanded={isActive ? "true" : "false"}
                        aria-controls={`collapse${i}`}
                        onClick={() => setActiveIndex(i)}
                      >
                        <p
                          style={{ color: isActive ? "#a51e22" : "" }}
                          className="pe-2 mb-0 fw-600"
                        >
                          {data?.name}
                        </p>
                      </button>
                    </h3>
                    <div
                      id={`collapse${i}`}
                      className={`accordion-collapse collapse ${
                        isActive && "show"
                      }`}
                      aria-labelledby={`heading${i}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body pb-3 pt-0">
                        {data?.acceptedAnswer?.text}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
