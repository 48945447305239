import * as React from "react";
import "../css/common.css";
import { Card } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "./Utils/Loader";
import { UserDetails } from "./Utils/PopupModal/UserDetails";
import { Authentication } from "./Utils/PopupModal/Authentication";
import { DBConvertDatetime } from "./constants";
import { GetAPI } from "./Utils/APIConnector";

export function Notification() {
  const [allMatchesData, setAllMatchesData] = useState([]);
  const [appliedMatchesData, setAppliedMatchesData] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [matchID, SetMatchID] = useState("");
  const [isLoading, SetIsLoading] = useState(true);
  const [userPopUp, SetUserPopUp] = useState(false);
  const [userDetails, SetUserDetails] = useState({});
  const [bowlingStyle, SetBowlingStyle] = useState("");
  const [battingStyle, SetBattingStyle] = useState("");
  const [isPresent, setisPresent] = useState([]);
  const [PlayerStats, SetPlayerStats] = useState([]);
  var matchRequest = [];
  var appliedMatches = [];

  const userType = window.localStorage.getItem("UserType");
  const userid = window.localStorage.getItem("userid");

  useEffect(async () => {
    if (userType === null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    await GetAPI("/CreateMatches/GetMatches/" + userid).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        var matchList = result.matchList;
        for (var i = 0; i < matchList?.length; i++) {
          matchRequest.push(matchList[i]);
        }
        setTimeout(() => {
          SetIsLoading(false);
        }, 1000);
        setAllMatchesData(matchRequest);
      }
    });
  }, []);

  const showUserDetails = async (detailsUserID) => {
    await GetAPI(
      "/MatchScoreCard/GetPlayersStatistics/?userid=" + parseInt(detailsUserID)
    ).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
        SetIsLoading(false);
      } else {
        var result = JSON.parse(result);
        if (result.status === "success") {
          SetUserDetails(result.userDetails);
          SetBattingStyle(result.battingstyle);
          SetBowlingStyle(result.bowlingstyleName);
          var player = new Array();
          player.push(result.playerStatistics);
          SetPlayerStats(player);
          SetUserPopUp(true);
        }
      }
    });
  };
  const closePopupHandler = () => {
    SetUserPopUp(false);
  };
  function confirmMatch(statusType, matchId) {
    var matchID = parseInt(matchId);
    var statusCode =
      statusType === "accept" ? 2 : statusType === "delete" ? 3 : 1;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var raw = JSON.stringify({
      cricketmatchid: matchID,
      StatusCode: statusCode,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_BASE_URL + "/CreateMatches/UpdateStatus",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // alert(result.status);
        // alert(result.paymentStatus);
        window.location = "/notification";
      })
      .catch((error) => console.log("error", error));
  }
  const matchDetails = (matchID) => {
    window.localStorage.setItem("MatchDetailID", matchID);
    window.location = "/match-details";
  };
  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="Container">
            <Row className="mx-2">
              <div className="p-2 text-center">
                <label>
                  <h4>
                    <b>Notifications</b>
                  </h4>
                </label>
              </div>

              {allMatchesData.length === 0 ? (
                <Card className="mt-3 matchListItem p-0 text-center">
                  <Card.Body className="p-0">
                    <div className="">
                      <div className="profileformControl small">
                        <b>No records found.</b>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                allMatchesData.map((str, index) => {
                  var returData;
                  returData =
                    str.userId === parseInt(userid) ? (
                      <Card
                        key={index}
                        className="mt-3 matchListItem p-0 "
                        data-item={str.id}
                        data-index={index}
                      >
                        <Card.Body className="p-0">
                          <div
                            className=""
                            style={{
                              background:
                                str.status === 2
                                  ? "#D5F5E3"
                                  : str.status === 3
                                  ? "#FADBD8"
                                  : str.status === 4
                                  ? "#D6DBDF"
                                  : "#E8E8E8",
                            }}
                          >
                            <div className="p-3 small">
                              Your status for Match{" "}
                              <a
                                onClick={() => matchDetails(str.cricketMatchId)}
                                style={{ textDecoration: "underline" }}
                              >
                                {" "}
                                <b>
                                  {str.cricName} - {str.groundName}
                                </b>
                              </a>{" "}
                              at <b> {DBConvertDatetime(str.matchDate)}</b> is{" "}
                              <b>
                                {str.status === 1
                                  ? "Requested"
                                  : str.status === 2
                                  ? "Approved"
                                  : str.status === 3
                                  ? "Rejected"
                                  : str.status === 4
                                  ? "Refunded"
                                  : null}
                              </b>
                              .
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Card
                        key={index}
                        className="mt-2 matchListItem px-0"
                        data-item={str.id}
                        data-index={index}
                        style={{ backgroundColor: "#C4C4C426" }}
                      >
                        <Card.Body className="">
                          <div className="row">
                            <div className="col-2 p-0 centerAlign">
                              <label
                                htmlFor="upload-button"
                                className="userProfileImageDiv"
                              >
                                <img
                                  key={index}
                                  src={
                                    str.profileImage === null ||
                                    str.profileImage === ""
                                      ? "/images/dummyavtar.png"
                                      : `/userimage/${str.profileImage}`
                                  }
                                  onClick={() =>
                                    showUserDetails(str.cricketPlayerId)
                                  }
                                  className="userProfileImage"
                                />
                              </label>
                            </div>
                            <div className="col-10 d-flex">
                              <span className="">
                                <b
                                  style={{
                                    color: "#4BAE4F",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {str.status === 1
                                    ? "Requested"
                                    : str.status === 2
                                    ? "Approved"
                                    : str.status === 3
                                    ? "Rejected"
                                    : str.status === 4
                                    ? "Refunded"
                                    : null}{" "}
                                  for match -{" "}
                                </b>{" "}
                                <a
                                  key={index}
                                  onClick={() =>
                                    matchDetails(str.cricketMatchId)
                                  }
                                  style={{ textDecoration: "underline" }}
                                >
                                  <b>{str.cricName}</b>
                                </a>
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  return returData;
                })
              )}
            </Row>
            {userPopUp ? (
              <UserDetails
                show={userPopUp}
                UserDetails={userDetails}
                BattingStyle={battingStyle}
                BowlingStyle={bowlingStyle}
                PlayerStatistics={PlayerStats}
                onHide={closePopupHandler}
                onClick={closePopupHandler}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}
