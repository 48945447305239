import { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import { ConvertDateTime } from "../constants";
import { PostAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";

export const MatchPayment = ({
  prevStep,
  nextStep,
  handleChange,
  values,
  validatedInputs,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  const [showLoader, SetShowLoader] = useState(false);
  const UserID = parseInt(window.localStorage.getItem("userid"));
  const UserName = window.localStorage.getItem("UserName");
  const username = window.localStorage.getItem("UserName");
  const phoneno = window.localStorage.getItem("phoneno");
  const UserEmail = window.localStorage.getItem("UserEmail");
  const MatchFees = parseInt(values.currentPlayer) * parseInt(values.MatchFees);

  const timeAndDate = values.MatchDate + " " + values.MatchTime;

  const handleRazorpayPayment = async () => {
    var options;
    const data = JSON.stringify({
      UserId: UserID,
      MatchId: parseInt(values.MatchID),
      Isfromvenuepayment: false,
      Amount: MatchFees,
    });
    await PostAPI("/payment/initialize", data).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        const order_id = result.orderResponse.orderId;
        options = {
          key: result.orderResponse.razorPayAPIKey,
          amount: result.orderResponse.amount,
          name: "Match Registration Fee",
          description: "Total_match_fee",
          image: "/images/cricketFlash.svg",
          order_id: order_id,
          handler: async (data) => {
            SetShowLoader(true);
            validatedInputs("OrderID", data.razorpay_order_id);
            validatedInputs("PaymentID", data.razorpay_payment_id);
            var paymentData = JSON.stringify(data);
            await PostAPI("/payment/confirm", paymentData).then((result) => {
              if (result === undefined || result === "") {
                alert("Something went wrong");
              } else {
                result = JSON.parse(result);
                validatedInputs("Status", result.paymentStatus);
                validatedInputs("showExitPrompt", false);
                SetShowLoader(false);
                nextStep();
              }
            });
            document.body.style.overflow = "auto";
          },
          prefill: {
            name: username,
            email: UserEmail ? UserEmail : "",
            contact: phoneno,
          },
          theme: {
            color: "#F3F3F3",
          },
          config: {
            display: {
              hide: [
                { method: "paylater" },
                { method: "netbanking" },
                { method: "wallet" },
              ],
            },
          },
          webview_intent: true,
          modal: {
            ondismiss: function () {
              document.body.style.overflow = "auto";
            },
          },
        };

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          console.log(response.error.code);
        });
        paymentObject.open();
      }
    });
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <div id="paymentInfo" className="px-2">
            <div id="previewDetails" className="row mt-2">
              <div className="p-2 text-center">
                <label style={{ color: "#A51E22" }}>
                  <h4>
                    <b>Match Payment</b>
                  </h4>
                </label>
                <hr />
              </div>
              <div className="col-12">
                <form>
                  <div className="profileformControl">
                    {UserName != null && (
                      <div className="row text-left">
                        <div className="col-6">
                          <label class="form-label textLabel">
                            Created By :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <lable className="dataText">{UserName}</lable>
                        </div>
                      </div>
                    )}

                    <div className="row text-left">
                      <div className="col-6">
                        <label class="form-label textLabel">Location : </label>
                      </div>
                      <div className="col-6">
                        <lable className="dataText">
                          {values?.NameOfGround}
                        </lable>
                      </div>
                    </div>

                    <div className="row text-left">
                      <div className="col-6">
                        <label class="form-label textLabel">Match Name :</label>
                      </div>
                      <div className="col-6">
                        <lable className="dataText">{values.MatchName}</lable>
                      </div>
                    </div>
                    <div className="row text-left">
                      <div className="col-6 pe-0">
                        <label class="form-label textLabel">
                          Match Date & Time:
                        </label>
                      </div>
                      <div className="col-6">
                        <lable className="dataText">
                          {ConvertDateTime(timeAndDate)}
                        </lable>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 text-center ">
                    <label
                      class="form-label textLabel mb-0"
                      style={{ textTransform: "uppercase" }}
                    >
                      Total Match Fees For {values.currentPlayer} Player
                    </label>
                    <div
                      className="p-2 dataText"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <label className="dataText profileformControl m-2">
                        {values.currentPlayer}
                      </label>
                      X
                      <label className="form-label dataText profileformControl m-2">
                        {values.MatchFees}
                      </label>
                      =
                      <label className="form-label dataText profileformControl m-2">
                        {parseInt(values.currentPlayer) *
                          parseInt(values.MatchFees)}{" "}
                        ₹
                      </label>
                    </div>
                  </div>
                  <span
                    className="requiredValidation text-center validationText"
                    style={{ fontSize: "14px", color: "black" }}
                  >
                    <p>
                      *We will charge{" "}
                      <span className="fw-bold">
                        {process.env.REACT_APP_CUSTOM_PLATFORM_FEE}
                      </span>{" "}
                      platform fees and{" "}
                      <span className="fw-bold">
                        {process.env.REACT_APP_CUSTOM_REFUND_FEE}{" "}
                      </span>
                      fees will be refunded to your payment source after match
                      is completed.
                    </p>
                  </span>
                </form>
              </div>
            </div>
            <div id="previewButtons" className="mt-3 mb-3">
              <hr />
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <button
                    type="submit"
                    className=" loginbtn col-6"
                    onClick={handleRazorpayPayment}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
            <div id="paymentDiv" className="d-none">
              <div className="p-2 text-center ">
                <label>
                  <h4>
                    <b>Payment Details</b>
                  </h4>
                </label>
                <hr />
              </div>
            </div>
          </div>
          <div id="PaymentSuccess" className="d-none">
            <div className="profileformControl">
              <div className=" text-left">
                <label className="form-label textLabel">
                  <h6>Match ID : </h6> {values.MatchID}
                </label>
              </div>
            </div>
            <div className="profileformControl">
              <div className=" text-left">
                <label className="form-label textLabel">
                  <h6>Payement ID : </h6> {values.MatchID}
                </label>
              </div>
            </div>
            <div className="profileformControl">
              <div className=" text-left">
                <label className="form-label textLabel">
                  <h6>Order ID : </h6> {values.MatchID}
                </label>
              </div>
            </div>
            <div className="profileformControl">
              <div className=" text-left">
                <label className="form-label textLabel">
                  <h6>Status : </h6> {values.MatchID}
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
